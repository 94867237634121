import React, { useState, useEffect, useReducer } from "react";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";

function Exemptedcustomer() {
  const token = JSON.parse(localStorage.getItem("data")).token;
  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  <div className="card card-raised mb-5"></div>;
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [exemptedcustomercategory, setExemptedcustomercategory] = useState([]);
  const [exemptedcustomer, setExemptedcustomer] = useState([]);

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [exemptedcustomercategory1, setexemptedcustomercategory1] = useState({
    customername: "",
    customercategory: "",
    deliverydate:currentDate,
    orgcode:JSON.parse(localStorage.getItem("data")).orgcode,
    distance: "",
    tariff: "",
    disable: false,
  });
  const [User, setUser] = useState([]);

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setexemptedcustomercategory1({
      ...exemptedcustomercategory1,
      [field]: actualValue,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post("/api/exemptedcustomer/create", exemptedcustomercategory1, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //    navigate("/users/Deviceusermapping");
        toast.success("Exempted Customer added");
        setLoading(false);
        getData();
      })
      .catch((error) => {
        //   navigate("/users/Deviceusermapping");
        //    toast.error("The Deviceid must be unique");

        setLoading(false);
      });
  };
  const getData = async () => {
    setLoader(true);
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios
    .get(
      `/api/exemptedcustomercategories/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setExemptedcustomercategory(res.data);
        setLoader(false);
      });
    await axios
      .get("/api/exemptedcustomer", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setExemptedcustomer(res.data);
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div
            className="col-10 mx-xl-auto ps-xl-8"
            style={{ position: "relative", left: "100px", top: "150px" }}
          >
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="card-title"> Exempted Customer Creation</div>
                <br></br>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="customername ">Customer Name </label>
                      <input
                        type="text"
                        className="form-control"
                        id="customername"
                        value={exemptedcustomercategory1.customername}
                        onChange={(e) => handleChange(e, "customername")}
                      />
                    </div>

                    <div className="form-floating-sm mb-2">
                      <label for="customercategory ">Customer Category </label>
                      <select
                        id="customercategory"
                        type="text"
                        className="form-control"
                        placeholder="customercategory"
                        onChange={(e) => handleChange(e, "customercategory")}
                      >
                        <option>select customercategory</option>
                        {exemptedcustomercategory.map((ec) => {
                          return (
                            <option value={ec.categoryname}>
                              {ec.categoryname}
                            </option>
                          );
                        })}
                      </select>{" "}
                    </div>
                    <div className="form-floating-sm mb-2">
                      <label for="distance ">Distance </label>
                      <input
                        type="text"
                        className="form-control"
                        id="distance"
                        value={exemptedcustomercategory1.distance}
                        onChange={(e) => handleChange(e, "distance")}
                      />{" "}
                    </div>
                   
                    <div className="form-floating-sm mb-2">
                      <label for="tariff ">Tariff</label>
                      <input
                        type="text"
                        className="form-control"
                        id="tariff"
                        value={exemptedcustomercategory1.tariff}
                        onChange={(e) => handleChange(e, "tariff")}
                      />{" "}
                    </div>
                  </div>
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      value="Save"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      <br />
      <br /> <br />
      <br />
      {loader && <div className="loader"></div>}
      <div
        className="col-10 mx-xl-auto ps-xl-9"
        style={{ position: "relative", left: "100px", top: "150px" }}
      >
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="card-title"> Exempted Customer List</div>
            <table
              className="table table-bordered "
              style={{ position: "relative" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Customer Name</th>
                  <th>Category Name</th>
                  <th>Distance</th>
                  <th>Tariff</th>


                </tr>
              </thead>
              <tbody>
                {exemptedcustomer.map((ecustomer) => {
                  return (
                    <tr
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <th>{ecustomer.customername}</th>

                      <th>{ecustomer.customercategory}</th>
          
                      <th>{ecustomer.distance}</th>

                      {/* <th>{ecustomer.deliveryrate}</th> */}
                      <th>{ecustomer.tariff}</th>

                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exemptedcustomer;
