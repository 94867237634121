import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import Search from "./search";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import Financialmanagment from "./financialManagment";
function ProductcostDetails() {
  const [uproductcost, setUproductcost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchstate, setSearchstate] = useState(true);
  const [searchbydate, setSearchbydate] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const date = new Date();

  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const searchName = async (text) => {
    setSearchstate(!searchstate);
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const res = await axios
      .get(`/api/fleetfinancial/date=${text}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);

        setSearchbydate(response.data);
      });
  };
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    setLoading(true);

    axios

      .get(
        `/api/fleetfinancial/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        setUproductcost(response.data);

        setLoading(false);
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
          setLoading(false);
        } else {
          toast.error(error.response.data.message);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handlefinancialmanagment = () => {
    setIsModalOpen(true);



  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  console.log("productcost", uproductcost);
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
     
      <div
        className="col-9  mx-xl-auto  px-xl-5 "
        style={{ position: "relative", left: "150px" }}
      >
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div>
                <Search searchName={searchName} />
              </div>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;
             
              <button className="btn btn-primary float-end" onClick={handlefinancialmanagment}>
               ADD
            </button>
      
            </div>   
            <br />
            <br />
            <div style={{ position: "relative", left: "30px", width: "800px" }}>
              
                  <div className="card-title">Product Cost Details
                 
                  {loading && (
        <div
          className="loader"
          style={{ position: "relative", left: "50px", top: "6px" }}
        ></div>
      )}
</div>
                  <br />
                  <table className="table table-bordered ">
                    <thead>
                      <tr
                        style={{
                          fontSize: "17px",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                        <th>Date</th>
                        <th>Vehicleid</th>
                        <th>product Code</th>

                        <th>Productname</th>
                        <th>Salescost</th>
                        <th>Newconectioncost</th>

                        <th>Additionalcost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchstate &&
                        uproductcost.map((cost) => {
                          return (
                            <tr key={cost.id}>
                              <td>{cost.date}</td>
                              <td>{cost.vehicle}</td>
                              <td>{cost.productcode}</td>

                              <td>{cost.productname}</td>

                              <td>{cost.salecost}</td>

                              <td>{cost.newconection}</td>
                              <td>{cost.additionalcost}</td>
                            </tr>
                          );
                        })}
                      {!searchstate &&
                        searchbydate.map((cost) => {
                          return (
                            <tr key={cost.id}>
                              <td>{cost.date}</td>
                              <td>{cost.vehicle}</td>
                              <td>{cost.productcode}</td>

                              <td>{cost.productname}</td>

                              <td>{cost.salecost}</td>

                              <td>{cost.newconection}</td>
                              <td>{cost.additionalcost}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <Financialmanagment isOpen={isModalOpen} onRequestClose={handleCloseModal} />

                </div>
             
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductcostDetails;
