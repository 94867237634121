import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import "./Loader.css";
import { toast } from "react-toastify";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Vehiclereport(props) {
  const [fuel, setFuel] = useState([]);
  const [insurance, setInsurance] = useState([]);
  const [service, setService] = useState([]);
  const [fc, setFc] = useState([]);
  const [permit, setPermit] = useState([]);
  const [ecertificate, setEcertificate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const getData = async () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    // const trans = await axios.get(
    //   `http://localhost:8080/api/ReturntransactionDo/pdate=${currentDate}`,
    //   {
    //     headers: { Authorization: `Bearer ${token}` },
    //   }
    // );

    // console.log(trans);

    // setTransactionin(trans.data.product);
    await Promise.all([
      axios
        .get(`/api/fuel/vehicleid=${props.params.truckinfo}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);
          setLoader(false);
          setFuel(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            toast.success(error.response.data.message);
          } else {
          }
        }),
      axios
        .get(`/api/Insurance/vehicleid=${props.params.truckinfo}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setInsurance(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            toast.success(error.response.data.message);
          } else {
          }
        }),
      axios
        .get(`/api/maintenance/vehicleid=${props.params.truckinfo}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);

          setService(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            toast.success(error.response.data.message);
          } else {
          }
        }),
      axios
        .get(`/api/Fitnesscertificate/vehicleid=${props.params.truckinfo}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setFc(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            toast.success(error.response.data.message);
          } else {
          }
        }),
      axios
        .get(`/api/Permit/vehicleid=${props.params.truckinfo}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);

          setPermit(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            toast.success(error.response.data.message);
          } else {
          }
        }),
      axios
        .get(`/api/Emisioncertificate/vehicleid=${props.params.truckinfo}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);

          setEcertificate(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            toast.success(error.response.data.message);
          } else {
          }
        }),
    ]);
  };

  useEffect(() => {
    getData();
  }, []);
  const navigate = useNavigate();
  function addfuel(e) {
    navigate(`/users/fuel/${props.params.truckinfo}`);
  }
  function addinsurance(e) {
    navigate(`/users/insurance/${props.params.truckinfo}`);
  }
  function addservice(e) {
    navigate(`/users/service/${props.params.truckinfo}`);
  }
  function addFc(e) {
    navigate(`/users/fc/${props.params.truckinfo}`);
  }
  function addpermit(e) {
    navigate(`/users/permit/${props.params.truckinfo}`);
  }
  function addemission(e) {
    navigate(`/users/emission/${props.params.truckinfo}`);
  }
  const onClickInsuranceDownload = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/Insurance/download/id=${id}`,
      method: "GET",
      responseType: "blob",

      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };
  const onClickFuelDownload = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/fuel/download/id=${id}`,
      method: "GET",
      responseType: "blob",

      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };
  const onClickFCDownload = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/Fitnesscertificate/download/id=${id}`,
      method: "GET",
      responseType: "blob",

      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };
  const onClickServiceDownload = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/maintenance/download/id=${id}`,
      method: "GET",
      responseType: "blob",

      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data.file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };
  const onClickPermitDownload = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/Permit/download/id=${id}`,
      method: "GET",
      responseType: "blob",

      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };
  const onClickEmissionDownload = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/Emissioncertificate/download/id=${id}`,
      method: "GET",
      responseType: "blob",

      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div>
      <div
        className="col-12 mx-xl-auto ps-xl-8"
        style={{
          fontSize: "25px",

          position: "relative",
        }}
      >
        <div className="card card-raised ">
          <div className="card-body ">
            <h1
              style={{
                fontSize: "25px",
                position: "absolute",
                left: "20px",
                textAlign: "center",
              }}
            >
            </h1>
            <div>
              <AdminDashboard />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />{" "}
           
            <div
              className="col-12 mx-xl-auto ps-xl-10"
              style={{
                fontSize: "25px",

                position: "relative",
              }}
            >
              <div className="card card-raised ">
                <div className="card-body ">
                  <div className="d-flex">
                    <h1
                      style={{
                        fontSize: "25px",

                        position: "relative",
                        left: "300px",
                      }}
                    >
                      Vehicle Number:{`${props.params.truckinfo}`}
                    </h1>
                  </div>{" "}
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="transactions" id="transactions">
              <div className="col-12 mx-xl-auto ps-xl-10 ">
                <div className="card card-raised ">
                  <div className="card-body ">
                    <div className="d-flex">
                      <div className="card-title"> Fuel Management</div>{" "}
                      {loader && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        className="btn btn-primary"
                        onClick={(e) => addfuel(e)}
                      >
                        ADD
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <br />
                    <table className="table table-bordered ">
                      <thead>
                        <tr
                          style={{
                            fontSize: "17px",
                            backgroundColor: " #6200ea",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          <th>Quantity</th>
                          <th>Rate per Liter</th>
                          <th>Amount</th>
                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fuel.length === 0 ? (
                          <tr>
                            <td colSpan="7" className="text-center">
                              No Data Available
                            </td>
                          </tr>
                        ) : (
                        fuel.map((fuel) => {
                          return (
                            <tr key={fuel.id}>
                              <th>{fuel.quantity}</th>
                              <th>{fuel.rate}</th>

                              <th>{fuel.amount}</th>
                              <th>
                                <button
                                  onClick={(e) =>
                                    onClickFuelDownload(e, fuel.id)
                                  }
                                >
                                  Download
                                </button>
                              </th>
                            </tr>
                          );
                        }))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br /> <br /> <br /> <br />
              <div className="col-12 mx-xl-auto ps-xl-10 ">
                <div className="card card-raised ">
                  <div className="card-body ">
                    <div className="d-flex">
                      <div className="card-title">Insurance Details</div>{" "}
                      {loader && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      <button
                        className="btn btn-primary"
                        onClick={(e) => addinsurance(e)}
                      >
                        Add
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <br />
                    <table className="table table-bordered ">
                      <thead>
                        <tr
                          style={{
                            fontSize: "17px",
                            backgroundColor: " #6200ea",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <th>Policynumber</th>

                          <th>Year</th>
                          <th>Phonenumber</th>
                          <th>Agent </th>
                          <th>Startdate</th>
                          <th>Enddate</th>
                          <th>Effective </th>

                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {insurance.length === 0 ? (
                          <tr>
                            <td colSpan="7" className="text-center">
                              No Data Available
                            </td>
                          </tr>
                        ) : (
                        insurance.map((insurance) => {
                          return (
                            <tr>
                              <th>{insurance.policynumber}</th>

                              <th>{insurance.year}</th>
                              <th>{insurance.phonenumber}</th>
                              <th>{insurance.agent}</th>
                              <th>{insurance.startdate}</th>
                              <th>{insurance.enddate}</th>
                              <th>{insurance.effective}</th>

                              <th>
                                <button
                                  onClick={(e) =>
                                    onClickInsuranceDownload(e, insurance.id)
                                  }
                                >
                                  Download
                                </button>
                              </th>
                            </tr>
                          );
                        }))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br /> <br /> <br />
            <div className="col-12 mx-xl-auto ps-xl-10 ">
              <div className="card card-raised ">
                <div className="card-body ">
                  <div className="d-flex">
                    <div className="card-title">Service Details</div>{" "}
                    {loader && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={(e) => addservice(e)}
                    >
                      Add
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <br />
                  <table className="table table-bordered ">
                    <thead>
                      <tr
                        style={{
                          fontSize: "17px",
                          backgroundColor: " #6200ea",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        <th>Date</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {service.length === 0 ? (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No Data Available
                          </td>
                        </tr>
                      ) : (
                      service.map((service) => {
                        return (
                          <tr>
                            <th>{service.date}</th>
                            <th>{service.description}</th>
                            <th>{service.amount}</th>

                            <th>
                              <button
                                onClick={(e) =>
                                  onClickServiceDownload(e, service.id)
                                }
                              >
                                Download
                              </button>
                            </th>
                          </tr>
                        );
                      }))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="transactions" id="transactions">
              <div className="col-12 mx-xl-auto ps-xl-10 ">
                <div className="card card-raised ">
                  <div className="card-body ">
                    <div className="d-flex">
                      <div className="card-title">
                        {" "}
                        Fitness Certificate Detals
                      </div>{" "}
                      {loader && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        className="btn btn-primary"
                        onClick={(e) => addFc(e)}
                      >
                        ADD
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <br />
                    <table className="table table-bordered ">
                      <thead>
                        <tr
                          style={{
                            fontSize: "17px",
                            backgroundColor: " #6200ea",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          <th>Fcnumber</th>
                          <th>Issuedate</th>
                          <th>Expirydate</th>
                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fc.length === 0 ? (
                          <tr>
                            <td colSpan="7" className="text-center">
                              No Data Available
                            </td>
                          </tr>
                        ) : (
                        fc.map((fc) => {
                          return (
                            <tr>
                              <th>{fc.fcnumber}</th>
                              <th>{fc.issuedate}</th>

                              <th>{fc.expirydate}</th>
                              <th>
                                <button
                                  onClick={(e) => onClickFCDownload(e, fc.id)}
                                >
                                  Download
                                </button>
                              </th>
                            </tr>
                          );
                        }))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>{" "}
            </div>
            <br />
            <br />
            <br />
            <div className="transactions" id="transactions">
              <div className="col-12 mx-xl-auto ps-xl-10 ">
                <div className="card card-raised ">
                  <div className="card-body ">
                    <div className="d-flex">
                      <div className="card-title"> Permit Details {""}
                      {loader && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                      </div>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        className="btn btn-primary"
                        onClick={(e) => addpermit(e)}
                      >
                        ADD
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <br />
                    <table className="table table-bordered ">
                      <thead>
                        <tr
                          style={{
                            fontSize: "17px",
                            backgroundColor: " #6200ea",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          <th>Permit Number</th>
                          <th>Issue Date</th>
                          <th>Expiry Date</th>
                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {permit.length === 0 ? (
                          <tr>
                            <td colSpan="7" className="text-center">
                              No Data Available
                            </td>
                          </tr>
                        ) : (
                        permit.map((permit) => {
                          return (
                            <tr>
                              <th>{permit.permitnumber}</th>
                              <th>{permit.issuedate}</th>

                              <th>{permit.expirydate}</th>
                              <th>
                                <button
                                  onClick={(e) =>
                                    onClickPermitDownload(e, permit.id)
                                  }
                                >
                                  Download
                                </button>
                              </th>
                            </tr>
                          );
                        }))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>{" "}
            </div>
            <br />
            <br />
            <br />
            <div className="transactions" id="transactions">
              <div className="col-12 mx-xl-auto ps-xl-10 ">
                <div className="card card-raised ">
                  <div className="card-body ">
                    <div className="d-flex">
                      <div className="card-title">
                        {" "}
                        Emission Certificate Details
                      </div>{" "}
                      {loader && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        className="btn btn-primary"
                        onClick={(e) => addemission(e)}
                      >
                        ADD
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <br />
                    <table className="table table-bordered ">
                      <thead>
                        <tr
                          style={{
                            fontSize: "17px",
                            backgroundColor: " #6200ea",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          <th>Certification Number</th>
                          <th>Issue Date</th>
                          <th>Expiry Date</th>
                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ecertificate.length === 0 ? (
                          <tr>
                            <td colSpan="7" className="text-center">No Data Available</td>
                          </tr>
                        ) : (
                        ecertificate.map((ecertificate) => {
                          return (
                            <tr>
                              <th>{ecertificate.certificationnumber}</th>
                              <th>{ecertificate.issuedate}</th>

                              <th>{ecertificate.expirydate}</th>
                              <th>
                                <button
                                  onClick={(e) =>
                                    onClickEmissionDownload(e, ecertificate.id)
                                  }
                                >
                                  Download
                                </button>
                              </th>
                            </tr>
                          );
                        }))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Vehiclereport);
