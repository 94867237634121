import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link } from "react-router-dom";
import CreateAdminAccount from "./createAdminAccount";
function Organizationdetails() {
  const [organizationdetails, setOrganizationdetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const url = "/api/Returntransaction";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get("/api/organizationprofiles", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans);

    setOrganizationdetails(trans.data);
  };

  const handleCreateAccount = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <div>
        <div className="col-10  mx-xl-auto ps-xl-10" style={{marginTop:"10%"}}>
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">Organization Details
             
                <button className="btn btn-primary" style={{ position: "relative", marginLeft: "50%" }}  onClick={handleCreateAccount}>Create Account</button>
      
          
              </div>

              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Organization Name </th>

                    <th>Contact</th>
                  </tr>
                </thead>
                <tbody>
                  {organizationdetails.map((accd) => {
                    return (
                      <tr key={accd.name}>
                        <td>{accd.name}</td>

                        <td>{accd.contact}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div>
              <CreateAdminAccount isOpen={isModalOpen} onRequestClose={handleCloseModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organizationdetails;
