import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import MonthlyTarget from "./Monthlytarget";
import { Link } from "react-router-dom";

function Productdetails() {
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null); // State for selected month


  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
    setLoader(true);

    try {
      const monthlyTargetUrl = `/api/productmonthlytarget/orgcode=${orgCode}`;
      const monthlyTargetResponse = await axios.get(monthlyTargetUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let filteredData = [...monthlyTargetResponse.data];
      
      // Filter data by selected month if it's not null
      if (selectedMonth !== null) {
        filteredData = filteredData.filter(item => new Date(item.date).getMonth() === selectedMonth);
      }
      
      setProduct(filteredData);

      if (selectedDate !== null) {
        const modalUrl = `/api/productmonthlytarget/orgcode=${orgCode}/date=${selectedDate}`;
        const modalResponse = await axios.get(modalUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setModalData([...modalResponse.data]);
        console.log(modalResponse.data);
      }

      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [selectedDate, selectedMonth]); // Listen to changes in selectedDate and selectedMonth

  const handleViewDetails = (date) => {
    setSelectedDate(date);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData(null);
  };
  const handleAddProduct = () => {
    setIsModalOpen(true);
    setProduct([]);


  };

  const handleaddCloseModal = () => {
    setIsModalOpen(false);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "long" }); 
    return `${year} ${month}`;
  };
  const handleMonthChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setSelectedMonth(selectedValue === null ? null : selectedValue); // Update selected month only if it's not null
  };
  

  return (
    <div>
      <AdminDashboard />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="col-10 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
              <div className="card-title">Monthly Target</div>
              <div className="d-flex align-items-center">

              <label style={{ fontSize: '1.2em',color:"#000000" ,marginLeft:"20%" }}>Month</label>
        <select   style={{ width: "130px", fontSize: "0.9em",height:"40px" }}
        onChange={handleMonthChange} value={selectedMonth}>
          <option value={null}>All </option>
          <option value={0}>January</option>
          <option value={1}>February</option>
          <option value={2}>March</option>
          <option value={3}>April</option>
          <option value={4}>May</option>
          <option value={5}>June</option>
          <option value={6}>July</option>
          <option value={7}>August</option>
          <option value={8}>September</option>
          <option value={9}>October</option>
          <option value={10}>November</option>
          <option value={11}>December</option>
        </select>
        </div>
              <button className="btn btn-primary" style={{marginLeft:"40%"}} onClick={handleAddProduct}>
               New
            </button>
            </div>
            <br />
            <table className="table table-bordered">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>Year & Month</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {product.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  product.map((currentProduct, index) => {
                    const isFirstRow = index === 0;
                    const prevDate = isFirstRow
                      ? null
                      : product[index - 1].date.slice(0, 7);
                    const currentDate = currentProduct.date.slice(0, 7);
                    const showDate = isFirstRow || prevDate !== currentDate;

                    return (
                      <tr key={currentProduct.id}>
                        {showDate && (
                          <>
                  <td>{formatDate(currentProduct.date)}</td>
                            <td>
                              <button
                                onClick={() =>
                                  handleViewDetails(currentProduct.date)
                                }
                              >
                                View
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Monthly Target details </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                {loader ? (
                  <p>Loading...</p>
                ) : (
                  <div>
                {modalData && modalData.length > 0 ? (
         <table className="table">
          <thead>
    <tr>
      <th>Date: {modalData[0].date}</th>
      <th>Orgcode: {modalData[0].orgcode}</th>
    </tr>
    <tr>
      <th>Product Code</th>
      <th>Product Name</th>
      <th>Target</th>
    </tr>
    </thead>
    <tbody>
      {modalData.map((data) => (
        <tr key={data.id}>
          
          <td>{data.productcode}</td>
          <td>{data.productname}</td>
          <td>{data.target}</td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <p>No data available for the selected date</p>
)}
                </div>
              )}
            </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

<MonthlyTarget isOpen={isModalOpen} onRequestClose={handleaddCloseModal} />

    </div>
  );
}

export default Productdetails;
