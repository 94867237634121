import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import { useParams,useNavigate,useLocation } from "react-router-dom";
import { GoogleMap, Marker,Circle} from '@react-google-maps/api'; // Import GoogleMap and Marker from react-google-maps
import { Link } from "react-router-dom";
import axios from "../auth/baseurl";


const centerOfIndia = { lat: 22.3511148, lng: 78.6677428 };


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
   let location = useLocation();
   let navigate = useNavigate();

  let params = useParams();
  
  return (
  
  <Component
  {...props}
  location={location}
  params={params}
  navigate={navigate}
  />
   );
  }
   return ComponentWithRouterProp;
  }






const MapComponent = (props) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const { deviceid } = useParams();
  const [agents, setAgents] = useState([]);
  const [api, setapi] = useState([]); 

  const navigate = useNavigate();
  

  



  
  // Function to update the marker's position
  const updateMarker = (latitude, longitude) => {
    if (marker && map) {
      marker.setPosition({ lat: latitude, lng: longitude });
      map.panTo({ lat: latitude, lng: longitude });
    }
  };


  useEffect(() => {
    // Initialize the Google Map
    const initializeMap = async () => {
      // Fetch appname and apikey from the API
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response1 = await axios.get(`/api/Locationtrack/userid=${ JSON.parse(localStorage.getItem("data")).userid}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setapi(response1.data);
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCI0IsM1EWVG5PKM4mCHNdV6NK8Lja4-lM&libraries=places`;
        googleMapScript.async = true;
        googleMapScript.defer = true;
    
    
    const url =  process.env.REACT_APP_CONVOIZ_URL;

    console.log(url);

    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`${url}/api/location/deviceid=${props.params.deviceid}`, {
          headers: {
            'AppName': `${api.appname}`,
            'APIKey': `${api.apikey}`,
       
              },
        })
      // Check if the response contains data and if it's an array
      setAgents(response.data);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const initializeMap = () => {
    const googleMap = new window.google.maps.Map(document.getElementById("map"), {
      center: centerOfIndia,
      zoom: 6,
    });

    setMap(googleMap);

    // Add a marker at the center
    const centerMarker = new window.google.maps.Marker({
      position: centerOfIndia,
      map: googleMap,
    });

    setMarker(centerMarker);

  
  fetchData();
};
googleMapScript.onload = () => {
  initializeMap();

  // Refresh the map and update location every 10 seconds
  const intervalId = setInterval(() => {
    fetchData();
  }, 10000);

  // Clear the interval when the component is unmounted
  return () => clearInterval(intervalId);
};


googleMapScript.onerror = () => {
  console.error("Error loading Google Maps script");
};


document.head.appendChild(googleMapScript);
return () => {
  document.head.removeChild(googleMapScript);
};
} catch (error) {
  console.error("Error fetching appname and apikey:", error);
}
};
initializeMap();

}, [props.params.userid]);

return (

<div>
<AdminDashboard />
 
<div className="dform-group d-flex align-items-center justify-content-between mt-8 mb-0" >
 <Link  to="/users/vehicledetail" style={{textDecoration:"none", position: "absolute", left: "300px", top: "100px"}}>
 <button className="btn btn-secondary" type="button">Back</button>
  </Link>
</div>

<div id="map" style={{   position: "absolute", left: "300px", top: "150px", height: '550px', width: '80%' }}>
{agents.map((agent) => (

<GoogleMap
      mapContainerStyle={{ height: '100%', width: '100%' }}
      center={  {lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude)}}
      zoom={10}
>
<React.Fragment key={agent.id}>
{console.log("Agent Data:", agent)}  
{console.log("Latitude:", parseFloat(agent.latitude))}    
{console.log("Longitude:", parseFloat(agent.longitude))}

<Circle
             key= {`circle_${agent.id}`}
            center={{ lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude) }}
            radius={3000}
            options={{
              fillColor: 'blue',
              fillOpacity: 0.1,
              strokeColor: 'blue',
              strokeWeight: 1,
            }}
          />
<Marker
            key= {agent.id}
            position={{ lat: parseFloat(agent.latitude), lng: parseFloat(agent.longitude) }}
            icon={{
              // path: window.google.maps.SymbolPath.CIRCLE,
              // fillColor: 'blue',
              // fillOpacity: 0.5,
              // strokeColor: 'blue',
              // strokeWeight: 2,
              // scale: 8,
              url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png', // URL to your custom pointer icon
              scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed

            }}
          />
</React.Fragment>
</GoogleMap>
      ))}

</div>
</div>
);
};

export default withRouter(MapComponent);

