import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../api/baseurl";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";

  const RegisterMd = ({ isOpen, onRequestClose }) => {

  // const navigate = useNavigate();

  const url = "/api/register";
  const navigate = useNavigate();

  const [admindata, setAdata] = useState([
    {
      username: "",
      role: "",
      organization: {
        orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      },
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [accountrole, setAccountrole] = useState([]);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    await axios
      .get("/api/accountRole", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setLoading(false);
        setAccountrole(res.data);
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.success(error.response.data.message);
          setLoading(false);
        } else {
          toast.error(error.response.data.message);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(url, {
        username: admindata.username,
        role: admindata.role,
        organization: {
          orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
        },
      })
      .then((res) => {
        console.log(res.data.roleRequested);
        setLoading(false);
        toast("Account created Successfully and Password sent to your mail");
        onRequestClose(); 
        navigate("/users/Deliveryagentdetails");

        setAdata({}); // Assuming setAdmindata is a state setter function
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        toast.error("Error: Failed to send password");
      });
  }
  function rhandle(e) {
    const newdata = { ...admindata };
    newdata[e.target.id] = e.target.value;
    setAdata(newdata);
  }
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
          
                      <div className="text-center">
                        <Link to="users/admindashboard">
                          {" "}
                          <img
                            className="mb-3"
                            src="assets/img/icons/background.svg"
                            alt="..."
                            style={{ height: "48px" }}
                          />
                        </Link>

                        <h1 className="display-5 mb-0">Create Account</h1>
                        <div className="subheading-1 mb-5">
                          for Delivery Agents
                        </div>
                      </div>

                      <form onSubmit={(e) => rsubmit(e)}>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="username">Email</label>
                            <input
                              className="form-control"
                              id="username"
                              type="email"
                              placeholder="enter email"
                              value={admindata.username}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="username">Orgcode</label>
                            <input
                              className="form-control"
                              id="orgcode"
                              type="text"
                              placeholder="orgcode"
                              value={ JSON.parse(localStorage.getItem("data")).orgcode}
                              onChange={(e) => rhandle(e)}
                            />
                          </div>
                        </div>


                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="role">Role</label>
                            <select
                              id="role"
                              className="form-control"
                              type="text"
                              placeholder="role"
                              onChange={(e) => rhandle(e)}
                            >
                              <option>select role</option>
                              {accountrole.map((arole) => {
                                return (
                                  <option value={admindata.role}>
                                    {arole.role}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                          <input
                            type="submit"
                            value="create account"
                            className="btn btn-primary"
                          />
                          
                           <Button variant="secondary" onClick={onRequestClose}>
                       Close
                        </Button> 
                        </div>
                       
                      </form>
                      <br/>
                     
                  

               </Modal.Body>
      <Modal.Footer>
     
       </Modal.Footer>
    </Modal>
  );
};
export default RegisterMd;
