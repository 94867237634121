import React, { useState, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";
import "./Loader.css";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Dacashamount(props) {
  const token = JSON.parse(localStorage.getItem("data")).token;
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  var curr_hour = date.getHours();
  var curr_min = date.getMinutes();

  var curr_sec = date.getSeconds();

  let currentDate = `${year}-${month}-${day}`;
  let currTime = `${curr_hour}:${curr_min}:${curr_sec}`;
  let finaltotal = 0;

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState([
    {
      total: 0,
    },
  ]);

  const [denom, setDenom] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [denomination, setDenomination] = useState({
    denominationnumber: "",
  });
  const [cash, setCash] = useState({
    vehicleid: props.params.vid,
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    totalamount: finaltotal,
    datetime: currentDate,
    time: currTime,
    denomoinationvalue: [
      {
        facevalue: "",
        denominationnumber: "",
      },
    ],
  });

  const navigate = useNavigate();

  const handleChange = (event, field, index, n) => {
    let actualValue = event.target.value;
    setDenomination({ ...denomination, [field]: actualValue });
    const newitem = [...total];
    newitem[index] = { total: actualValue * n };
    setTotal(newitem);
    const newproduct = [...cash.denomoinationvalue];
    newproduct[index] = {
      ...newproduct[index],
      [field]: actualValue,
    };
    if (field == "vehicleid") {
      setCash({ ...cash, vehicleid: actualValue });
    } else if (field == "totalamount") {
      setCash({ ...cash, totalamount: actualValue });
    } else {
      setCash({ ...cash, denomoinationvalue: newproduct });
    }
  };
  function handleChange1(e, ta) {
    setCash({ ...cash, totalamount: `${ta}` });
  }
  const handleSubmit = (event, finaltotal) => {
    event.preventDefault();
    setLoading(true);
    const totalcash = {
      vehicleid: props.params.vid,
      orgid: JSON.parse(localStorage.getItem("data")).orgcode,
      totalamount: `${finaltotal}`,
      datetime: currentDate,
      time: currTime,
      denomoinationvalue: cash.denomoinationvalue,
    };
    axios
      .post("/api/DeliveryAgentCashPayment/create", totalcash, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //    navigate("/users/");
        toast.success("Amount sent to admin for verification");

        setLoading(false);
        navigate("/users/updatetin");
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error("you don't have a permission");
        }
        //   navigate("/users/Deviceusermapping");

        setLoading(false);
      });
  };

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const uid = JSON.parse(localStorage.getItem("data")).userid;

    const { data } = await axios
      .get("/api/denominations/status=true", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setDenom(res.data);
        setCash({
          ...cash,
          denomoinationvalue: res.data.map((item) => ({
            facevalue: item.denominations,
          })),
        });
      });
  };

  useEffect(() => {
    getData();
  }, []);
  total.forEach((item) => {
    finaltotal += item.total;
  });
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div
            className="col-10 mx-xl-auto ps-xl-9"
            style={{ position: "relative", left: "130px" }}
          >
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="card-title">Denomination</div>

                <br></br>

                <form onSubmit={(e) => handleSubmit(e, finaltotal)}>
                  {denom.map((denom, index) => {
                    return (
                      <div className="d-flex">
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <input
                              className="form-control"
                              id="facevalue"
                              value={denom.denominations}
                              readOnly
                              onChange={(e) =>
                                handleChange(e, "facevalue", index)
                              }
                            />
                          </div>
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;*&nbsp;</div>
                        &nbsp; &nbsp;
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <input
                              className="form-control"
                              id="denominationnumber"
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  "denominationnumber",
                                  index,
                                  denom.denominations
                                )
                              }
                            />
                          </div>
                        </div>
                        <div>&nbsp;&nbsp;&nbsp;=&nbsp;</div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <input
                              className="form-control"
                              id="total"
                              key={index}
                              value={total[index]?.total}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="form-floating-sm mb-2 d-flex">
                      <h3>Total: </h3>{" "}
                      <input
                        className="form-control"
                        id="totalamount"
                        value={finaltotal}
                      />
                    </div>
                  </div>

                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
export default withRouter(Dacashamount);
