import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";
import RegisterMd from "./registermandd";
 
 
function Deliveryagentdetails() {
  const [Deliveryagentdetails, setDeliveryagentdetails] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const onClickhandler1 = async (e, vid, vusername, vrole) => {
  //   const token = JSON.parse(localStorage.getItem("data")).token;
  //   setLoading(vid);
  //   try {
  //     const vehicles = await axios.delete(`/api/accounts/delete/${vid}`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //       data: {
  //         id: vid,
  //         username: vusername,
  //         role: vrole,
  //         orgid: JSON.parse(localStorage.getItem("data")).orgcode,
  //       },
  //     });
  
  //     toast.success("Deleted successfully");
  //     getData();
  //     setLoading(null);
  //   } catch (error) {
  //     console.error("Error deleting vehicle:", error);
  //     setLoading(null);
  //     toast.error("Error deleting vehicle");
  //   }
  // };

 
  const getData = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    const trans = await axios.get( `/api/accounts/deliveryagents/orgid=${JSON.parse(localStorage.getItem("data")).orgcode
  }`, {
      headers: { Authorization: `Bearer ${token}` },
    });
 
    console.log(trans);
 
    setDeliveryagentdetails(trans.data);
  } catch (error) {
    console.error("Error fetching delivery agents:", error);
  } 
};
  useEffect(() => {
    getData();
  }, []);
  function onClickhandler(e, id, disabled) {
    setLoading(id);
    const checked = e.target.checked;
   console.log(checked);
    const token = JSON.parse(localStorage.getItem("data")).token;
    console.log(token);
    axios.post(`/api/accounts/update`, {
      id: id,
      disabled: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(null);
        toast.success(" status updated");
      });
  }
  const handleregistermd = () => {
    setIsModalOpen(true);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();

  };
 
  return (
    <div>
      <div>
        <Dashboard />
      </div>
      <br />
      <br />
      <br />
      <div>
        <div className="col-10  mx-xl-auto ps-xl-10">
          <div className="card card-raised " style={{marginTop:"10%"}}>
            <div className="card-body " >
              <div className="card-title">Delivery Agent Details</div>
              <br />
              <button className="btn btn-primary float-end" onClick={handleregistermd}>
               ADD
            </button>
            
              
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Id </th>
 
                    <th>UserName</th>
                    <th>Role</th>
                    <th>Orgcode</th>
                    <th>Status</th>
                    <th>Date</th>
 
 
 
                  </tr>
                </thead>
                <tbody>
                {Deliveryagentdetails.length === 0 ? (
                       <tr>
                      <td colSpan="6" className="text-center">NO DATA AVAILABLE</td>
                      </tr>
                     
                  ):(
                    Deliveryagentdetails.map((accd) => {
                    return (
                      <tr key={accd.id}>
                        <td>{accd.id}</td>
 
                        <td>{accd.username}</td>
                        <td>{accd.role}</td>
 
                        <td>{accd.orgid}</td>
 
                        <td>
                        <div className="form-check form-switch">
                          {loading == accd.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "55px",
                                top: "8px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={!accd.disabled}
                            onClick={(e) => onClickhandler(e, accd.id, !accd.disabled)}
                            value={accd.id}
                          />
                        </div>
                      </td>                      
                       <td>{accd.createdTime}</td>
                       {/* <td>
                        <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler1(
                              e,
                              accd.id,
                              accd.username,
                              accd.role
 
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                        {loading == accd.id && (
                          <div
                            className="loader"
                            style={{
                              position: "relative",
                              left: "40px",
                              top: "8px",
                            }}
                          ></div>
                        )}{" "}
                      </td> */}
 
 
 
                      </tr>
                     )
              
                    }))
                    }
                </tbody>
              </table>
              <RegisterMd isOpen={isModalOpen} onRequestClose={handleCloseModal} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Deliveryagentdetails;
 