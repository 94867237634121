import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import Search from "./search";
import { toast, ToastContainer } from "react-toastify";
import "./transaction.css";
function Loaddetails() {
  const [Loadin, setLoadin] = useState([]);
  const [Loadout, setLoadout] = useState([]);
  const [searchbydate, setSearchbydate] = useState(true);
  const [transactions1, setTransaction1] = useState([]);

  const today = new Date();
  function searchName(text) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    axios
      .get(
        `/api/LoadOutDo/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoader(false);
        setLoadout(res.data);
      });

    axios
      .get(
        `/api/LoadinDo/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoader(false);
        setLoadin(res.data);
      });

      axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res.data);
        setProduct(res.data);
      })
      .catch((error) => {
       // handleError(error);
      });
  }

  const [transactions, setTransaction] = useState([]);
  const [loader, setLoader] = useState(false);

  const [product, setProduct] = useState([]);

  const url = "/api/loadout";

  const getData = async () => {
    setLoader(true);
    const date = new Date();

    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios.get(
      `/api/LoadOutDo/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setTransaction(trans.data);
    console.log(trans.data)

    const trans1 = await axios.get(
      `/api/LoadinDo/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setLoader(false);
    setTransaction1(trans1.data);

    axios.get(
      `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      console.log(res.data);
      setProduct(res.data);
    })
    .catch((error) => {
     // handleError(error);
    });
  };

  const onClickdownloadLoadIn = async (e, id) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/loadin/download/id=${id}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      const contentType = response.headers["content-type"];
      let extension = "";
  
      // Determine the file extension based on content type
      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }
  
      // Create a blob URL and download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  const onClickdownloadLoadOut= async (e, id) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/loadout/download/id=${id}`,
      method: "GET",
      responseType: "blob",
       headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      const contentType = response.headers["content-type"];
      let extension = "";
  
      // Determine the file extension based on content type
      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }
  
      // Create a blob URL and download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br /> <br /> <br />
      <div style={{ position: "relative", left: "260px" }}>
        <Search searchName={searchName} />{" "}
      </div>
      <br />
      <div className="loadin" id="loadin">
        <div>
          <div className="col-12 mx-xl-auto ps-xl-10 ">
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="d-flex">
                  <div
                    className="card-title"
                    style={{
                      fontSize: "20px",
                      // backgroundColor: "blue",
                    }}
                  >
                    Load Incoming Details
                  </div>
                  <div className="col-9 d-flex justify-content-end ps-xl-6">
                  <Link to="/users/updateloadin">
                    <button className="btn btn-primary">Create Invoice</button>
                  </Link>
                </div>
                </div>
                <br />
                <table className="table table-bordered ">
                  <thead>
                    <tr
                      style={{
                        fontSize: "17px",
                        backgroundColor: " #6200ea",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <th>Vehicle Id</th>
                      {product.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
                      <th>Invoice Id</th>
                      <th>Download</th>


                    </tr>
                  </thead>
                  <tbody>
                  {searchbydate && transactions1.length === 0 ? (
            <tr>
              <td colSpan="6" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (searchbydate &&
                      transactions1.map((ttin) => {
                        return (
                          <tr className="text-center">
                            <td>{ttin.truckinfo}</td>
                            {ttin.pdo.map((ps, index) => {
                              return <th key={index}>{ps.qty}</th>;
                            })}
                            <td>{ttin.invoiceid}</td>
                            <th>
                                <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadIn(e, ttin.loadinId)
                                  }
                                >
                                  Download
                                </button>
                              </th>

                          </tr>
                        );
                      }))}
                     {!searchbydate && Loadin.length === 0 ? (
            <tr>
              <td colSpan="6" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (!searchbydate &&
                      Loadin.map((ttin) => {
                        return (
                          <tr className="text-center">
                            <td>{ttin.truckinfo}</td>
                            {ttin.pdo.map((ps, index) => {
                              return <th key={index}>{ps.qty}</th>;
                            })}
                            <td>{ttin.invoiceid}</td>
                            <th>
                                  <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadIn(e, ttin.loadinId)
                                  }
                                >
                                  Download
                                </button>
                              </th>


                          </tr>
                      );
                    }))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <div className="loadout" id="loadout">
        <div>
          <div className="col-12 mx-xl-auto ps-xl-10 ">
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="d-flex">
                  <div className="card-title">Load Outgoing Details</div>
                 <div className="col-9 d-flex justify-content-end ps-xl-6">
                  <Link to="/users/updateloadout">
                    <button className="btn btn-primary">Create ERV</button>
                  </Link>
                  </div>
                </div>
                <br />
                <table className="table table-bordered ">
                  <thead>
                    <tr
                      style={{
                        fontSize: "17px",
                        backgroundColor: " #6200ea",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      <th>Vehicle Id</th>
                      {product.map((pro)=>{
                      return <th>{pro.productname}</th>;
                      })}
                      <th>ERV Id</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                  {searchbydate && transactions.length === 0 ? (
            <tr>
              <td colSpan="6" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (searchbydate &&
                      transactions.map((tout) => {
                        return (
                          <tr className="text-center">
                            <td>{tout.truckinfo}</td>
                            {tout.pdo.map((ps, index) => {
                              return <th key={index}>{ps.qty}</th>;
                            })}
                            <td>{tout.ervid}</td>
                            <th>
                                <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadOut(e, tout.loadoutId)
                                  }
                                >
                                  Download
                                </button>
                              </th>


                          </tr>
                        );
                      }))}
                     {!searchbydate && Loadout.length === 0 ? (
            <tr>
              <td colSpan="6" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (!searchbydate &&
                      Loadout.map((tout) => {
                        return (
                          <tr className="text-center">
                            <td>{tout.truckinfo}</td>
                            {tout.pdo.map((ps, index) => {
                              return <th key={index}>{ps.qty}</th>;
                            })}
                            <td>{tout.ervid}</td>
                            <th>
                                <button className="btn btn-primary"
                                  onClick={(e) =>
                                    onClickdownloadLoadOut(e, tout.loadoutId)
                                  }
                                >
                                  Download
                                </button>
                              </th>
                           
                          </tr>
                        );
                      }))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {loader && <div className="loading"></div>}
        </div>
      </div>
    </div>
  );
}

export default Loaddetails;
