import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Dacashpayment() {
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [vehicle, setVehicle] = useState([]);
  const [vehicledetails, setVehicledetails] = useState({
    vehicle: "",
  });
  const [loading, setLoading] = useState(false);
  const url = "/api/product/create";
  const navigate = useNavigate();
  const getData = async () => {
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
  };

  useEffect(() => {
    getData();
  }, []);
  function cashHandler(e, vehicleid) {
    navigate(`/users/dacash/${vehicleid}`);
  }
  function rhandle(e) {
    let actualvalue = e.target.value;
    setVehicledetails({ vehicledetails, vehicle: actualvalue });
  }
  console.log(vehicledetails.vehicle, "vv");
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Update Cash</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form>
                    <select
                      id="vehicle"
                      type="text"
                      className="form-control"
                      placeholder="vehicle"
                      onChange={(e) => rhandle(e)}
                    >
                      <option>select vehicle</option>
                      {vehicle.map((vehicle) => {
                        return (
                          <option value={vehicle.truckinfo}>
                            {vehicle.truckinfo}
                          </option>
                        );
                      })}
                    </select>
                    <br />
                    <button
                      onClick={(e) => cashHandler(e, vehicledetails.vehicle)}
                      className="btn btn-primary"
                    >
                      {" "}
                      Click here to enter the amount
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default withRouter(Dacashpayment);
