import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { format, isValid } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';



import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import baseurl from "../api/baseurl";
import "./Loader.css";

function Attendencedetails() {
  const [currentDate, setCurrentDate] = useState(new Date());

  const [attendencedetails, setAttendencedetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Current month
  const [year, setYear] = useState(new Date().getFullYear()); // Current year
  const [filteredDates, setFilteredDates] = useState([]);
  const [filteredDays, setFilteredDays] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [orgIds, setOrgIds] = useState([]);
  const [deliveryagent, setdeliveryagent] = useState("");
  const [organizationName, setOrganizationName] = useState(""); // New state for organization name

  const [orgcodeFilter, setOrgcodeFilter] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const navigate = useNavigate();



  const generateDays = () => {
    const firstDay = new Date(selectedYear, selectedMonth - 1, 1);
    const lastDay = new Date(selectedYear, selectedMonth, 0);
    const daysArray = [];

    for (let day = firstDay.getDate(); day <= lastDay.getDate(); day++) {
      daysArray.push(day);
    }

    return daysArray;
  };

  const getData = async () => {
    try {
      setLoader(true);

      const token = JSON.parse(localStorage.getItem("data")).token;
      console.log("Fetching data with params:", selectedMonth, selectedYear, orgId, orgcodeFilter);

      console.log("month:", month);
      console.log("year:", year);


      // Fetch organization name based on the orgcode
      const orgProfileResponse = await axios.get(`/api/organizationprofiles/Orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setOrganizationName(orgProfileResponse.data.name); // Update the organization name state
      setCurrentDate(new Date());
      const response = await axios.get(`/api/UserAttendance/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode
        }/month=${selectedMonth}/year=${selectedYear}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setdeliveryagent(response.data)




      const orgIds = response.data.map((agent) => agent.orgid);
      setOrgIds(orgIds);
      const params = {
        month: selectedMonth,
        year: selectedYear,
      };
      if (orgId !== null) {
        params.orgId = orgId;
      }

      // Add orgcode filter to params
      if (orgcodeFilter) {
        params.orgcode = orgcodeFilter;
      }



      // const trans = await axios.get("api/UserAttendance", {
      //   headers: { Authorization: `Bearer ${token}` },
      //   params,

      // });
      // console.log("Response Data:", trans.data);


      // console.log(trans);
      // Filter the attendance data based on the selected month and year
      const filteredData = response.data.filter((agent) => {
        const formattedDate = format(new Date(agent.date), 'yyyy-MM-dd');
        const dataMonth = new Date(formattedDate).getMonth() + 1;
        const dataYear = new Date(formattedDate).getFullYear();

        return dataMonth === selectedMonth && dataYear === selectedYear;
      });
      console.log("Response Data:", response.data);
      console.log("Filtered Data:", filteredData);

      setAttendencedetails(filteredData);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching attendance details:", error);
      setLoader(false);
    }
  };


  useEffect(() => {
    getData();
    setFilteredDays(generateDays());
  }, [orgcodeFilter, orgId, selectedMonth, selectedYear]);
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
  ];

  const getAttendanceStatus = (agent, day) => {
    const formattedDate = `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const currentDate = new Date();
    const formattedCurrentDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    // Check if the date is in the future
    if (formattedDate > formattedCurrentDate) {
      return null; // Return null for future dates
    }

    // Check if the date in the header matches the formatted date and if attendance status is present
    const attendanceStatus = agent.attendanceMap && agent.attendanceMap[day];

    return attendanceStatus === true;
  };





  
 // ...

// ...

const handleDownload = () => {
  const doc = new jsPDF();

  // Add filter information
  doc.text(`Organization Name: ${organizationName}`, 10, 10);
  doc.text(`Orgcode: ${JSON.parse(localStorage.getItem("data")).orgcode}`, 10, 20);
  doc.text(`Date of report: ${format(currentDate, 'yyyy-MM-dd')}`, 10, 30);
  doc.text(`Month: ${monthNames[selectedMonth - 1]}`, 10, 40);
  doc.text(`Year: ${selectedYear}`, 10, 50);


  // Add a line to separate filter information from the table
  // doc.line(10, 60, 200, 60);
  // Extracted table data for PDF creation
  const tableData = [
    ["Delivery Agent", ...filteredDays.map(day => ` ${day}`)], // Use "Day 1", "Day 2", etc.
    ...deliveryagent.map(agent => [
      agent.deliveryAgentName,
      ...filteredDays.map(day => {
        const status = getAttendanceStatus(agent, day);
        return status === true ? 'P' : status === false ? 'A' : '';
      })
    ])
  ];

  // Create the PDF content
  doc.autoTable({
    head: [tableData[0]],
    body: tableData.slice(1),
    startY: 50, // Set the Y position for the table to start below filter information
  });

  // Save the PDF file
  doc.save('attendance_data.pdf');
};


// ...

// ...

  
    


  return (
    <div className="attendance-page" style={{marginTop:'100px'}}>
    <div className="dashboard-container">
        <Dashboard />
      </div>
      <br />
      <br />
      <br />
      <div className="content-container">
        <div className="col-12 mx-xl-auto ps-xl-30">
          <div className="card card-raised">
            <div className="card-body ">
              <div className="card-title">Attendance Details{" "}
                {loader && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <br />

            <div>

              {/* Dropdown for orgcode filter */}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label style={{ fontSize: '1.2em',color:"#000000" }}>Organization Name: {organizationName}</label>
            <br/><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;
              <label style={{ fontSize: '1.2em',color:"#000000"  }}>Orgcode: {JSON.parse(localStorage.getItem("data")).orgcode}</label>
              <br/><br/>
              {/* <select
              value={orgcodeFilter}
              onChange={(e) => setOrgcodeFilter(e.target.value)}
            >
              <option value="">All</option>
              {orgIds.map((orgId) => (
                <option key={orgId} value={orgId}>
                  {orgId}
                </option>
              ))}
            </select> */}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label style={{ fontSize: '1.2em',color:"#000000"  }}>
           Date of report: <span style={{  padding: '5px', borderRadius: '5px', marginRight: '10px' }}>{format(currentDate, 'yyyy-MM-dd')}</span>
           </label>  
           <br/><br/>
           &nbsp;&nbsp;&nbsp;&nbsp;
              <label style={{ fontSize: '1.2em',color:"#000000"  }}>Month</label>
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
              >
                {monthNames.map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month} {/* Display abbreviated month */}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;

              <label style={{ fontSize: '1.2em',color:"#000000"  }}>Select Year:</label>
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              >
                {Array.from({ length: 5 }, (_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;

              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


              <button className="btn btn-primary" onClick={handleDownload}>Download</button>


            </div>
            <br />
            <div className="table-responsive">
            <table className="table table-bordered horizontal-table" >
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}>
                  {/* <th>Orgcode</th>
                  <th>User Id</th> */}
                  <th>Deliveryagents</th>
                  {/* <th>Date</th>  */}

                  {/* <th>Date</th> */}
                  {/* <th>Attendance Status</th> */}



                  {filteredDays.map((day, index) => (
                    <th key={index}>{day}</th>
                  ))}

                </tr>
              </thead>
              <tbody>
                {deliveryagent.length > 0 ? (



                  deliveryagent.map((agent) => (
                    <tr key={agent.id}>
                      {/* Add new columns here */}
                      {/* <td>{accd.id}</td>
                   <td>{accd.orgcode}</td> */}
                      <td>{agent.deliveryAgentName}</td>
                      {/* <td>{format(new Date(agent.date), 'yyyy-MM-dd')}</td> */}

                      {/* <td>
                    <button onClick={() => handleDeliveryAgentClick(accd.deliveryagent)}>
                    {accd.deliveryagent} 
                  </button>
                    </td>    
         
                  <td>{format(new Date(accd.date), 'yyyy-MM-dd')}</td>
                   <td>{accd.attendancestatus}</td> */}



                      {/* Loop through days and display attendance status */}
                      {filteredDays.map((day, index) => (
                        <td key={index}>
                          {getAttendanceStatus(agent, day) === true ? '✓' : getAttendanceStatus(agent, day) === false ? '❌' : ''}
                        </td>
                      ))}
                    </tr>
                  ))



                ) : (
                  <tr>
                    <td colSpan="30" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            </div>

            {/* <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    <th>Id </th>

                    <th>Orgcode</th>
                    <th>Date</th>
                    <th>User Id</th>
                    <th>Attendance Status</th>
                    <th>Approval Status</th>
                    <th>Approver</th>
                    





                  </tr>
                </thead>
                <tbody>
                  {attendencedetails.map((accd) => {
                    return (
                      <tr key={accd.id}>
                        <td>{accd.id}</td>
                        <td>{accd.orgcode}</td>
                        <td>{accd.date}</td>
                        <td>{accd.userid}</td>
                        <td>{accd.attendancestatus}</td>
                        <td>{accd.approvalstatus}</td>
                        <td>{accd.approver}</td>
                      







                      </tr>
                    );
                  })}
                </tbody>
              </table> */}
          </div>
        </div>
      </div>

    </div>
  );
}

export default Attendencedetails;
