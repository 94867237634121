import React, { useEffect, useState, useReducer } from "react";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import Sidebar from "./adminsidebar";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import "./Spinner.css";
import "./Loader.css";
import "./approval.css";
function AdminApproval() {
  const [approvals, setApprovals] = useState([]);
  const [status, setStatus] = useState({
    id: "",
    approvalstatus: "",
    username: "",
    role: "",
    orgid: "",
  });
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const url = "/api/authority/orgid";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    if (!JSON.parse(localStorage.getItem("data")).role == "ROLE_SUPER") {
      const prof1 = await axios.get(
        `/api/authority/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log(prof1);

      setApprovals(prof1.data);
    } else {
      const prof1 = await axios.get("/api/authority", {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(prof1);

      setApprovals(prof1.data);
    }
  };

  useEffect(() => {
    getData();
  }, [reducerValue]);

  function rsubmit(e, id, user, astatus, orgid, role) {
    e.preventDefault();
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);

    const token = JSON.parse(localStorage.getItem("data")).token;
    if (!JSON.parse(localStorage.getItem("data")).role == "ROLE_SUPER") {
      axios
        .post(
          "/api/admin/approvals",
          {
            id: id,
            approvalstatus: astatus,
            username: user,
            role: role,
            orgid: orgid,
          },

          { headers: { Authorization: `Bearer ${token}` } }
        )

        .then((response) => {
          console.log(response.data);
          setLoading(false);
          forceUpdate();
        })

        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(
          "/api/superadmin/authority",
          {
            id: id,
            approvalstatus: astatus,
            username: user,
            role: role,
            orgid: orgid,
          },

          { headers: { Authorization: `Bearer ${token}` } }
        )

        .then((response) => {
          console.log(response.data);
          setLoading(false);
          forceUpdate();
        })

        .catch((error) => {
          console.log(error);
        });
    }
  }
  function rhandle(e) {
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);
  }
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div>
        <Sidebar />
      </div>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />
      <div
        style={{ position: "relative", top: "100px" }}
        className="d-flex align-items-right "
      >
        <div className="col-10  align-items-center mx-xl-auto  ps-xl-10">
          <div className="card card-raised mb-5">
            <div className="card-body p-5">
              <div className="card-title">Approval Details</div>
              <br />

              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th>ID </th>

                    <th>USERNAME</th>

                    <th>ROLE</th>

                    <th>ORGANIZATIONID</th>
                    <th>APPROVALSTATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {approvals.map((approval, index) => {
                    return (
                      <tr key={approval.id}>
                        <td>{approval.id}</td>

                        <td>{approval.username}</td>

                        <td>{approval.role}</td>
                        <td>{approval.orgid}</td>
                        {approval.approvalstatus == "REQUESTED" ? (
                          <td id="icon" value={approval.approvalstatus}>
                            &nbsp;
                            {approval.approvalstatus} &nbsp;
                            <BsCheckCircle
                              className="icon"
                              id="approval.approvalstatus"
                              style={{ backgroundColor: "green" }}
                              value="APPROVED"
                              onClick={(e) =>
                                rsubmit(
                                  e,
                                  approval.id,
                                  approval.username,
                                  "APPROVED",
                                  approval.orgid,
                                  approval.role
                                )
                              }
                            />
                            &ensp;
                            <BsXCircle
                              className="icon"
                              value="REJECTED"
                              style={{ backgroundColor: "red" }}
                              id="approval.approvalstatus"
                              onClick={(e) =>
                                rsubmit(
                                  e,
                                  approval.id,
                                  approval.username,
                                  "REJECTED",
                                  approval.orgid,
                                  approval.role
                                )
                              }
                            />
                          </td>
                        ) : (
                          <td id="icon" value={approval.approvalstatus}>
                            {" "}
                            {approval.approvalstatus}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default AdminApproval;
