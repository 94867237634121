import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";
import CreatableSelect from "react-select/creatable";
import Supersubscriptiondetails from "./supersubscriptiondetails";
// import { constants } from 'buffer';

function SubModule() {
  const [module, setModule] = useState({
    submodel: "",
    region: "",
    cost: "",
    features: [
      {
        features: "",
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [feature, setFeature] = useState([]);
  const [subfeature, setSubfeature] = useState([]);
  const [regions, setRegions] = useState([]);
  const [value, setValue] = useState("");
  const [subfeaturedropdown, setSubfeaturedropdown] = useState([]);
  const [vdevice,setVdevice]= useState([])
  const navigate = useNavigate();

  const token = JSON.parse(localStorage.getItem("data")).token;
  const handleChange1 = (event, field) => {
    let actualValue = event.target.value;

    setModule({ ...module, [field]: actualValue });
  };

  // let tra = { ...transaction, [field]: actualValue };

  // console.log(tra);

  const handleChange = (event, field, index) => {
    let actualValue = event.target.value;

    // let tra = { ...transaction, [field]: actualValue };

    // console.log(tra);

    const newproduct = [...module.features];

    newproduct[index] = {
      ...newproduct[index],

      [field]: actualValue,
    };
    setModule({ ...module, features: newproduct });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // setLoading(true);
    console.log(module);
    const body = {
      ...module,
      features: value.map((v1) => ({
        features: v1.label,
      })),
    };

    axios
      .post("/api/submodelfeature", body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // navigate("/users/subscription");
        toast.success("Subscription module added");
        setLoading(false);
        window.location.reload();
       
      })
      .catch((error) => {
        //   navigate("/users/Deviceusermapping");
        //  toast.error("The module must be unique");

        setLoading(false);
      });
  };

  const handleCreate = (inputValue) => {
    const newValue = [
      ...subfeaturedropdown,
      { value: inputValue, label: inputValue },
    ];
    setSubfeaturedropdown(newValue);
    console.log(inputValue, "inputvalue");
  };

  const getData = async () => {

    try {

      const token = JSON.parse(localStorage.getItem("data")).token;



      // Fetch data from the API

      const regionResponse = await axios.get(`/api/subscription-region`, {

        headers: { Authorization: `Bearer ${token}` },

      });



      const featuresResponse = await axios.get(`/api/subscription-features`, {

        headers: { Authorization: `Bearer ${token}` },

      });



      const modelResponse = await axios.get(`/api/subscription-model`, {

        headers: { Authorization: `Bearer ${token}` },

      });



      // Assuming the API responses contain arrays, set the states accordingly

      setRegions(regionResponse.data); // Ensure 'regionResponse.data' is an array

      setSubfeature(featuresResponse.data);

      setSubfeaturedropdown(featuresResponse.data.map((sf) => ({ value: sf.features, label: sf.features })));

      setVdevice(modelResponse.data);

    } catch (error) {

      console.error("Error fetching data:", error);

      // Handle error or set default values for states if needed

    }

  };



  useEffect(() => {

    getData();

  }, []);

// Check if 'regions' is an array before using '.map()'

if (!Array.isArray(regions) || regions.length === 0) {
  return <div>Loading...</div>;
}

  // const options={subfeature.map((sf)=> {return ${sf.features}`})}

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div id="layoutDrawer_content">
        {/* {loading && <div className="loading"></div>} */}

        <main>
          <div
            className="col-10 mx-xl-auto ps-xl-9"
            style={{
              position: "relative",
              left: "100px",
              top: "150px",
              width: "1000px",
            }}
          >
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="card-title">Subscription Module</div>
                <br></br>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="submodel ">Submodel </label>
                      <input
                        className="form-control"
                        id="submodel"
                        onChange={(e) => handleChange1(e, "submodel")}
                      />
                    </div>
                  </div>
                  <div className="form-floating-sm mb-2">

<label for="region"> Region</label>

<select

  className="form-control"

  id="Region"

   



onChange={(e) => handleChange1(e, "region")}>

    <option>select region</option>

    {regions.length > 0 &&
  regions.map((reg) => (
    <option key={reg.region} value={reg.region}>
      {reg.region}
    </option>
  ))}
                    </select>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="cost"> Cost</label>

                      <input
                        className="form-control"
                        id="cost"
                        onChange={(e) => handleChange1(e, "cost")}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="features"> Features</label>

                      <CreatableSelect
                        // defaultValue={feature}
                        // onChange={setFeature}
                        onChange={(newValue) => {
                          setValue(newValue);
                          console.log(newValue, "value");
                        }}
                        // onCreateOption={handleCreate}
                        isClearable
                        isMulti
                        options={subfeaturedropdown}
                      />

                      {/* <CreatableSelect
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={(newValue) => setValue(newValue)}
      onCreateOption={handleCreate}
      options={subfeature.map((sf)=> {return `${sf.features}`
    })}
    /> */}
                    </div>
                  </div>

                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Supersubscriptiondetails />
      </div>
    </div>
  );
}

export default SubModule;
