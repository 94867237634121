import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import moment from "moment";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
function Updateopenstock() {



  const [openstock, setOpenstock] = useState({
    date: "",

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "", productname:"" }],
  });
  const [openingstock, setOpeningstock] = useState({
    date: "",

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    prodDo: [{ pid: "", qty: "", status: "",productname:""}],
  });
  const [product, setProduct] = useState([]);

  const [productstate, setProductstatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [quantity, setQuantity] = useState([
    {
      quantity1: "",
      quantity2: "",
      quantity3: "",
    },
  ]);
  
  const navigate = useNavigate();
  const url = "/api/Openingstock/update";
  const getData = async () => {
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    const trans = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);
    setLoader(false);
    setProduct(trans.data);
    const ps = await axios.get("/api/entitytype", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(ps);

    setProductstatus(ps.data);



  
    
    const proddo = [];
    trans.data.forEach((element) => {
      const item = { pid: element.productcode, productname: element.productname };
      ps.data.forEach((psitem) => {
        item[psitem.producttype] = 0;
      });
      proddo.push(item);
    });
    setOpenstock({
      ...openstock,
      // prodDo: trans.data.map((trans1) =>
      //   ps.data.map((ps1) => ({
      //     pid: trans1.id,
      //     qty: 0,
      //     status: ps1.producttype,
      //   }))
      prodDo: proddo,
    });

    const trans1 = await axios.get("/api/Openingstock", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(trans1);

    setOpeningstock(trans1.data);
  };

  useEffect(() => {
    getData();

    

  }, []);
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        openstock,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("openingstock updated successfully");
        navigate("/users/stock");
      })

      .catch((error) => {
        console.log(error);
        toast.warning("openingstock not updated");
      });
  }
  // function rhandle(e, field) {
  //   let actualValue = e.target.value;

  //   if (field == "date") {
  //     setOpenstock({ ...openstock, date: actualValue });
  //   }

  //   let tra = { ...transaction, [field]: actualValue };
  //   console.log(tra);
  // }
  function onClickUpdate(e, id, state) {
    console.log("openstock", openstock);
    const token = JSON.parse(localStorage.getItem("data")).token;
    // console.log("openstock", openstock);
    // openstock.prodDo.forEach((element) => {
    //   productstate.forEach((ps) => {
    //     if (ps.producttype == "full")
    //       ({ ...openstock.prodDo, qty: element.field });
    //   });
    // });
    const newpro = [];
    openstock.prodDo.forEach((element) => {
      productstate.forEach((psitem) => {
        const item = {
          pid: element.pid,
          status: psitem.producttype,
          qty: parseInt(element[psitem.producttype]),
          productname:element.productname
        };

        newpro.push(item);
      });
    });

    const reqbody = {
      datetime: openstock.date,
      //  moment(openstock.date).format("YYYY-MM-DD"),

      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      prodDo: newpro,
    };
    axios
      .post(
        "/api/Openingstock/update",
        reqbody,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        toast.success("openingstock updated successfully");
        navigate("/users/stock");
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.success(error.response.data.message);
          // setLoader1(false);
          navigate("/users/stock");
        } else {
          toast.error(error.response.data.message);
          // setLoader1(false);
          navigate("/users/stock");
        }
      });
  }
  function rhandle(e, field, index) {
    let actualValue = e.target.value;
    // let tra = { ...transaction, [field]: actualValue };
    // console.log(tra);
    console.log("actualValue", actualValue);
    console.log("index", index);

    console.log("field", field);

    // newproduct[index] = {
    //   ...quantity[index],
    //   [field]: actualValue,
    // };

    const newproddo = [...openstock.prodDo];
    newproddo[index] = { ...openstock.prodDo[index], [field]: actualValue };
    const newos = { ...openstock, prodDo: newproddo };
    if (field == "date") {
      newos.date = actualValue;
    }
    setOpenstock(newos);
  }

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>{" "}
      {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "750px", top: "75px" }}
        ></div>
      )}
      <br /> <br /> <br /> <br />
      <div
        style={{ position: "relative", top: "100px", left: "150px" }}
        className="col-8  mx-xl-auto ps-xl-6"
      >
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div className="card-title">OpenStock Update</div>
            </div>
            <br />
            <input
              id="date"
              type="date"
              // value={new Date().toISOString().slice(0, 10)}

              onChange={(e) => rhandle(e, "date")}
            />{" "}
            <br />
            <br /> <br /> <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="empty">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
                {openstock.prodDo.map((products, index) => {
                  return (
                    <tr>
                      <td>{products.productname}</td>

                      <td key={index}>
                        <input
                          id="quantity1"
                          type="text"
                          value={products.full}
                          style={{ border: "none" }}
                          onChange={(e) => rhandle(e, "full", index)}
                        />
                      </td>
                      <td key={index}>
                        <input
                          id="quantity2"
                          type="text"
                          value={products.empty}
                          style={{ border: "none" }}
                          onChange={(e) => rhandle(e, "empty", index)}
                        />
                      </td>
                      <td key={index}>
                        <input
                          id="quantity3"
                          type="text"
                          value={products.defective}
                          style={{ border: "none" }}
                          onChange={(e) => rhandle(e, "defective", index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={(e) => onClickUpdate(e, product.id)}
            >
              update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Updateopenstock;
