import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";

const UserProfile = ({ isOpen, onRequestClose, userProfileDetails }) => {
  
    useEffect(() => {
       
      }, []);

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered >
      <Modal.Header closeButton>
        <Modal.Title>User Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
      <div className="d-flex flex-column align-items-center">
{userProfileDetails.image ? (
  <img
    className="img-fluid circular-image mb-1"
    id="image"
    src={`${userProfileDetails?.imageMeta},${userProfileDetails?.image}`}
    alt="Profile"
    style={{
      width: "100px",
      height: "100px",
      display: "block",
      borderRadius: "50%", // Use 50% border radius for a circular shape
      background: "#ccc", // Set a background color for the icon
      textAlign: "center",
      lineHeight: "170px",
      fontSize: "24px",
    }}
  />
) : (
  <div
    className="img-fluid circular-image mb-1"
    id="image"
    style={{
      width: "100px",
      height: "100px",
      display: "block",
      borderRadius: "50%", // Use 50% border radius for a circular shape
      background: "#ccc", // Set a background color for the icon
      textAlign: "center",
      lineHeight: "170px",
      fontSize: "24px",
    }}
  >
  </div>
)}
                     
                      </div>
                      
                              <form>
                              <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="organization">
                              First Name :
                            </label>
                            <input
                              className="form-control"
                              id="fisrtname"
                              type="text"
                              value={userProfileDetails.firstname}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="organization">
                              Last Name :
                            </label>
                            <input
                              className="form-control"
                              id="lastname"
                              type="text"
                              value={userProfileDetails.lastname}
                              readOnly
                            />
                          </div>
                        </div>

						<div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="organization">
                            Phone Number :
                            </label>
                            <input
                              className="form-control"
                              id="phone"
                              type="text"
                              value={userProfileDetails.phone}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="organization">
                            Email ID :
                            </label>
                            <input
                              className="form-control"
                              id="email"
                              type="email"
                              value={userProfileDetails.email}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="DOB">
                            Date Of Birth :
                            </label>
                            <input
                              className="form-control"
                              id="DOB"
                              type="text"
                              value={userProfileDetails.DOB}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="gender">
                            Gender :
                            </label>
                            <input
                              className="form-control"
                              id="gender"
                              type="text"
                              value={userProfileDetails.gender}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="company">
                           Company :
                            </label>
                            <input
                              className="form-control"
                              id="company"
                              type="text"
                              value={userProfileDetails.company}
                              readOnly
                            />
                          </div>
                        </div>
                      
                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label for="country">
                            Country :
                            </label>
                            <input
                              className="form-control"
                              id="country"
                              type="text"
                              value={userProfileDetails.country}
                              readOnly
                            />
                          </div>
                        </div>
                       
                    </form>                               
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserProfile;