import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();

    return <Component {...props} params={params} />;
  }
  return ComponentWithRouterProp;
}
function SettingseditModalPopup({ isOpen, onRequestClose, Id }) {


  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [uproduct1, setUproduct1] = useState({
    id: "",
    apikey: "",
    appname:"",
    username:"",
    token:"",
    url: "",
    userid: "",

    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
  const url = "/api/Locationtrack/id";
  const navigate = useNavigate();
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    await axios
      .get(`/api/Locationtrack/${Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUproduct1(res.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/Locationtrack/update",
        uproduct1,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success(" updated successfully");
        navigate("/users/Settings");
        onRequestClose();
      })

      .catch((error) => {
        console.log(error);
      });
  }

  const rhandle = (event, field) => {
    let actualValue = event.target.value;

    setUproduct1({ ...uproduct1, [field]: actualValue });
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update</Modal.Title>
    </Modal.Header>
    <Modal.Body>
     
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                  <label htmlFor="productname">Id</label>

                    <input
                      class="form-control"
                      id="id"
                      type="text"
                      placeholder="id"
                      value={uproduct1.id}
                      readOnly
                    />
                     
    
    
                    <br />
                    <label htmlFor="productname">User Id</label>

                    <input
                      className="form-control"
                      id="userid"
                      type="text"
                      placeholder="userid"
                      value={uproduct1.userid}
                      onChange={(e) => rhandle(e, "userid")}
                    />
                    <br />
                    <label htmlFor="productname">Api Key</label>

                    <input
                      className="form-control"
                      id="apikey"
                      type="text"
                      placeholder="apikey"
                      value={uproduct1.apikey}
                      onChange={(e) => rhandle(e, "apikey")}
                    />
                    <br />
                    <label htmlFor="productname">App Name</label>

                    <input
                      className="form-control"
                      id="appname"
                      type="text"
                      placeholder="appname"
                      value={uproduct1.appname}
                      onChange={(e) => rhandle(e, "appname")}
                    />
                    <br />
                    
                    {/* <input
                      className="form-control"
                      id="username"
                      type="text"
                      placeholder="username"
                      value={uproduct1.username}
                      onChange={(e) => rhandle(e, "username")}
                    />
                    <br />
                    <input
                      className="form-control"
                      id="token"
                      type="text"
                      placeholder="token"
                      value={uproduct1.token}
                      onChange={(e) => rhandle(e, "token")}
                    /> */}
                    
                    <label htmlFor="productname">URL</label>

                    <input
                      className="form-control"
                      id="url"
                      type="text"
                      placeholder="url"
                      value={uproduct1.url}
                      onChange={(e) => rhandle(e, "url")}
                    />
                    <br />

                    <input
                      type="submit"
                      value="Update"
                      className="btn btn-primary"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                  </form>
                </div>
              </div>
            </div>



         </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}

export default SettingseditModalPopup;
