import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import CreaterecordModal from "./Createrecord";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import SQCleveleditModalPopup from "./sqcleveledit";
import "./Loader.css";

function Addsqcnumber() {
  const date = new Date();
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [product, setProduct] = useState([]);
  const [exemptedcustomercategory, setExemptedcustomercategory] = useState([]);
  const [exemptedcustomercategory1, setexemptedcustomercategory1] =useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [freeze, setFreeze] = useState(() => {
    // Initialize freeze state from localStorage or default to false
    return localStorage.getItem("freeze") === "true";
  });
  const [AddExempteddelivery, setAddExempteddelivery] = useState({
    id:"",
    invoiceNumber: "",
    truckNumber: "",
    date: currentDate,
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    freeze: false,
  });
  const onClickhandler = async (e, id) => {
    if (freeze) return;
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    const vehicles = await axios({
      url: "/api/sqcleveldetails",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
      },
    }).then((res) => {
      toast.success("deleted successfully");
      fetchData();
      setLoading(null);
    });
  };

  const [loading, setLoading] = useState(false);
  const url = "/api/sqcform/create";
  const navigate = useNavigate();
  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setexemptedcustomercategory1({
      ...exemptedcustomercategory1,
      [field]: actualValue,
    });
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      const url = `/api/sqcleveldetails/orgcode=${AddExempteddelivery.orgcode}/date=${currentDate}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Check the structure of the response data
      console.log("Response Data:", response.data);
      // Confirm the presence of 'sqcId' in each object of the response data
      if (response.data.length > 0) {
        console.log("SQC ID of the first object:", response.data[0].sqcId);
      }
      setProduct(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    }
  };
  
  useEffect(() => {

    fetchData();
    localStorage.setItem("freeze", freeze.toString());

  }, [AddExempteddelivery.orgcode,freeze]);


function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
   

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        AddExempteddelivery,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Sqc details  added successfully");
        fetchData();
        navigate("/users/addsqcnumber");
      })

      .catch((error) => {
        console.log(error);
      });
  }
  function rhandle(e) {
    const newdata1 = { ...AddExempteddelivery };
    newdata1[e.target.id] = e.target.value;
    setAddExempteddelivery(newdata1);
  }
  

  const handlecreaterecord = () => {
    setIsModalOpen(true);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchData();
  };
  const handleEdit = (productId) => {
    if (freeze) return;
    setSelectedProductId(productId);
    setIsProductEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsProductEditModalOpen(false);
    fetchData();
    
  };
  
 
  
  const toggleFreeze = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.post(
        "/api/sqcleveldetails/freeze",
        { freeze: !freeze },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setFreeze((prevFreeze) => !prevFreeze);
        toast.success(`Product ${!freeze ? "frozen" : "unfrozen"} successfully`);
      }
    } catch (error) {
      toast.error("Error toggling freeze status");
      console.error("Error toggling freeze status:", error);
    }
  };

  return (
    <div className="attendance-page" style={{marginTop:'100px'}}>
    <div className="dashboard-container">
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="content-container">

      <div className="col-12 mx-xl-auto px-xl-30 "
      style={{ position: 'relative' , left: '10px' }}
      >
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title">Create</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div>
                <div>
                  <form onSubmit={rsubmit}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="invoiceNumber">Invoice Number</label>
                        <input
                          className="form-control"
                          id="invoiceNumber"
                          type="text"
                          placeholder="Invoice Number"
                          value={AddExempteddelivery.invoiceNumber}
                          onChange={rhandle}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="truckNumber">Truck Number</label>
                        <input
                          className="form-control"
                          id="truckNumber"
                          type="text"
                          placeholder="Truck Number"
                          value={AddExempteddelivery.truckNumber}
                          onChange={rhandle}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="date">Date</label>
                        <input
                          className="form-control"
                          id="date"
                          type="date"
                          value={AddExempteddelivery.date}
                          onChange={rhandle}
                        />
                      </div>
                    </div>
                    <br/>
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                     &nbsp;
             
             
                  </form>
                  <button className="btn btn-primary float-end" onClick={handlecreaterecord}>
              Create Record
           </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreaterecordModal isOpen={isModalOpen} onRequestClose={handleCloseModal} />

      </div>{" "}
      
      {loading && <div className="loading"></div>}

      <br />
      <br/>
      <br />
      <div>
      <div >
      <div className="col-12 mx-xl-auto px-xl-30 " >
      <div className="card card-raised">
          <div className="card-body">
        <div className="d-flex">
          <div className="card-title">Create record Details</div>
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


              <button
                  className="btn btn-primary"
                  onClick={toggleFreeze}
                  style={{
                    backgroundColor: freeze ? "red" : "green",
                  }}
                >
                  {freeze ? "Unfreeze" : "Freeze"}
                </button>
        </div>
        <br />
        <table className="table table-bordered ">
          <thead>
            <tr
             style={{
              fontSize: "17px",
              backgroundColor: "blue",
              color: "white",
            }}>
              <th>SQC Id</th>
              <th>Product Name</th>
              <th>Product Code</th>
              <th>Serial Number</th>
              <th>Seal Intact</th>
              <th>Date</th>
              <th>Gross weight</th>            
              <th>Tare Weight</th>
              <th>Remarks</th>
              <th>Returned product</th>
              <th>Erv Number</th>
               <th>Erv Date</th>
               <th>Actual Weight in kg</th>
               <th>Variation in grams</th>
               <th>Action</th>




            </tr>
          </thead>
          <tbody>
            {product.length === 0 ? (
              <tr>
                <td colSpan="15" className="text-center">
                  No Data Available
                </td>
              </tr>
            ) : (
              product.map((product) => {
                return (
                  <tr >
                    <td>{product.sqcId}</td>
                    <td>{product.productName}</td>
                    <td>{product.productCode}</td>
                    <td>{product.serialNumber}</td>
                    <td>{product.sealIntact}</td>
                    <td>{product.date}</td>
                    <td>{product.grossWeight}</td>
                    <td>{product.tareWeight}</td>
                    <td>{product.remarks}</td>
                    <td>{product.productReturned}</td>
                    <td>{product.ervNumber}</td>

                    <td>{product.ervDate}</td>
                    <td>{product.actualWeight}</td>

                    <td>{product.difference}</td>

                    <td>
                     
                     <button
                      onClick={() => handleEdit(product.id)}
                  style={{
                         border: "none",
                         background: "white",
                         size: "150px",
                       }}
                     >
                       <BiEditAlt />
                     </button>
                     
                        <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              product.id
                             
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                        {loading == product.id && (
                          <div
                            className="loader"
                            style={{
                              position: "relative",
                              left: "40px",
                              top: "8px",
                            }}
                          ></div>
                        )}{" "}
                     

                   </td>
                   
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        </div>
        </div>
      </div>
      {isProductEditModalOpen && (
              <SQCleveleditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeEditModal}
                productId={selectedProductId}
              />
            )}
</div>

    </div>
    </div>
    </div>
  );
}

export default Addsqcnumber;
