import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import ManagerSidebar from "./managerSidebar";
import "./Spinner.css";
function Updateclosestock() {
  const [closestock, setClosestock] = useState({
    productcode: "",
    orgcode: "",
    quantity: "",
    status: "",
    datetime: "",
  });
  const [loading, setLoading] = useState(false);
  const url = "/api/CloseStock/create";

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        closestock,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("ClosingStock updated successfully");
      })

      .catch((error) => {
        console.log(error);
        toast.warning("ClosingStock not updated");
      });
  }
  function rhandle(e) {
    const newdata = { ...closestock };
    newdata[e.target.id] = e.target.value;
    setClosestock(newdata);
  }
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Update Closestock</div>
            <br />

            <div className="col-xl-10 mx-xl-auto ">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="productcode">Product Id</label>
                        <input
                          className="form-control"
                          id="productcode"
                          type="number"
                          placeholder="productcode"
                          value={closestock.productcode}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="orgcode">Organization Id</label>
                        <input
                          className="form-control"
                          id="orgcode"
                          type="number"
                          placeholder="orgcode"
                          value={closestock.orgcode}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="quantity">Quantity</label>
                        <input
                          className="form-control"
                          id="quantity"
                          type="text"
                          placeholder="quantity"
                          value={closestock.quantity}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="status">Status</label>
                        <input
                          className="form-control"
                          id="status"
                          type="text"
                          placeholder="status"
                          value={closestock.status}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="datetime">Datetime</label>
                        <input
                          className="form-control"
                          id="datetime"
                          type="text"
                          placeholder="datetime"
                          value={closestock.datetime}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>

                    <input
                      type="submit"
                      value="update"
                      className="btn btn-primary"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default Updateclosestock;
