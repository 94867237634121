import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import "./a4shett.css";
import cynefianlogo from "../images/cynefianlogo.png";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let params = useParams();

    return <Component {...props} params={params} />;
  }
  return ComponentWithRouterProp;
}
function Invoice(props) {
  const [amount, setAmount] = useState([]);
  const [appcode, setAppcode] = useState({});
  const [costs, setCost] = useState({});
  const [order, setOrder] = useState({});
  const [serialnumber, setSerialnumber] = useState(1);
  const [username, setUsername] = useState(""); //Declare and initialize the username state
  const date = new Date();

  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let nextyear = year + 1;
  let currentDate = `${day}-${month}-${year}`;

  const navigate = useNavigate();

  const getData = async () => {
    await axios.get(`/api/paymentgatewaytax`).then((response) => {
      setAmount(response.data);
    });

    const id = `${props.params.id}`;
    await axios.get(`/api/subscription-model/${id}`).then((response) => {
      setCost(response.data);
    });
    await axios.get("/api/version/appcode").then((response) => {
      setAppcode(response.data);
      setInvoice(
        `IN - ${year} - ${nextyear} - ${response.data.appCode} - ${props.params.pid}`
      );
    });
  };

  // const storedData = localStorage.getItem("RegisterationDatat").username;
  useEffect(() => {
    // Retrieve username from local storage
    setUsername(JSON.parse(localStorage.getItem("RegistrationData")).username);
    getData();
  }, []);
  function Printbill(e) {
    window.print();
    localStorage.removeItem("RegistrationData");
    navigate("/signin");
  }
  let appcd = `${appcode.appCode}`;
  const [invoice, setInvoice] = useState("");

  console.log("ll", appcd);

  return (
    <div>
      <div id="layoutDrawer_content">
        <main>
          <div className="a4-sheet">
            <header className="bg-primary">
              <div className="container-xl px-5">
                <h1 class="text-white py-3 mb-0 display-6"></h1>
              </div>
            </header>
            <br />
            <br /> <br />
            <form>
              {amount.map((amount) => {
                const totalcost = +`${costs.cost}`;
                const gsttax = +`${amount.gsttax}`;
                const gatewaytax = +`${amount.gatewaytax}`;
                const Taxamount1 = (gsttax / 100) * totalcost + totalcost;
                const Taxamount3 = (gsttax / 100) * totalcost;
                const Taxamount2 = (gatewaytax / 100) * Taxamount1;
                const Tax1 = (gsttax / 100) * Taxamount2;
                const TotalTax = Taxamount2 + Tax1;
                const cgst = (gsttax / 2 / 100) * totalcost;
                const sgst = (gsttax / 2 / 100) * totalcost;

                const totalamount = Taxamount1 + TotalTax;
                const totaltransactioncost = Tax1 + Taxamount2;
                return (
                  <div>
                    <div className="d-flex">
                      <div>
                        <h5>
                          <b>Invoice</b>
                        </h5>
                        <br />
                        <h6>
                          <b>Cynefian</b>{" "}
                        </h6>{" "}
                        <h6> 1010/27 Mysore ROad</h6>{" "}
                        <h6>Gundlupet , Karnataka</h6>
                        <h6> PIN 571111</h6>
                        <h6> GSTIN : 29AAJCC7045D1ZM</h6>
                      </div>
                      <div>
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp;&ensp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <img src={cynefianlogo} width="125px" />
                      </div>
                    </div>
                    <br />
                    <div className="d-flex">
                      {" "}
                      <div>
                        <h6>
                          <b>Bill To</b>
                        </h6>

                        <h6>{`${username}`}</h6>
                      </div>
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <h6>
                        {" "}
                        <b>INVOICE </b>
                      </h6>
                      &ensp;&nbsp;&nbsp;&nbsp;
                      <h6>{`${invoice}`}</h6>
                    </div>
                    <hr style={{ height: "8px", color: "black" }} />
                    <div className="d-flex">
                      {" "}
                      <h2>Total Amount</h2>{" "}
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <h2>{totalamount.toFixed(2)}</h2>
                    </div>

                    <hr style={{ height: "8px", backgroundColor: "black" }} />
                    <div className="d-flex">
                      <div></div>&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;
                      <div>
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&ensp;&nbsp;{" "}
                        <div>
                          {" "}
                          <h6>Amount: {totalcost}</h6>
                          <h6>CGST:{cgst.toFixed(2)}</h6>
                          <h6>SGST:{sgst.toFixed(2)}</h6>
                          <h6>
                            Transaction Cost:{totaltransactioncost.toFixed(2)}
                          </h6>
                        </div>
                        <h2 style={{ color: "black" }}>
                          Total Amount: {totalamount.toFixed(2)}
                        </h2>
                      </div>
                    </div>
                    <br />
                    <div className="text-end d-flex">
                      &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                      &emsp;&emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;
                      &emsp;&emsp;&emsp; &emsp;&emsp;&emsp;
                      <button
                        onClick={(e) => Printbill(e)}
                        className="btn btn-primary"
                      >
                        Print
                      </button>
                    </div>
                  </div>
                );
              })}
            </form>
          </div>
        </main>
      </div>
    </div>
  );
}

export default withRouter(Invoice);
