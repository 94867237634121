import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import "./Spinner.css";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal,Button } from "react-bootstrap";
 
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();
 
    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Dagentandvehiclemapping({ isOpen, onRequestClose, params }) {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [davehicle, setDavehicle] = useState({
    deliveryagentid: "",
    vehicleid: params.vid,
  });
  const [vehicle, setVehicle] = useState([]);
  const [dAdetails, setDadetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const url = "/api/DeliveryAgentVehicleMapping/create";
  const navigate = useNavigate();
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    axios
 
      .post(
        url,
        davehicle,
 
        { headers: { Authorization: `Bearer ${token}` } }
      )
 
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("updated successfully");
        navigate("/users/vehicledetail");
        onRequestClose();
      })
 
      .catch((error) => {
        toast.error("Error updating");
      });
    const username = davehicle.deliveryagentid;
    axios.get(`/api/authority/username=${username}/isavailable=false`, {
      headers: { Authorization: `Bearer ${token}` },
    });
   
  }
  const rhandle = (e) => {
    const { id, value } = e.target;
    setDavehicle((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const getData = async () => {
    const date = new Date();
 
    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    const trans = await axios.get(
      `/api/authority/role=ROLE_DELIVERY_AGENTS/orgid=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/isavailable=true`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
 
    console.log(trans);
 
    setDadetails(trans.data);
 
    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
 
    console.log(vehicles);
 
    setVehicle(vehicles.data);
  };
 
  useEffect(() => {
    getData();
  }, []);
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Add Agents Vehicle Mapping</Modal.Title>
    </Modal.Header>
    <Modal.Body>
 
            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Truckinfo</label>
                        <input
                          className="form-control"
                          id="vehicleid"
                          type="text"
                          placeholder="truckinfo"
                          value={params.truckinfo}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                     <div className="row">
                     <div className="form-floating-sm mb-2">
                        <label for="date">date</label>
                        <input
                          className="form-control"
                          id="date"
                          type="date"
                          placeholder="date"
                          value={davehicle.date}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                      </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="organisation code">username</label>
                        <select
                          className="form-control"
                          id="deliveryagentid"
                          type="text"
                          placeholder="Vehicleid"
                          value={davehicle.deliveryagentid}
                          onChange={(e) => rhandle(e)}
                        >
                          <option>Select DeliveryAgent</option>
                          {dAdetails.map((dadetails) => {
                            return (
                              <option value={dadetails.username}>
                                {dadetails.username}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
 
                    <input
                      type="submit"
                      value="ADD"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
 
                  </form>
                </div>
              </div>
            </div>
 
 
           </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
 
export default Dagentandvehiclemapping;