import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";
  const Financialmanagment = ({ isOpen, onRequestClose }) => {

  const date = new Date();

  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");

  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const token = JSON.parse(localStorage.getItem("data")).token;

  const [uproductcost, setUproductcost] = useState({
    date: currentDate,
    vehicle: "",
    productcode:"",
    salecost: "",
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
    productname: "",
    newconection: "",
    additionalcost: "",
  });

  const [product, setProduct] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const url = "/api/fleetfinancial/create";
  const getData = async () => {
    const trans = await axios
      .get(
        `/api/product/productstatus=true/orgcode=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setProduct(res.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      });

    const vehicles = await axios
      .get(
        `/api/vehicle/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setVehicle(res.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        uproductcost,

        { headers: { Authorization: `Bearer ${token}` } }
      )
         .then((response) => {
        console.log(response.data);
        setLoading(false);
        navigate("/users/financialdetails");
        toast.success("Productcost updated successfully");
        onRequestClose(); 
      })

      .catch((error) => {
        console.log(error);
      });
  }
  function rhandle(e) {
    const newdata = { ...uproductcost };
    newdata[e.target.id] = e.target.value;
    setUproductcost(newdata);
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Product Cost</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Vehicleid</label>
                        <select
                          className="form-control"
                          id="vehicle"
                          type="text"
                          placeholder="vehicle"
                          value={uproductcost.vehicle}
                          onChange={(e) => rhandle(e)}
                        >
                          <option>select vehicle</option>
                          {vehicle.map((vehicle) => {
                            return (
                              <option value={vehicle.truckinfo}>
                                {vehicle.truckinfo}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Product Name</label>
                        <select
                          className="form-control"
                          id="productname"
                          type="text"
                          placeholder="vehicle"
                          value={uproductcost.productname}
                          onChange={(e) => rhandle(e)}
                        >
                          <option>select product</option>
                          {product.map((pro) => {
                            return (
                              <option value={pro.productname}>
                                {pro.productname}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Product Code</label>
                        <select
                          className="form-control"
                          id="productcode"
                          type="text"
                          placeholder="vehicle"
                          value={uproductcost.productcode}
                          onChange={(e) => rhandle(e)}
                        >
                          <option>select product</option>
                          {product.map((pro) => {
                            return (
                              <option value={pro.productcode}>
                                {pro.productcode}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Salescost</label>
                        <input
                          className="form-control"
                          id="salecost"
                          type="text"
                          value={uproductcost.salecost}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Newconnection</label>
                        <input
                          className="form-control"
                          id="newconection"
                          type="text"
                          value={uproductcost.newconection}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Additionalcost</label>
                        <input
                          className="form-control"
                          id="additionalcost"
                          type="text"
                          value={uproductcost.additionalcost}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <input
                      type="submit"
                      value="update"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;

<Button variant="secondary" onClick={onRequestClose}>
Close
</Button>
                  </form>
                </div>
              </div>
            </div>

            </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default Financialmanagment;
