import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import Search from "./search";
import { toast } from "react-toastify";
import AdminDashboard from "./admindashboard";
import { IoSearchOutline } from "react-icons/io5";
import "reactjs-popup/dist/index.css";
import "./Spinner.css";
import "./Loader.css";
import Modal from "react-modal";

function Stock() {
  const [openstock, setOpenstock] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [searchbytoutvehicle, setSearchbytoutvehicle] = useState([]);
  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [closestock, setClosestock] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    datetime: currentDate,
  });
  const [closestockone, setClosestockone] = useState([]);
  const [closestocktwo, setClosestocktwo] = useState([]);
  const [closestockByCurrentdate, setClosestockByCurrentdate] = useState([]);
  const [openingstock, setOpeningstock] = useState([]);
  const [closestockBydate, setClosestockByDate] = useState([]);
  const [searchstate, setSearchstate] = useState(true);
  const [tsearchstate, setTsearchstate] = useState(true);
  const [searchbydate, setSearchbydate] = useState([]);
  const [searchloadin, setSearchloadin] = useState([]);
  const [searchloadout, setSearchloadout] = useState([]);
  const [searchtin, setSearchtin] = useState([]);
  const [searchtout, setSearchtout] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [Searchclosestock, setSearchclosestock] = useState([]);
  const [searchDaDetails, setSearchDaDeatails] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [tto1, setTto1] = useState([]);
  const [ttin, setTtin] = useState([]);
  const [tlin, setTLin] = useState([]);
  const [tlout, setTLout] = useState([]);
  const [tindetails, settindetails] = useState([]);
  const [toutdetails, settoutdetails] = useState([]);
  const [lindetails, setLindetails] = useState([]);
  const [loutdetails, setLoutdetails] = useState([]);
  const [searchbyvehicle, setSearchbyvehicle] = useState([]);
  const [searchbyv, setSearchbyv] = useState(true);
  const [vehicledetails, setVehicledetails] = useState({
    vehicleid: "",
  });
  const [amountdenominationdetails, setdenominationdetails] = useState([]);
  const [totalamountdetails, setTotalamountdetails] = useState([]);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [returnfilteredStatus, setReturnfilterstatus] = useState([]);
  const [toutfilteredStatus, setToutfilterstatus] = useState([]);
  const [searchDate, setSearchDate]= useState(currentDate);

  const [lastUpdatedClosestock, setLastUpdatedClosestock] = useState([]);
  const [dapaymentdetails, setDapaymentdetails] = useState([]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); 
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day}/${monthNames[monthIndex]}/${year}`;
  }
  const searchName = async (text) => {
    setSearchstate(!searchstate);
    setSearchDate(text);

    setTsearchstate(!tsearchstate);
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await Promise.all([
      axios
        .get(
          `/api/OpeningstockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setSearchbydate(response.data.prodDo);
          console.log(response.data);
        }),
        axios
        .get(
          `/api/Openingstock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setOpeningstock(response.data);
        }),
        (async () => {
          try {
            const loadInResponse = await axios.get(
              `/api/totalLoadinDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setSearchloadin(loadInResponse.data.prodDo);
          } catch (error) {
            if (error.response && error.response.status === 404) {
              console.error("Data not found for totalLoadinDo:", error.response.data);
            } else {
              console.error("An error occurred for totalLoadinDo:", error);
            }
          }
        })(),
        (async () => {
          try {
            const loadOutResponse = await axios.get(
              `/api/totalLoadoutDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setSearchloadout(loadOutResponse.data.prodDo);
          } catch (error) {
            if (error.response && error.response.status === 404) {
              console.error("Data not found for totalLoadoutDo:", error.response.data);
            } else {
              console.error("An error occurred for totalLoadoutDo:", error);
            }
          }
        })(),
      axios
        .get(
          `/api/totaltransaction/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setSearchtin(response.data);
        }),
      axios
        .get(
          `/api/totaltransactionDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setSearchtout(response.data.prodDo);
        }),
      axios
        .get(
          `/api/CloseStockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setSearchclosestock(response.data.prodDo);
        }),
        axios
        .get(
          `/api/CloseStock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setClosestockByDate(response.data);
        }),

        axios
        .get(
          `/api/DacashDetails/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${text}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setSearchDaDeatails(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            //toast.success(error.response.data.message);
          } else {
            //toast.error(error.response.data.message);
          }
        }),
    ]);

     setLoading(false);
    
  };
  function denominationdetails(text, vehicleid){
    setModalIsOpen(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .get(
        `/api/DeliveryAgentCashPayment/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res);
        setTotalamountdetails(res.data);
        setLoading(false);
      });
    axios
      .get(
        `/api/DeliveryagentDenomination/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${text}/vehicleid=${vehicleid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setdenominationdetails(res.data);
        console.log(res);
        setLoading(false);
      });
  }
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    setLoader(true);
    setLoader5(true);
    setLoader1(true);
    setLoader2(true);
    setLoader3(true);
    setLoader4(true);
    await Promise.all([
      axios
        .get(
          `/api/OpeningstockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setLoader(false);
          setOpenstock(response.data.prodDo);
        }),

      axios
        .get(
          `/api/Returntransaction/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          console.log(res);
          setReturnfilterstatus({
            filteredStatus: res.data.filter(
              (status) => status.approvalstatus === "Requested"
            ),
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
          } else {
           // toast.error(error.response.data.message);
          }
        }),
      axios
        .get(
          `/api/transactionoutgoing/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          console.log(res);
          setToutfilterstatus({
            toutfilteredStatus: res.data.filter(
              (status) => status.approvalstatus === "Requested"
            ),
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            //toast.success(error.response.data.message);
          } else {
           // toast.error(error.response.data.message);
          }
        }),
      axios
        .get(
          `/api/product/productstatus=true/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setProduct(res.data);
        }),

      axios
        .get(
          `/api/vehicle/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setVehicle(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            //toast.success(error.response.data.message);
          } else {
            //toast.error(error.response.data.message);
          }
        }),

      // setLoader(true);
      axios
        .get(
          `/api/CloseStockDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setLoader(false);
          setClosestockone(response.data.prodDo);

          // setProductstock(trans.data.prodDo[0].pdo);
          console.log("openstock:", openstock);
        }),
        axios
        .get(
          `/api/CloseStock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setLoader(false);
          setClosestockByCurrentdate(response.data);

          // setProductstock(trans.data.prodDo[0].pdo);
          console.log("openstock:", openstock);
        }),
      // setLoader(true);
      axios
        .get(
          `/api/Openingstock/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/datetime=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setLoader(false);
          setOpeningstock(response.data);
        }),

        axios
        .get(
          `/api/CloseStock/lastUpdated/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setLoader(false);
          setLastUpdatedClosestock(response.data);
        }),
      axios
        .get(
          `/api/totalLoadinDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTLin(res.data.prodDo);
          setLoader1(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
            setLoader1(false);
          } else {
            //toast.error(error.response.data.message);
            setLoader1(false);
          }
        }),
      axios
        .get(
          `/api/totalLoadoutDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTLout(res.data.prodDo);
          setLoader2(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
            setLoader2(false);
          } else {
            //toast.error(error.response.data.message);
            setLoader2(false);
          }
        }),
      axios
        .get(
          `/api/totaltransaction/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTtin(res.data);
          console.log(res)
          setLoader3(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
            setLoader3(false);
          } else {
            //toast.error(error.response.data.message);
            setLoader3(false);
          }
        }),
      axios
        .get(
          `/api/totaltransactionDo/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setTto1(res.data.prodDo);
          setLoader4(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
            setLoader4(false);
          } else {
           // toast.error(error.response.data.message);
            setLoader4(false);
          }
        }),

      axios
        .get(
          `/api/totalloadin/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setLindetails(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
          } else {
            //toast.error(error.response.data.message);
          }
        }),

      axios
        .get(
          `/api/totalloadout/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setLoutdetails(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
          } else {
            //toast.error(error.response.data.message);
          }
        }),

      axios
        .get(
          `/api/totaltransaction/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          settindetails(res.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
          } else {
           // toast.error(error.response.data.message);
          }
        }),

      axios
        .get(
          `/api/totaltransactionout/orgid=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          settoutdetails(res.data);
          console.log(toutdetails);
        })
        .catch((error) => {
          if (error.response.status === 404) {
           // toast.success(error.response.data.message);
          } else {
            //toast.error(error.response.data.message);
          }
        }),

      axios
        .get(
          `/api/DacashDetails/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }/date=${currentDate}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setDapaymentdetails(res.data);
          console.log(res.data);
          setLoader5(false);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            //toast.success(error.response.data.message);
            setLoader5(false);
          } else {
            //toast.error(error.response.data.message);
            setLoader5(false);
          }
        }),
    ]);
  };

  useEffect(() => {
    getData();
  }, []);
  const searchbyvehi = async (e, id) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbyv(false);
    if (id === "ALL") {
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
           { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbyvehicle(tinResponse.data);
  
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbytoutvehicle(toutvehicleResponse.data.prodDo);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      try {
        const tinResponse = await axios.get(
          `/api/totaltransaction/vehicleid=${id}/date=${searchDate}/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbyvehicle(tinResponse.data);
  
        const toutvehicleResponse = await axios.get(
          `/api/totaltransactionDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${searchDate}/vehicleid=${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchbytoutvehicle(toutvehicleResponse.data.prodDo);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  console.log(searchbyvehicle, "sdsd");
  const onClickSearch = async (e, date) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios
      .get(`/api/Stockfilter/datetime=${date}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOpenstock(response.data.prodDo);
        console.log("openstock:", openstock);
      });

    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
  };
  const createclosestock = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios
      .post(`/api/CloseStock/create`, closestock, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        toast.success("closestock created successfully");
        setClosestock(response.data);
      });
  };
  console.log("filter", returnfilteredStatus);
  console.log("tfilter", toutfilteredStatus);
  const onclickhandler = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;

    setLoading(true);
      {
      axios
        .post("/api/totalloadin/create", lindetails, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
        });
      axios
        .post("/api/totalloadout/create", loutdetails, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
        });
      axios
        .post("/api/totaltransaction/create", tindetails, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
        });
      axios
        .post("/api/totaltransactionout/create", tto1, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          toast.success("Transaction End Successfully");
          setLoading(false);
        });
      axios
        .post("/api/DacashDetails/create", dapaymentdetails, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
        });

      await axios
        .post("/api/CloseStock/update", openingstock, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          toast.success("closestock updated successfully");
          setClosestocktwo(res.data);
          setLoading(false);
        });
       } 
    window.location.reload();

  };
  function rhandle(e, field) {
    e.preventDefault();
    let actualValue = e.target.value;

    setVehicledetails({ vehicledetails, [field]: actualValue });
  }
  console.log(vehicledetails, "fff");
 
  function openModal(e, vehicleid) {
    setModalIsOpen(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    axios
      .get(
        `/api/DeliveryAgentCashPayment/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${currentDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res);
        setTotalamountdetails(res.data);
        setLoading(false);
      });
      
    axios
      .get(
        `/api/DeliveryagentDenomination/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/datetime=${currentDate}/vehicleid=${vehicleid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setdenominationdetails(res.data);
        console.log(res);
        setLoading(false);
      });
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  const customStyles = {
    content: {
      width: "25%",
      backgroundColor: "rgba(0, 0, 0, .1)",
      position: "relative",
      left: "550px",
      top: "200px",
    },
  };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      {/* {" "}
      {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "750px", top: "75px" }}
        ></div>
      )} */}
      <br /> <br /> <br /> <br />
      
      <div
        style={{ position: "relative", top: "40px" }}
        className="col-13  mx-xl-auto ps-xl-10"
      >

              <div >
  <div className="card-body">
    <div className="d-flex">
    <br />
    <table className="table table-bordered ">
      <thead>
        <tr
          style={{
            fontSize: "17px",
            color: "black",
            backgroundColor:"white",
            textAlign: "center",
          }}
        >
          <th>
            Last Day End Transaction Completed on :{" "}
            {lastUpdatedClosestock && (
              <span className="ml-2">{formatDate(lastUpdatedClosestock.datetime)}</span>
              
            )}
            <br/>
            <br/>
              <Search searchName={searchName} />{" "}

          </th>
        </tr>
      </thead>
    </table>
    </div>
  </div>
</div>
</div>{" "}

{(openingstock.length===0 && searchstate && tsearchstate) &&(
             <div style={{ position: "relative", top: "40px" , textAlign:"center"}}
             className="col-11  mx-xl-auto ps-xl-10">
             <div className="card card-raised">
                 <div className="card-body ">
  
                   <h5 style={{color:"red"}}>Please Complete Day End Transaction for Previous Date</h5>
                   <br/>
                  <Link to="/users/Updateopenstock">
                      <button className="btn btn-primary">Create Exception</button>
                  </Link>
            
              </div>
            </div>
            </div>
)}

       {(searchstate && tsearchstate && openingstock.length > 0) && (     
      <div>
            <br></br>
             <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body ">
            <div className="d-flex">
              <div className="card-title">OpenStock Details</div>
              {" "}
                  {loader1 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}             
             <div style={{ position: "relative", marginLeft: "60%" }}>
              <Link to="/users/Updateopenstock">
                <button className="btn btn-primary">Update Stock</button>
              </Link>
            </div>
            </div>
            <br />
            
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="defective">Defective</th>
                </tr>{" "}
              </thead>
              <tbody>
              {openstock.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                searchstate &&
                  openstock.map((products) => {
                    return (
                      <tr>
                        <th>{products.product.productname}</th>
                        {products.pdo.map((ps, index) => {
                          return <th key={index}>{ps.qty}</th>;
                        })}
                      </tr>
                    );
                  }))}
              </tbody>
            </table>
          </div>
        </div>
       </div>

      <br /> <br /> <br /> 
      <div>
        <div className="col-12  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                    // backgroundColor: "blue",
                  }}
                >
                  Total LoadIn Details{" "}
                  {loader1 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                </div>
              </div>
              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th>{prod.productname}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {searchstate && tlin.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                    tlin.map((ttin) => {
                      return (
                        <tr>
                          <th>{ttin.vehicle}</th>
                          {ttin.pdo.map((ps, index) => {
                            return <th key={index}>{ps.qty}</th>;
                          })}
                        </tr>
                      );
                    }))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-12  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                  }}
                >
                  Total LoadOut Details
                  {loader2 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                </div>
              </div>
              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th>{prod.productname}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {searchstate && tlout.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                    tlout.map((ttin) => {
                      return (
                        <tr>
                          <th>{ttin.vehicle}</th>
                          {ttin.pdo.map((ps, index) => {
                            return <th key={index}>{ps.qty}</th>;
                          })}{" "}
                        </tr>
                      );
                    }))}
                    
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div>
      <div className="col-12  mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                    // backgroundColor: "blue",
                  }}
                >
                  Total Transactionout
                  {loader4 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                </div>
                &nbsp;&nbsp;&nbsp;
                &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div>
                  {" "}
                  <select
                    id="vehicleid"
                    type="text"
                    placeholder="vehicleid"
                    onChange={(e) => rhandle(e, "vehicleid")}
                  >
                    <option>ALL</option>
                    {vehicle.map((vehicle) => {
                      return (
                        <option value={vehicle.truckinfo}>
                          {vehicle.truckinfo}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                    onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
                </div>
              </div>
              <br />
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th>{prod.productname}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                {tto1.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                    searchbyv &&
                    searchstate &&
                    tto1.map((ttin) => {
                      return (
                        <tr>
                          <th>{ttin.vehicle.truckinfo}</th>
                          {ttin.pdo.map((ps, index) => {
                            return <th key={index}>{ps.qty}</th>;
                          })}
                        </tr>
                      );
                    }))}
                  {!searchbyv &&
                    searchbytoutvehicle.map((ttout) => {
                      return (
                        <tr>
                          <th>{ttout.vehicle.truckinfo}</th>
                          {ttout.pdo.map((ps1, index) => {
                            return <th key={index}>{ps1.qty}</th>;
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-12 mx-xl-auto ps-xl-10">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="d-flex">
                <div
                  className="card-title"
                  style={{
                    fontSize: "20px",
                    // backgroundColor: "blue",
                  }}
                >
                  Total TransactionIn
                  {loader3 && (
                    <div
                      className="loader"
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "10px",
                      }}
                    ></div>
                  )}
                </div>
                &nbsp;&nbsp;&nbsp;
                &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {/* <div>
                  {" "}
                  <select
                    id="vehicleid"
                    type="text"
                    placeholder="vehicleid"
                    onChange={(e) => rhandle(e, "vehicleid")}
                  >
                    <option>select vehicle</option>
                    {vehicle.map((vehicle) => {
                      return (
                        <option value={vehicle.truckinfo}>
                          {vehicle.truckinfo}
                        </option>
                      );
                    })}
                  </select>{" "}
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                    onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
              </div> */}
             </div>

              <br />
              <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Vehicle Id</th>
                    <th>Product Name</th>

                    <th>Total Sale </th>
                    <th>Exempted</th>
                    <th>New Organization</th>
                    <th>Additionals</th>
                    <th>Onlinepay</th>
                    <th>Returned Product</th>
                    <th>Transfer</th>
                    <th>Total Quantity</th>
                    <th>Total Amount</th>
                    
                  </tr>
                </thead>
                <tbody>
                {ttin.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                  searchbyv &&
                    tsearchstate &&
                    ttin.map((ttin) => {
                      return (
                        <tr>
                          {ttin.vehicleid == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.vehicleid}</td>
                          )}

                          {ttin.productname == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.productname}</td>
                          )}
                           {ttin.totalsale == null ? (
                            <td>{ttin.totalofallsale}</td>
                          ) : (
                            <td>{ttin.totalsale}</td>
                          )}
                           {ttin.totalexempted == null ? (
                            <td>{ttin.totalofallexempted}</td>
                          ) : (
                            <td>{ttin.totalexempted}</td>
                          )}
                           {ttin.totalneworg == null ? (
                            <td>{ttin.totalofallneworg}</td>
                          ) : (
                            <td>{ttin.totalneworg}</td>
                          )}
                           {ttin.totaladditional == null ? (
                            <td>{ttin.totalofalladditional}</td>
                          ) : (
                            <td>{ttin.totaladditional}</td>
                          )}
                           {ttin.totalonlinepay == null ? (
                            <td>{ttin.totalofallonlinepay}</td>
                          ) : (
                            <td>{ttin.totalonlinepay}</td>
                          )}
                           {ttin.totalreturnedproduct == null ? (
                            <td>{ttin.totalofallreturnedproduct}</td>
                          ) : (
                            <td>{ttin.totalreturnedproduct}</td>
                          )}
                           {ttin.totaltransfer == null ? (
                            <td>{ttin.totalofalltransfer}</td>
                          ) : (
                            <td>{ttin.totaltransfer}</td>
                          )}

                          {ttin.totalquantity == null ? (
                            <td>{ttin.totalofallquantity}</td>
                          ) : (
                            <td>{ttin.totalquantity}</td>
                          )}
                         
                         
                         
                         
                         
                          {/* {ttin.totaloutgoingcylinder == null ? (
                            <td>{ttin.totalofalloutgoingcylinder}</td>
                          ) : (
                            <td>{ttin.totaloutgoingcylinder}</td>
                          )} */}
                          

                         
                          {ttin.totalamount == null ? (
                            <td>{ttin.totalofallamount}</td>
                          ) : (
                            <td>{ttin.totalamount}</td>
                          )}
                        </tr>
                      );
                    }))}
                  {!searchbyv &&
                    searchbyvehicle.map((ttin) => {
                      return (
                        <tr>
                          {ttin.vehicleid == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.vehicleid}</td>
                          )}

                          {ttin.productname == null ? (
                            <td>Total</td>
                          ) : (
                            <td>{ttin.productname}</td>
                          )}
                          {ttin.totalsale == null ? (
                            <td>{ttin.totalofallsale}</td>
                          ) : (
                            <td>{ttin.totalsale}</td>
                          )}
                           {ttin.totalexempted == null ? (
                            <td>{ttin.totalofallexempted}</td>
                          ) : (
                            <td>{ttin.totalexempted}</td>
                          )}
                          {ttin.totalneworg == null ? (
                            <td>{ttin.totalofallneworg}</td>
                          ) : (
                            <td>{ttin.totalneworg}</td>
                          )}
                           {ttin.totaladditional == null ? (
                            <td>{ttin.totalofalladditional}</td>
                          ) : (
                            <td>{ttin.totaladditional}</td>
                          )}
                            {ttin.totalonlinepay == null ? (
                            <td>{ttin.totalofallonlinepay}</td>
                          ) : (
                            <td>{ttin.totalonlinepay}</td>
                          )}
                           {ttin.totalreturnedproduct == null ? (
                            <td>{ttin.totalofallreturnedproduct}</td>
                          ) : (
                            <td>{ttin.totalreturnedproduct}</td>
                          )}
                           {ttin.totaltransfer == null ? (
                            <td>{ttin.totalofalltransfer}</td>
                          ) : (
                            <td>{ttin.totaltransfer}</td>
                          )}
                          


                          {ttin.totalquantity == null ? (
                            <td>{ttin.totalofallquantity}</td>
                          ) : (
                            <td>{ttin.totalquantity}</td>
                          )}
              
                          {/* {ttin.totaloutgoingcylinder == null ? (
                            <td>{ttin.totalofalloutgoingcylinder}</td>
                          ) : (
                            <td>{ttin.totaloutgoingcylinder}</td>
                          )} */}
                          
                          {ttin.totalamount == null ? (
                            <td>{ttin.totalofallamount}</td>
                          ) : (
                            <td>{ttin.totalamount}</td>
                          )}
                        </tr>
                      );
                    })}
                  
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
        

      </div>
      <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div
                className="card-title"
                style={{
                  fontSize: "20px",
                  // backgroundColor: "blue",
                }}
              >
                Delivery Agent Payment Details
                {loader5 && (
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      left: "20px",
                      top: "10px",
                    }}
                  ></div>
                )}
              </div>
              &nbsp;&nbsp;&nbsp;
              &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Vehicle Id</th>
                  <th>Total Amount</th>
                  <th>Paid Amount</th>
                  <th>Pending Amount</th>
                </tr>
              </thead>
              <tbody>
              {dapaymentdetails.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                dapaymentdetails.map((dacash, index) => {
                  return (
                    <tr key={index}>
                      <th>{dacash.vehicleid}</th>
                      <th>{dacash.totalamount}</th>
                      <th>
                        <button
                          onClick={(e) => openModal(e, dacash.vehicleid)}
                          style={{ border: "none", backgroundColor: "white" }}
                        >
                          {dacash.paidamount}
                        </button>{" "}
                        <div>
                          <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Facevalue</th>

                                  <th>Denomination Number</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {amountdenominationdetails.map((dadenom) => {
                                  return (
                                    <tr>
                                      <th>{dadenom.facevalue}</th>
                                      <th>{dadenom.denominationnumber}</th>
                                      <th>
                                        {dadenom.facevalue *
                                          dadenom.denominationnumber}
                                      </th>
                                    </tr>
                                  );
                                })}
                              </tbody>{" "}
                            </table>
                            <button
                              className="btn btn-primary"
                              onClick={closeModal}
                            >
                              Close
                            </button>
                          </Modal>
                        </div>
                      </th>

                      <th>{dacash.penddingamount}</th>
                    </tr>
                  );

                  
                }))}
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <br />
      <br />
          {closestockByCurrentdate.length===0 ? (
          <div
            className="endtransaction"
            style={{ position: "relative", marginLeft: "80%" }}
          >
            <button className="btn btn-primary" onClick={(e) => onclickhandler(e)}>
              Perform Day End
            </button>
          </div>
          ):(
        <h5 style={{ position: "relative", marginLeft: "50%"}}> 
       Day  End transaction is completed for Today</h5>
          )}
      <br />
      <br />{" "}
      <div className="col-12  mx-xl-auto ps-xl-10">
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="d-flex">
              <div className="card-title">Close Stock Details</div>
            </div>
            <br />
            <br />

            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th id="date">Product</th>

                  <th id="full">Full</th>

                  <th id="empty">Empty</th>
                  <th id="empty">Defective</th>
                </tr>{" "}
               </thead>
               <tbody>
               {closestockone===null? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchstate &&
                  closestockone.map((products) => {
                    return (
                      <tr>
                        <th>{products.product.productname}</th>
                        {products.pdo.map((ps, index) => {
                          return <th key={index}>{ps.qty}</th>;
                        })}
                      </tr>
                    );
                  }))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
   )}

{(!searchstate && !tsearchstate && openingstock.length===0) && (
                        <div style={{ position: "relative", top: "40px" , textAlign:"center"}}
                        className="col-11  mx-xl-auto ps-xl-10">
                        <div className="card card-raised">
                            <div className="card-body ">
             
                              <h5 style={{color:"red"}}>Please Complete Day End Transaction for Previous Date</h5>
                              <br/>
                             <Link to="/users/Updateopenstock">
                                 <button className="btn btn-primary">Create Exception</button>
                             </Link>
                       
                         </div>
                       </div>
                       </div>
)}
   {(!searchstate && !tsearchstate && openingstock.length > 0) && (
        <div>
        <br></br>
         <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised">
      <div className="card-body ">
        <div className="d-flex">
          <div className="card-title">OpenStock Details</div>
          {" "}
              {loader1 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )}   
          <div style={{ position: "relative", marginLeft: "60%" }}>
          <Link to="/users/Updateopenstock">
            <button className="btn btn-primary">Update Stock</button>
          </Link>
          </div>
        </div>
        <br />
        
        <table className="table table-bordered ">
          <thead>
            <tr
              style={{
                fontSize: "17px",
                backgroundColor: " #6200ea",
                color: "white",
                textAlign: "center",
              }}
            >
              <th id="date">Product</th>

              <th id="full">Full</th>

              <th id="empty">Empty</th>
              <th id="defective">Defective</th>
            </tr>{" "}
          </thead>
          <tbody>
          {searchbydate.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
              searchbydate.map((products) => {
                return (
                  <tr>
                    <th>{products.product.productname}</th>
                    {products.pdo.map((ps, index) => {
                      return <th key={index}>{ps.qty}</th>;
                    })}
                  </tr>
                );
              }))}
          </tbody>
        </table>
      </div>
    </div>
   </div>

  <br /> <br /> <br /> 
  <div>
    <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
                // backgroundColor: "blue",
              }}
            >
              Total LoadIn Details{" "}
              {loader1 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )}
            </div>
          </div>
          <br />
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                {product.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {searchloadin.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                searchloadin.map((ttin) => {
                  return (
                    <tr>
                      <th>{ttin.vehicle}</th>
                      {ttin.pdo.map((ps, index) => {
                        return <th key={index}>{ps.qty}</th>;
                      })}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
              }}
            >
              Total LoadOut Details
              {loader2 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )}
            </div>
          </div>
          <br />
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                {product.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {searchloadout.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                searchloadout.map((ttin) => {
                  return (
                    <tr>
                      <th>{ttin.vehicle}</th>
                      {ttin.pdo.map((ps, index) => {
                        return <th key={index}>{ps.qty}</th>;
                      })}{" "}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div>
  <div className="col-12  mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
                // backgroundColor: "blue",
              }}
            >
              Total Transactionout
              {loader4 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )}
            </div>
            &nbsp;&nbsp;&nbsp;
            &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              {" "}
              <select
                id="vehicleid"
                type="text"
                placeholder="vehicleid"
                onChange={(e) => rhandle(e, "vehicleid")}
              >
                <option>ALL</option>
                {vehicle.map((vehicle) => {
                  return (
                    <option value={vehicle.truckinfo}>
                      {vehicle.truckinfo}
                    </option>
                  );
                })}
              </select>{" "}
              <button
                type="submit"
                value="search"
                className="btn btn-primary"
                id="btn"
                onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
              >
                <IoSearchOutline id="IoSearchOutline" />
              </button>
            </div>
          </div>
          <br />
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                {product.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
              </tr>
            </thead>
            <tbody>
            {!searchbyv &&
                searchbytoutvehicle.map((ttout) => {
                  return (
                    <tr>
                      <th>{ttout.vehicle.truckinfo}</th>
                      {ttout.pdo.map((ps1, index) => {
                        return <th key={index}>{ps1.qty}</th>;
                      })}
                    </tr>
                  );
                })}
               {searchtout.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchbyv && searchbytoutvehicle && searchtout.map((ttin) => {
              return (
                    <tr>
                      <th>{ttin.vehicle.truckinfo}</th>
                      {ttin.pdo.map((ps, index) => {
                        return <th key={index}>{ps.qty}</th>;
                      })}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div className="col-12 mx-xl-auto ps-xl-10">
      <div className="card card-raised ">
        <div className="card-body ">
          <div className="d-flex">
            <div
              className="card-title"
              style={{
                fontSize: "20px",
                // backgroundColor: "blue",
              }}
            >
              Total TransactionIn
              {loader3 && (
                <div
                  className="loader"
                  style={{
                    position: "relative",
                    left: "20px",
                    top: "10px",
                  }}
                ></div>
              )}
            </div>
            &nbsp;&nbsp;&nbsp;
            &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/* <div>
              {" "}
              <select
                id="vehicleid"
                type="text"
                placeholder="vehicleid"
                onChange={(e) => rhandle(e, "vehicleid")}
              >
                <option>select vehicle</option>
                {vehicle.map((vehicle) => {
                  return (
                    <option value={vehicle.truckinfo}>
                      {vehicle.truckinfo}
                    </option>
                  );
                })}
              </select>{" "}
              <button
                type="submit"
                value="search"
                className="btn btn-primary"
                id="btn"
                onClick={(e) => searchbyvehi(e, vehicledetails.vehicleid)}
              >
                <IoSearchOutline id="IoSearchOutline" />
              </button>
          </div> */}
         </div>

          <br />
          <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr
                style={{
                  fontSize: "17px",
                  backgroundColor: " #6200ea",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <th>Vehicle Id</th>
                <th>Product Name</th>

                <th>Total Sale </th>
                <th>Exempted</th>
                <th>New Organization</th>
                <th>Additionals</th>
                <th>Onlinepay</th>
                <th>Returned Product</th>
                <th>Transfer</th>
                <th>Total Quantity</th>
                <th>Total Amount</th>
                
              </tr>
            </thead>
            <tbody>
            {!searchbyv &&
                searchbyvehicle.map((ttin) => {
                  return (
                    <tr>
                      {ttin.vehicleid == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.vehicleid}</td>
                      )}

                      {ttin.productname == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.productname}</td>
                      )}
                      {ttin.totalsale == null ? (
                        <td>{ttin.totalofallsale}</td>
                      ) : (
                        <td>{ttin.totalsale}</td>
                      )}
                       {ttin.totalexempted == null ? (
                        <td>{ttin.totalofallexempted}</td>
                      ) : (
                        <td>{ttin.totalexempted}</td>
                      )}
                      {ttin.totalneworg == null ? (
                        <td>{ttin.totalofallneworg}</td>
                      ) : (
                        <td>{ttin.totalneworg}</td>
                      )}
                       {ttin.totaladditional == null ? (
                        <td>{ttin.totalofalladditional}</td>
                      ) : (
                        <td>{ttin.totaladditional}</td>
                      )}
                        {ttin.totalonlinepay == null ? (
                        <td>{ttin.totalofallonlinepay}</td>
                      ) : (
                        <td>{ttin.totalonlinepay}</td>
                      )}
                       {ttin.totalreturnedproduct == null ? (
                        <td>{ttin.totalofallreturnedproduct}</td>
                      ) : (
                        <td>{ttin.totalreturnedproduct}</td>
                      )}
                       {ttin.totaltransfer == null ? (
                        <td>{ttin.totalofalltransfer}</td>
                      ) : (
                        <td>{ttin.totaltransfer}</td>
                      )}
                      


                      {ttin.totalquantity == null ? (
                        <td>{ttin.totalofallquantity}</td>
                      ) : (
                        <td>{ttin.totalquantity}</td>
                      )}
          
                      {/* {ttin.totaloutgoingcylinder == null ? (
                        <td>{ttin.totalofalloutgoingcylinder}</td>
                      ) : (
                        <td>{ttin.totaloutgoingcylinder}</td>
                      )} */}
                      
                      {ttin.totalamount == null ? (
                        <td>{ttin.totalofallamount}</td>
                      ) : (
                        <td>{ttin.totalamount}</td>
                      )}
                    </tr>
                  );
                })}
              {searchtin.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
            searchbyv && searchbyvehicle && searchtin.map((ttin) => {
              return (
                    <tr>
                      {ttin.vehicleid == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.vehicleid}</td>
                      )}

                      {ttin.productname == null ? (
                        <td>Total</td>
                      ) : (
                        <td>{ttin.productname}</td>
                      )}

                       {ttin.totalsale == null ? (
                        <td>{ttin.totalofallsale}</td>
                      ) : (
                        <td>{ttin.totalsale}</td>
                      )}
                       {ttin.totalexempted == null ? (
                        <td>{ttin.totalofallexempted}</td>
                      ) : (
                        <td>{ttin.totalexempted}</td>
                      )}
                       {ttin.totalneworg == null ? (
                        <td>{ttin.totalofallneworg}</td>
                      ) : (
                        <td>{ttin.totalneworg}</td>
                      )}
                       {ttin.totaladditional == null ? (
                        <td>{ttin.totalofalladditional}</td>
                      ) : (
                        <td>{ttin.totaladditional}</td>
                      )}
                      {ttin.totalonlinepay == null ? (
                        <td>{ttin.totalofallonlinepay}</td>
                      ) : (
                        <td>{ttin.totalonlinepay}</td>
                      )}
                       {ttin.totalreturnedproduct == null ? (
                        <td>{ttin.totalofallreturnedproduct}</td>
                      ) : (
                        <td>{ttin.totalreturnedproduct}</td>
                      )}
                       {ttin.totaltransfer == null ? (
                        <td>{ttin.totalofalltransfer}</td>
                      ) : (
                        <td>{ttin.totaltransfer}</td>
                      )}


                      {ttin.totalquantity == null ? (
                        <td>{ttin.totalofallquantity}</td>
                      ) : (
                        <td>{ttin.totalquantity}</td>
                      )}
                     
                      {ttin.totalamount == null ? (
                        <td>{ttin.totalofallamount}</td>
                      ) : (
                        <td>{ttin.totalamount}</td>
                      )}
                    </tr>
                  );
                }))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    

  </div>
  <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised ">
      <div className="card-body ">
        <div className="d-flex">
          <div
            className="card-title"
            style={{
              fontSize: "20px",
            }}
          >
            Delivery Agent Payment Details
            {loader5 && (
              <div
                className="loader"
                style={{
                  position: "relative",
                  left: "20px",
                  top: "10px",
                }}
              ></div>
            )}
          </div>
          &nbsp;&nbsp;&nbsp;
          &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <table className="table table-bordered ">
          <thead>
            <tr
              style={{
                fontSize: "17px",
                backgroundColor: " #6200ea",
                color: "white",
                textAlign: "center",
              }}
            >
              <th>Vehicle Id</th>
              <th>Total Amount</th>
              <th>Paid Amount</th>
              <th>Pending Amount</th>
            </tr>
          </thead>
          <tbody>
          
          {searchDaDetails.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
searchDaDetails.map((searchDa, index) => {
return (
  <tr key={index}>
    <th>{searchDa.vehicleid}</th>
    <th>{searchDa.totalamount}</th>
    <th>
                    <button
                      onClick={(e) => denominationdetails(searchDa.date,searchDa.vehicleid)}
                      style={{ border: "none", backgroundColor: "white" }}
                    >
                      {searchDa.paidamount}
                    </button>{" "}
                    <div>
                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Facevalue</th>

                              <th>Denomination Number</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {amountdenominationdetails.map((dadenom) => {
                              return (
                                <tr>
                                  <th>{dadenom.facevalue}</th>
                                  <th>{dadenom.denominationnumber}</th>
                                  <th>
                                    {dadenom.facevalue *
                                      dadenom.denominationnumber}
                                  </th>
                                </tr>
                              );
                            })}
                          </tbody>{" "}
                        </table>
                        <button
                          className="btn btn-primary"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </Modal>
                    </div>
                  </th>

                  <th>{searchDa.penddingamount}</th>
  </tr>
);
}))
}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  
  <br />
  <br />
        {closestockBydate.length===0 ?(
        <div
          className="endtransaction"
          style={{ position: "relative", marginLeft: "80%" }}
        >
          <button className="btn btn-primary" onClick={(e) => onclickhandler(e)}>
            End Transaction
          </button>
        </div>
        ):(
      <h5 style={{ position: "relative", marginLeft: "50%"}}> 
      End transaction is completed for Today</h5>
        )}
  <br />
  <br />{" "}
  <div className="col-12  mx-xl-auto ps-xl-10">
    <div className="card card-raised ">
      <div className="card-body ">
        <div className="d-flex">
          <div className="card-title">Close Stock Details</div>
        </div>
        <br />
        <br />

        <table className="table table-bordered ">
          <thead>
            <tr
              style={{
                fontSize: "17px",
                backgroundColor: " #6200ea",
                color: "white",
                textAlign: "center",
              }}
            >
              <th id="date">Product</th>

              <th id="full">Full</th>

              <th id="empty">Empty</th>
              <th id="empty">Defective</th>
            </tr>{" "}
           </thead>
           <tbody>
              
           {Searchclosestock.length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
              Searchclosestock.map((products) => {
                return (
                  <tr>
                    <th>{products.product.productname}</th>
                    {products.pdo.map((ps, index) => {
                      return <th key={index}>{ps.qty}</th>;
                    })}
                  </tr>
                );
              }))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>

   )} 
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default Stock;