import React from "react";
import { Link } from "react-router-dom";
import Signin from "./components/Login";
import Home from "./components/home.js";
import {  HashRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./components/register";



import Dashboard from "./components/dashboard";
import Profile from "./components/pro.js";
import Security from "./components/security.js";
import Forgotpassword from "./components/forgotpassword";
import Notification from "./components/notification";
import PrivateRoute from "./util/privateRoute";
import Invoice from "./components/invoice";
import ResetPassword from "./components/resetpassword";
import Otpvarification from "./components/otpvarification";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Approval from "./components/approval";
import Otpvalidation from "./components/otpvalidation";
import AdminDashboard from "./components/admindashboard";
import AdminApproval from "./components/adminapprovals";
import ManagerDashboard from "./components/managerdashboard";
import DeliveryagentDashboard from "./components/deliveragentdashboard";
import DashboardSidebar from "./components/dash";
import TransactionOutgoing from "./components/TransactionOutgoingDetails";
import TransactionIncoming from "./components/transactionincomingdetails";
import UpdateTransactionOutgoing from "./components/updateTransactionOutgoing";
import UpdateTransactionIncoming from "./components/updateTransactionIncoming";
import ManagerProfile from "./components/managerprofile";
import LoadIncoming from "./components/loadin";
import LoadOutgoing from "./components/loadoout";
import ManagerIncomingTransaction from "./components/managerincomingtransaction";
import ManagerTransactionOutgoing from "./components/manageroutgoingtransaction";
import AdminTransactionincoming from "./components/adminincomingtransaction";
import AdminTransactionoutgoing from "./components/adminoutgoingtransaction";
import SuperTransactionIncoming from "./components/superincomingtransaction";
import SuperTransactionOutgoing from "./components/superoutgoingtransaction";
import SuperProfile from "./components/superprofile";
import AdminProfile from "./components/adminprofile";
import AdminLoadin from "./components/adminloadin";
import AdminLoadout from "./components/adminloadout";
import Superloadin from "./components/superloadin";
import Superloadout from "./components/superloadout";
import ManagerLoadin from "./components/managerLoadin";
import ManagerLoadout from "./components/managerloadout";
import ManagerApproval from "./components/managerapprovals";
import UpdateProducts from "./components/updateproducts";
import Productdetails from "./components/productdetails";
import Addmember from "./components/addmember.js";

import UpdateLoadout from "./components/updateloadout";
import UpdateLoadin from "./components/updateloadin";
import Productquantity from "./components/productquantity";
import Openstock from "./components/openstock";
import Closestock from "./components/closestock";
import Updateclosestock from "./components/updateclosestock";
import Updateopenstock from "./components/updateopenstock";
import UpdateProductquantity from "./components/updateproductquantity";
import RegisterMd from "./components/registermandd";
import Changeofpassword from "./components/changeofpassword";
import Stock from "./components/stock";
import Transactions from "./components/transactions";
import AdminTransanctionApproval from "./components/admintransactionapproval";
import Vehicledetails from "./components/vehicleDetails";
import AddVehicles from "./components/Addvehicle";
import Dagentandvehiclemapping from "./components/Dagentandvehiclemapping";
import Vehiclereport from "./components/Vehiclereport";
import Fuel from "./components/fuel";
import Service from "./components/service";
import Insurance from "./components/insurance";
import Fuelreport from "./components/fuellogreport";
import Servicereport from "./components/servicereport";
import Dagentvehiclereport from "./components/Dagentvehiclereport";
import Fitnescertificate from "./components/Fc";
import Permit from "./components/Permit";
import Emissioncertificate from "./components/Emissioncertificate";
import AccountDetails from "./components/accountsdetails";
import Organizationdetails from "./components/Organizationdetails";
import Financialmanagment from "./components/financialManagment";
import ProductcostDetails from "./components/financialdetails";
import Subregion from "./components/Subregion";
import SubModule from "./components/Subscriptionmodule";
import Tax from "./components/Tax";
import Subscription from "./components/subscription";
import AnotherSubscription from "./components/anothersubscription";
import Totalamount from "./components/totalamount";
import Subscriptionbilling from "./components/subscriptionbilling";
import UProducts from "./components/productedit";
import Denomination from "./components/denomination";
import Dacashamount from "./components/deliveryagentcashamountenter";
import Dacashpayment from "./components/dacashpayment";
import Vehicleedit from "./components/Vehicleedit";
import Loaddetails from "./components/loaddetails";
import Productanalytics from "./components/productanalytics";
import Exemptedcustomercategory from "./components/exemptedcustomercategory";
import Exemptedcustomer from "./components/exemtedcustomer";

import Deliveryagentdetails from "./components/deliveryagentdetails";
import Customerpayment from "./components/customerpayment";
import AddCustomerPaymentdetails from "./components/AddCustomerpayment";
import Attendencedetails from "./components/Attendence";
import Customerdetails from "./components/customerdetails";
import AddCustomerdetails from "./components/AddCustomerdetails";

import Exemptedreport from "./components/exemptedreport";
import Termsandcondition from "./components/termsandconditions";
import Privacypolicy from "./components/privacypolicy";
import Exempteddelivery from "./components/exempteddelivery";
import Exemptedediting from "./components/exemptedediting";
import Transactionreport from "./components/transactionreport";
import AdminDashboardwithanalytics from "./components/admindashboardwithanalytics";
import Exemptedcustomercategoryedit from "./components/Exemptededit"
import AddExempteddelivery from "./components/AddExempteddelivery";
import Settings from "./components/Settings.js";
import AddSettings from "./components/AddSettings.js";
import Settingsedit from "./components/Settingsedit";
// import Location from "./components/location";
// import { Map } from "leaflet";
import SQCdetails from "./components/Sqcnumber.js";
import Addsqcnumber from "./components/addsqcnumber.js";
import PrintSQC from "./components/Printsqc.js";
import OpenGoogleMap from "./components/OpenGoogleMap";
import Monthlytargetdetails from "./components/monthlytargetdetails.js";
import SuperDashboard from "./components/Superdashboard.js";


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/register/:id" element={<Register />} />
          <Route path="/otpvalidation/:id" element={<Otpvalidation />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/otpverification" element={<Otpvarification />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/invoice/:id/:pid" element={<Invoice />} />
          <Route
            path="/anothersubscription"
            element={<AnotherSubscription />}
          />
          <Route path="termsandcondition" element={<Termsandcondition />} />
          <Route path="privacypolicy" element={<Privacypolicy />} />
         

          <Route path="/razorpay/:id" element={<Totalamount />} />
          <Route path="/users" element={<PrivateRoute />}>
          <Route path="dashboard" element={<Dashboard />} />
            <Route path="denomination" element={<Denomination />} />
            <Route path="dacash/:vid" element={<Dacashamount />} />
            <Route path="dapayment" element={<Dacashpayment />} />
            <Route path="profile" element={<Profile />} />
            <Route path="security" element={<Security />} />
            <Route path="notification" element={<Notification />} />

            <Route path="approval" element={<Approval />} />
            <Route path="admindashboard" element={<AdminDashboard />} />
            <Route path="adminapproval" element={<AdminApproval />} />
            <Route
              path="subscriptionbilling"
              element={<Subscriptionbilling />}
            />

            <Route path="managerdashboard" element={<ManagerDashboard />} />
            <Route
              path="deliveryagentdashboard"
              element={<DeliveryagentDashboard />}
            />
            <Route path="dashboardsidebar" element={<DashboardSidebar />} />
            <Route
              path="transactionoutgoing"
              element={<TransactionOutgoing />}
            />
            <Route
              path="transactionincoming"
              element={<TransactionIncoming />}
            />
            {/* <Route
              path="transactionincoming/:id"
              element={<TransactionIncoming />}
            /> */}
            <Route
              path="updatetransactionoutcoming"
              element={<UpdateTransactionOutgoing />}
            />
            <Route
              path="updatetransactionincoming"
              element={<UpdateTransactionIncoming />}
            />
            <Route path="managerprofile" element={<ManagerProfile />} />
            <Route path="loadincoming" element={<LoadIncoming />} />
            <Route path="loadoutgoing" element={<LoadOutgoing />} />
            <Route path="loaddetails" element={<Loaddetails />} />
            <Route
              path="managerincomintransaction"
              element={<ManagerIncomingTransaction />}
            />
            <Route
              path="manageroutgoingtransaction"
              element={<ManagerTransactionOutgoing />}
            />
            <Route
              path="adminincomingtransaction"
              element={<AdminTransactionincoming />}
            />
            <Route
              path="adminoutgoingtransaction"
              element={<AdminTransactionoutgoing />}
            />
            <Route
              path="superincomingtransaction"
              element={<SuperTransactionIncoming />}
            />
            <Route
              path="superinoutgoingtransaction"
              element={<SuperTransactionOutgoing />}
            />
            <Route path="superprofile" element={<SuperProfile />} />
            <Route path="notification" element={<Notification />} />

            <Route path="adminprofile" element={<AdminProfile />} />
            <Route path="adminloadin" element={<AdminLoadin />} />
            <Route path="adminloadout" element={<AdminLoadout />} />
            <Route path="superloadin" element={<Superloadin />} />
            <Route path="superloadout" element={<Superloadout />} />
            <Route path="managerloadin" element={<ManagerLoadin />} />
            <Route path="managerloadout" element={<ManagerLoadout />} />
            <Route path="managerapproval" element={<ManagerApproval />} />
           
            <Route path="uproduct" element={<UpdateProducts />} />
            <Route path="productdetails" element={<Productdetails />} />
            <Route path="productedits/:id" element={<UProducts />} />
            <Route path="updatetin" element={<UpdateTransactionIncoming />} />
            <Route path="updatetout" element={<UpdateTransactionOutgoing />} />
            <Route path="updateloadout" element={<UpdateLoadout />} />
            <Route path="updateloadin" element={<UpdateLoadin />} />
            <Route path="productquantity" element={<Productquantity />} />
            <Route path="productanalytics" element={<Productanalytics />} />

            <Route path="openstock" element={<Openstock />} />
            <Route path="closestock" element={<Closestock />} />
            <Route path="updateclosestock" element={<Updateclosestock />} />
            <Route path="updateopenstock" element={<Updateopenstock />} />
            <Route
              path="updateproductquantity"
              element={<UpdateProductquantity />}
            />
            <Route path="registermd" element={<RegisterMd />} />
            <Route path="changeofpassword" element={<Changeofpassword />} />
            <Route path="stock" element={<Stock />} />
            <Route path="transaction" element={<Transactions />} />
            <Route
              path="admintransactionapproval"
              element={<AdminTransanctionApproval />}
            />

            <Route
              path="exemptedcategory"
              element={<Exemptedcustomercategory />}
            />
            <Route path="exemptedreport" element={<Exemptedreport />} />
            <Route path="exemptedcustomer" element={<Exemptedcustomer />} />
            <Route
              path="Exempteddelivery"
              element={<Exempteddelivery />}
            />
            <Route path="exemptededit/:id" element={<Exemptedediting />} />
            <Route path="transactionreport" element={<Transactionreport />} />

            <Route path="vehicledetail" element={<Vehicledetails />} />
            <Route path="vehicleedit/:id" element={<Vehicleedit />} />

            <Route path="addvehicle" element={<AddVehicles />} />
            <Route
              path="davehiclemapping/:vid/:truckinfo"
              element={<Dagentandvehiclemapping />}
            />
            <Route
              path="vehicledocuments/:truckinfo"
              element={<Vehiclereport />}
            />
            <Route
              path="admindashboardwithanalytics"
              element={<AdminDashboardwithanalytics />}
            />
            <Route path="vehicledocuments" element={<Vehiclereport />} />
            <Route path="fuel/:truckinfo" element={<Fuel />} />
            <Route path="service/:truckinfo" element={<Service />} />
            <Route path="insurance/:truckinfo" element={<Insurance />} />
            <Route path="fuelreport" element={<Fuelreport />} />
            <Route path="servicereport" element={<Servicereport />} />
            <Route path="fc/:truckinfo" element={<Fitnescertificate />} />
            <Route path="permit/:truckinfo" element={<Permit />} />
            <Route
              path="emission/:truckinfo"
              element={<Emissioncertificate />}
            />
            <Route
              path="dagentvehiclereport"
              element={<Dagentvehiclereport />}
            />
            <Route path="accountdetails" element={<AccountDetails />} />
            <Route
              path="organizationdetails"
              element={<Organizationdetails />}
            />
            <Route path="financialmanagment" element={<Financialmanagment />} />
            <Route path="financialdetails" element={<ProductcostDetails />} />
            <Route path="subregion" element={<Subregion />} />

            <Route path="submodule" element={<SubModule />} />
            <Route path="tax" element={<Tax />} />

            <Route path="attendance" element={<Attendencedetails />} />
            <Route path="Deliveryagentdetails" element={<Deliveryagentdetails />} />
            <Route path="Addmembers" element={<Addmember />} />
            {/* <Route path="AddDeliveryagentdetails" element={<AddDeliveryagentdetails />} /> */}
            <Route path="Customerpayment" element={<Customerpayment />} />
            <Route path="AddCustomerPaymentdetails" element={<AddCustomerPaymentdetails />} />
            {/* <Route path="Admintransaction" element={<Admintransaction />} /> */}
            {/* <Route path="AddAdmintransactiondetails" element={<AddAdmintransactiondetails />} /> */}
            
            <Route path="Customerdetails" element={<Customerdetails />} />
            <Route path="AddCustomerdetails" element={<AddCustomerdetails />} />
            <Route path="Exemptedcustomercategoryedit/:id" element={<Exemptedcustomercategoryedit />} />
            <Route path="AddExempteddelivery" element={<AddExempteddelivery />} />
            {/* <Route path="location/:vehicletruckinfo" element={<Location />} /> */}
            <Route path="OpenGoogleMap/:truckinfo/:deviceid" element={<OpenGoogleMap />} />
            <Route path="Settings" element={<Settings />} />
            <Route path="AddSettings" element={<AddSettings />} />  
            
            <Route path="Settingsedit/:id" element={<Settingsedit />} />

            <Route path="Settingsedit/:id" element={<Settingsedit />} />

            <Route path="Sqcnumber" element={<SQCdetails />} />
            <Route path="addsqcnumber" element={<Addsqcnumber />} />
            <Route path="Printsqc/:id" element={<PrintSQC />} />
            
            
            <Route path="monthlytargetdetails" element={<Monthlytargetdetails />} />

            <Route path="Superdashboard" element={<SuperDashboard />} />


          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
