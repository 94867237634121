import React, { useEffect, useState, useReducer } from "react";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import Sidebar from "./adminsidebar";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import Search from "./search";
import "./approval.css";
import "./Spinner.css";
import "./Loader.css";
function AdminTransanctionApproval() {
  const [approvals, setApprovals] = useState([]);
  const [rapprovals, setRapprovals] = useState([]);
  const [status, setStatus] = useState({
    id: "",
    approvalstatus: "",
    orgcode: "",
    quantity: "",
  });
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchbydate, setSearchbydate] = useState(false);

const [outgoingCurrentPage, setOutgoingCurrentPage] = useState(1);
const [incomingCurrentPage, setIncomingCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items to show per page

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentApprovals = approvals.slice(indexOfFirstItem, indexOfLastItem);
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const handleFilterChange = () => {
    setOutgoingCurrentPage(1);
    setIncomingCurrentPage(1);
    // Reset currentPage when filter changes
  };
  const handleOutgoingPrevPage = () => {
    if (outgoingCurrentPage > 1) {
      setOutgoingCurrentPage(outgoingCurrentPage - 1);
    }
  };
  
  const handleOutgoingNextPage = () => {
    if (outgoingCurrentPage < pageNumbers.length) {
      setOutgoingCurrentPage(outgoingCurrentPage + 1);
    }
  };
  
  const handleIncomingPrevPage = () => {
    if (incomingCurrentPage > 1) {
      setIncomingCurrentPage(incomingCurrentPage - 1);
    }
  };
  
  const handleIncomingNextPage = () => {
    if (incomingCurrentPage < pageNumber.length) {
      setIncomingCurrentPage(incomingCurrentPage + 1);
    }
  };
  
   // Function to get current items based on pagination
   const getCurrentItems = () => {
    
     const indexOfLastItem = outgoingCurrentPage * itemsPerPage;
     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
     const currentApprovals = approvals.slice(indexOfFirstItem, indexOfLastItem);
    return currentApprovals;
    //  return filteredData.slice(indexOfFirstItem, indexOfLastItem);
      
  
   };

   const getCurrentItem = () => {
    
    const indexOfLastItems = incomingCurrentPage * itemsPerPage;
    const indexOfFirstItems = indexOfLastItems - itemsPerPage;
    const currentApproval = rapprovals .slice(indexOfFirstItems, indexOfLastItems);
    console.log(currentApproval);
    console.log(indexOfFirstItems);
    console.log(currentApproval);

   return currentApproval;
   //  return filteredData.slice(indexOfFirstItem, indexOfLastItem);
     
 
  };
  // Calculate page numbers based on total items and items per page
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(approvals.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
   // Calculate page numbers based on total items and items per page
   const pageNumber = [];
   for (let i = 1; i <= Math.ceil(rapprovals.length / itemsPerPage); i++) {
     pageNumber.push(i);
   }

  const url = "/api/transactionoutgoing/orgcode";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    const prof1 = await axios.get(
      `/api/transactionoutgoing/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(prof1);

    setApprovals(prof1.data);
    const prof2 = await axios.get(
      `/api/Returntransaction/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${currentDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(prof2);
    setLoader(false);
    setRapprovals(prof2.data);
  };
  const searchName = async (text) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    const prof1 = await axios.get(
      `/api/transactionoutgoing/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${text}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setApprovals(prof1.data);
    const prof2 = await axios.get(
      `/api/Returntransaction/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }/date=${text}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setRapprovals(prof2.data);
    setLoader(false);
  };


  useEffect(() => {
    getData();
  }, [reducerValue]);

  function rsubmit(e, id, status, orgcode, quantity) {
    e.preventDefault();
    setLoading(id);
    // if(!sub.status)

    //{

    //setSub(true)

    // }
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/admin/approvals/transactionoutgoing",
        {
          id: id,
          approvalstatus: status,
          orgcode: orgcode,
          quantity: quantity,
        },

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(null);
        forceUpdate();
      })

      .catch((error) => {
        console.log(error);
      });
  }
  function trsubmit(e, id, status, orgcode, quantity) {
    e.preventDefault();
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        "/api/admin/approvals/Returntransaction",
        {
          id: id,
          approvalstatus: status,
          orgcode: orgcode,
          quantity: quantity,
        },

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        forceUpdate();
      })

      .catch((error) => {
        console.log(error);
      });
  }
  function rhandle(e) {
    const newdata = { ...status };
    newdata[e.target.id] = e.target.value;
    setStatus(newdata);
  }
  // const handleFilterChange = () => {
  //   setCurrentPage(1); // Reset currentPage when filter changes
  // };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div>
        <Sidebar />
      </div>{" "}
      <br />
      <br />
      <br />
      <br />
      <br />{" "}
      {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "750px", top: "75px" }}
        ></div>
      )}
      <div className="d-flex align-items-right ">
        <div className="col-10  align-items-center mx-xl-auto  ps-xl-10">
        <div style={{ position: "relative", left: "260px" }}>
        <Search searchName={searchName} />{" "}
      </div>
      <br/>
          <div className="card card-raised mb-5">
            <div className="card-body p-5">
              <div className="card-title">
                Transaction Outgoing Approval Details
              </div>
              <br />

              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>VehicleId</th>
                    <th>Aprovalstatus</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentItems().map((approval, index) => {
                    return (
                      <tr key={approval.id}>
                        <td>{approval.productname}</td>

                        <td>{approval.quantity}</td>

                        <td>{approval.vehicle}</td>

                        {approval.approvalstatus == "Requested" ? (
                          <td id="icon" value={approval.approvalstatus}>
                            &nbsp;
                            {approval.approvalstatus} &nbsp;
                            {loading == approval.id && (
                              <div
                                className="loader"
                                style={{
                                  position: "relative",
                                  left: "75px",
                                  top: "10px",
                                }}
                              ></div>
                            )}{" "}
                            <BsCheckCircle
                              className="icon"
                              id="approval.approvalstatus"
                              style={{ backgroundColor: "green" }}
                              value="APPROVED"
                              onClick={(e) =>
                                rsubmit(
                                  e,
                                  approval.id,
                                  "APPROVED",
                                  approval.orgcode,
                                  approval.quantity
                                )
                              }
                            />
                            &ensp;
                            <BsXCircle
                              className="icon"
                              value="REJECTED"
                              style={{ backgroundColor: "red" }}
                              id="approval.approvalstatus"
                              onClick={(e) =>
                                rsubmit(
                                  e,
                                  approval.id,
                                  "REJECTED",
                                  approval.orgcode,
                                  approval.quantity
                                )
                              }
                            />
                          </td>
                        ) : (
                          <td id="icon" value={approval.approvalstatus}>
                            {" "}
                            {approval.approvalstatus}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
               {/* Pagination controls */}
          <nav>
            <ul className="pagination justify-content-center">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${number === outgoingCurrentPage ? "active" : ""}`}
                >
                 
                </li>
              ))}
            </ul>
          
  <ul className="pagination justify-content-center">
    <li className={`page-item ${outgoingCurrentPage === 1 ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleOutgoingPrevPage}>
        Previous
      </button>
    </li>
    {pageNumbers.map((number) => (
      <li
        key={number}
        className={`page-item ${number === outgoingCurrentPage ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => setOutgoingCurrentPage(number)}>
          {number}
        </button>
      </li>
    ))}
    <li className={`page-item ${outgoingCurrentPage === pageNumbers.length ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleOutgoingNextPage }>
        Next
      </button>
    </li>
  </ul>
</nav>
              {/* <div className="pagination-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>

              <div className="pagination">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {[...Array(Math.ceil(approvals.length / itemsPerPage))].map((_, index) => (
            <span
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              style={{ cursor: 'pointer', margin: '0 5px', fontWeight: index + 1 === currentPage ? 'bold' : 'normal' }}
            >
              {index + 1}
            </span>
          ))}
        <span>{currentPage}</span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentApprovals.length < itemsPerPage}
        >
          Next
        </button>
      </div>
            </div> */}
          </div>
        </div>
      </div>
      </div>
      <div className="d-flex align-items-right ">
        <div className="col-10  align-items-center mx-xl-auto  ps-xl-10">
          <div className="card card-raised mb-5">
            <div className="card-body p-5">
              <div className="card-title">
                Transaction Incoming Approval Details
              </div>
              <br />

              <table className="table table-bordered ">
                <thead>
                  <tr 
                    style={{
                      fontSize: "17px",
                      backgroundColor: " #6200ea",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>VehicleId</th>
                    <th>Aprovalstatus</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentItem().map((rapprovals, index) => {
                    return (
                      <tr key={rapprovals.id}>
                        <td>{rapprovals.productname}</td>

                        <td>{rapprovals.quantity}</td>

                        <td>{rapprovals.vehicle}</td>

                        {rapprovals.approvalstatus == "Requested" ? (
                          <td id="icon" value={rapprovals.approvalstatus}>
                            &nbsp;
                            {rapprovals.approvalstatus} &nbsp;
                            {loading == rapprovals.id && (
                              <div
                                className="loader"
                                style={{
                                  position: "relative",
                                  left: "75px",
                                  top: "10px",
                                }}
                              ></div>
                            )}{" "}
                            <BsCheckCircle
                              className="icon"
                              id="rapprovals.approvalstatus"
                              style={{ backgroundColor: "green" }}
                              value="APPROVED"
                              onClick={(e) =>
                                trsubmit(
                                  e,
                                  rapprovals.id,
                                  "APPROVED",
                                  rapprovals.orgcode,
                                  rapprovals.quantity
                                )
                              }
                            />
                            &ensp;
                            <BsXCircle
                              className="icon"
                              value="REJECTED"
                              style={{ backgroundColor: "red" }}
                              id="rapprovals.approvalstatus"
                              onClick={(e) =>
                                trsubmit(
                                  e,
                                  rapprovals.id,
                                  "REJECTED",
                                  rapprovals.orgcode,
                                  rapprovals.quantity
                                )
                              }
                            />
                          </td>
                        ) : (
                          <td id="icon" value={rapprovals.approvalstatus}>
                            {" "}
                            {rapprovals.approvalstatus}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
                {/* Pagination controls */}
          <nav>
            <ul className="pagination justify-content-center">
              {pageNumber.map((number) => (
                <li
                  key={number}
                  className={`page-item ${number === incomingCurrentPage ? "active" : ""}`}
                >
                 
                </li>
              ))}
            </ul>
          
  <ul className="pagination justify-content-center">
    <li className={`page-item ${incomingCurrentPage === 1 ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleIncomingPrevPage }>
        Previous
      </button>
    </li>
    {pageNumber.map((number) => (
      <li
        key={number}
        className={`page-item ${number === incomingCurrentPage ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => setIncomingCurrentPage(number)}>
          {number}
        </button>
      </li>
    ))}
    <li className={`page-item ${incomingCurrentPage === pageNumber.length ? "disabled" : ""}`}>
      <button className="page-link" onClick={handleIncomingNextPage }>
        Next
      </button>
    </li>
  </ul>
</nav>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default AdminTransanctionApproval;
