import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import "./Spinner.css";



function UpdateTransactionOutgoing() {
  const [time, setTime] = useState(new Date());
  var curr_hour = time.getHours();
  var curr_min = time.getMinutes();

  var curr_sec = time.getSeconds();

  const text = curr_hour + ":" + curr_min + ":" + curr_sec;
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const navigate=useNavigate();
  const [selectedVehicle, setSelectedVehicle] = useState(""); 

  const [transaction, setTransaction] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    status: "full",
    vehicle: "",
    approvalstatus: "",
    approvaldate: "",
    date: currentDate,
    time: text,
    
    products: [
      {
        productcode: "",
        productname: "",
        quantity: "",
      },
    ],
  });
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const url = "/api/transactionoutgoing/create";
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
setLoading(true)
    const trans = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);

    setProduct(trans.data);
    setTransaction({
      ...transaction,
      products: trans.data.map((item) => ({
        productcode: item.productcode,
        productname: item.productname,
        quantity: 0,
      })),
    });
    setLoading(false)
    const vehicles = await axios.get(
      `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(vehicles);

    setVehicle(vehicles.data);
  };

  useEffect(() => {
    getData();
  }, []);
//   function rshandle(e, field, index) {
    
    
//     const updatedValue = e.target.value; 

//   if (field === "vehicle") {
//     setSelectedVehicle(updatedValue); 
//   } else if (field === "quantity") {
//     const newProducts = [...transaction.products];
//     newProducts[index] = { ...newProducts[index], quantity: updatedValue };

//     setTransaction({ ...transaction, products: newProducts });
//   } else {
//     setTransaction({ ...transaction, [field]: updatedValue });
//   }
// }

  function rsubmit(e) {
    e.preventDefault();
    if (!selectedVehicle) {
      toast.error("Please select a vehicle");
      return;
    }
    
  
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        transaction,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("transaction Outgoing updated successfully");
        navigate("/users/transaction");
      })

      .catch((error) => {
        console.log(error);
      });
  }
  function rhandle(e, field, index) {
    let actualValue = e.target.value;
    // let tra = { ...transaction, [field]: actualValue };
    // console.log(tra);
    setSelectedVehicle(actualValue); 
    if (field == "quantity") {
      const newproduct = [...transaction.products];
      newproduct[index] = { ...newproduct[index], quantity: actualValue };

      setTransaction({ ...transaction, products: newproduct });
    } else {
      setTransaction({ ...transaction, [field]: actualValue });
    }
  }

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      <div className="col-8  mx-xl-auto px-xl-9">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Transaction Outgoing Updates</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div>
                      <label for="date">Date</label>
                      <input
                        id="date"
                        value={currentDate}
                        onChange={(e) => rhandle(e, "date")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="truckinfo">Vehicle</label>
                      <select
                        id="vehicle"
                        type="text"
                        placeholder="vehicle"
                        value={transaction.vehicle}
                        onChange={(e) => rhandle(e, "vehicle")}
                       
                      >
                        <option>select vehicle</option>
                        {vehicle.map((vehicle) => {
                          return (
                            <option value={vehicle.truckinfo}>
                              {vehicle.truckinfo}
                            </option>
                          );
                        })}
                      </select>
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                    </div>
                    <br />
                    <br />
                    <div>
                      <div>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Product Id</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transaction.products.map((product, index) => {
                              return (
                                <>
                                  <tr key={product.productcode}>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td>
                                      <input
                                        id="id"
                                        type="text"
                                        placeholder="0"
                                        style={{ border: "none" }}
                                        onChange={(e) =>
                                          rhandle(e, "quantity", index)
                                        }
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                    </div>

                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) => rsubmit(e)}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default UpdateTransactionOutgoing;
