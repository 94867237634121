import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import SettingseditModalPopup from "./Settingsedit";
import AddSettings from "./AddSettings";

 
 
function Settings() {
  const [Deliveryagentdetails, setDeliveryagentdetails] = useState([]);
  const [isProductEditModalOpen, setIsProductEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  // const onClickhandler1 = async (e, vid, vusername, vrole) => {
  //   const token = JSON.parse(localStorage.getItem("data")).token;
  //   setLoading(vid);
  //   try {
  //     const vehicles = await axios.delete(`/api/accounts/delete/${vid}`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //       data: {
  //         id: vid,
  //         username: vusername,
  //         role: vrole,
  //         orgid: JSON.parse(localStorage.getItem("data")).orgcode,
  //       },
  //     });
  
  //     toast.success("Deleted successfully");
  //     getData();
  //     setLoading(null);
  //   } catch (error) {
  //     console.error("Error deleting vehicle:", error);
  //     setLoading(null);
  //     toast.error("Error deleting vehicle");
  //   }
  // };

 
  const getData = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    const trans = await axios.get( `/api/Locationtrack`, {
      headers: { Authorization: `Bearer ${token}` },
    });
 
    console.log(trans);
 
    setDeliveryagentdetails(trans.data);
  } catch (error) {
    console.error("Error fetching :", error);
  } 
};
  useEffect(() => {
    getData();
  }, []);
 
 
  const handleProductEdit = (e, id) => {
    setSelectedProductId(id);
    setIsProductEditModalOpen(true);

   
  };
 
  const closeProductEditModal = () => {
    setIsProductEditModalOpen(false);
    getData();
 
  };
  const handleAdd = () => {
    setIsModalOpen(true);


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };
  
 
  return (
    <div  className="attendance-page" style={{marginTop:"100px"}}>
      <div  className="dashboard-container">
        <Dashboard />
      </div>
      <br />
      <br />
      <br />
      <div>
        <div className="col-12  mx-xl-auto ps-xl-30">
          <div className="card card-raised ">
            <div className="card-body ">
              <div className="card-title">List</div>
              <br />
             
                <button className="btn btn-primary float-end" onClick={handleAdd}>
               ADD
            </button>
              <table className="table table-bordered ">
                <thead>
                  <tr
                    style={{
                      fontSize: "17px",
                      backgroundColor: "blue",
                      color: "white",
                    }}
                  >
                    {/* <th>Id </th> */}
 
                    <th>Userid</th>
                    <th>APIKey</th>
                    <th>AppName</th>
                    {/* <th>Username</th>

                    <th>Token</th> */}

                    <th>URL</th>
                    <th>Edit</th>
 
 
 
                  </tr>
                </thead>
                <tbody>
                  {Deliveryagentdetails.map((accd) => {
                    return (
                      <tr key={accd.id}>
                        {/* <td>{accd.id}</td> */}
 
                        <td>{accd.userid}</td>
                        <td>{accd.apikey}</td>
 
                        <td>{accd.appname}</td>
                        {/* <td>{accd.username}</td>
                        <td>{accd.token}</td> */}

                        <td>{accd.url}</td>

 
                        <td>
                        <button
                          onClick={(e) => handleProductEdit(e, accd.id)}
                          style={{
                            border: "none",
                            background: "white",
                            size: "150px",
                          }}
                        >
                          <BiEditAlt />
                        </button>
                      </td>                    
                      
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

          </div>
        </div>

        {isProductEditModalOpen && (
              <SettingseditModalPopup
                isOpen={isProductEditModalOpen}
                onRequestClose={closeProductEditModal}
                Id={selectedProductId}
              />
            )}

<AddSettings isOpen={isModalOpen} onRequestClose={handleCloseModal} />

      </div>
    </div>
  );
}
 
export default Settings;
 