import React, { useState, useEffect, useReducer } from "react";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";

function Tax() {
  const token = JSON.parse(localStorage.getItem("data")).token;

  <div className="card card-raised mb-5"></div>;
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [gatewaytaxes, setGatewaytaxes] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [mapdevice, setMapdevice] = useState({
    id: "",
    gatewaytax: "",
    gsttax: "",
  });
  const [User, setUser] = useState([]);

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setMapdevice({ ...mapdevice, [field]: actualValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post(
        "/api/paymentgatewaytax/update",
        { id: 1, gatewaytax: mapdevice.gatewaytax, gsttax: mapdevice.gsttax },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        //    navigate("/users/Deviceusermapping");
        toast.success("Tax added");
        setLoading(false);
        getData();
      })
      .catch((error) => {
        //   navigate("/users/Deviceusermapping");
        //    toast.error("The Deviceid must be unique");

        setLoading(false);
      });
  };
  const getData = async () => {
    setLoader(true);
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;

    const trans = await axios
      .get("/api/paymentgatewaytax", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setGatewaytaxes(res.data);
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div
            className="col-9 mx-xl-auto ps-xl-9"
            style={{ position: "relative", left: "100px", top: "100px" }}
          >
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="card-title"> Tax Creation</div>
                <br></br>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="gatewaytax ">Gateway Tax </label>
                      <input
                        type="text"
                        className="form-control"
                        id="gatewaytax"
                        value={mapdevice.gatewaytax}
                        onChange={(e) => handleChange(e, "gatewaytax")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="tax ">GST Rate </label>
                      <input
                        type="text"
                        className="form-control"
                        id="gsttax"
                        value={mapdevice.gsttax}
                        onChange={(e) => handleChange(e, "gsttax")}
                      />
                    </div>
                  </div>

                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      value="update"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      <br />
      <br /> <br />
      <br />
      {loader && <div className="loader"></div>}
      <div
        className="col-9 mx-xl-auto ps-xl-9"
        style={{ position: "relative", left: "100px", top: "60px" }}
      >
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="card-title"> Tax Details</div>
            <table
              className="table table-bordered "
              style={{ position: "relative" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Gateway Tax</th>
                  <th>GST Rate</th>
                </tr>
              </thead>
              <tbody>
                {gatewaytaxes.map((gatewaytaxes) => {
                  return (
                    <tr
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <th>{gatewaytaxes.gatewaytax}</th>
                      <th> {gatewaytaxes.gsttax}</th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tax;
