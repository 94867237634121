import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import { Link } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import Search from "./search";
import {   FiInfo } from "react-icons/fi";  
import Modal from "react-modal";
import $ from "jquery";
import Exemptedmodalpopup from "./exemptedmodalpopup";
import AdditionalModalPopup from "./Additionalmodalpopup";
import OnlineModalPopup from "./Onlinemodalpopup";
import TransactionIncoming from "./transactionincomingdetails";
import TransactionOutgoing from "./TransactionOutgoingDetails";



function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [transactions1, setTransactions1] = useState([]);
  const [tin, setTin] = useState([]);
  const [tout, setTout] = useState([]);
  const [loader, setLoader] = useState(false);
  const [product, setProduct] = useState([]);
  const [searchbydate, setSearchbydate] = useState(true);
  const [selectedOutgoingVehicle, setSelectedOutgoingVehicle] = useState("");
  const [selectedIncomingVehicle, setSelectedIncomingVehicle] = useState("");
  const [exemptedDetails, setExemptedDetails] = useState([]);
  const [additionalDetails, setAdditionaldetails] = useState([]);
  const [onlineDetails, setOnlineDetails] = useState([]);


  const [exemptedModalIsOpen, setExemptedModalIsOpen] = useState(false);
  const [onlineModalIsOpen, setOnlineModalIsOpen] = useState(false);

const [additionalModalIsOpen, setAdditionalModalIsOpen] = useState(false);

const [transactionInModalIsOpen, setTransactionInModalIsOpen] = useState(false);
const [transactionOutModalIsOpen, setTransactionOutModalIsOpen] = useState(false);

const [selectedProductName, setSelectedProductName] = useState(null);
const [selectedVehicleId, setSelectedVehicleId] = useState(null);
const [transactionOutDetails, setTransactionOutDetails] = useState([]);
const [transactionInDetails, setTransactionInDetails] = useState([]);
const [toutDate, setToutDate] = useState("");

  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  
  // Function to search by date
  function searchName(text) {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setSearchbydate(false);
    setLoader(true);

    // Fetch outgoing transactions
    axios
      .get(`/api/totaltransactionDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${text}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setTout(res.data.prodDo);
        setToutDate(res.data.date);
        setLoader(false);
      })
      .catch((error) => {
        handleError(error);
      });

     axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log(res.data);
        setProduct(res.data);
      })
      .catch((error) => {
        handleError(error);
      });
    axios
      .get(`/api/totaltransaction/date=${text}/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setTin(res.data);
        setLoader(false);
      })
      .catch((error) => {
        handleError(error);
      });
      setLoader(false);
  }
 
  function handleError(error) {
    if (error.response && error.response.status === 404) {
      toast.success(error.response.data.message);
    } else {
      toast.error(error.message || "An error occurred");
    }
    setLoader(false);
  }

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);

    try {
      const outgoingResponse = await axios.get(
        `/api/totaltransactionDo/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTransactions(outgoingResponse.data.prodDo);
      setToutDate(outgoingResponse.data.date);

      const productResponse = await axios.get(
        `/api/product/productstatus=true/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProduct(productResponse.data);



      const incomingResponse = await axios
        .get(`/api/totaltransaction/date=${currentDate}/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      setTransactions1(incomingResponse.data);
      
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleOutgoingVehicleFilterChange = (event) => {
    setSelectedOutgoingVehicle(event.target.value);
  };

  const handleIncomingVehicleFilterChange = (event) => {
    setSelectedIncomingVehicle(event.target.value);
  };

  const outgoingVehicleOptions = new Set();
  const incomingVehicleOptions = new Set();

  transactions.concat(tout).forEach((ttin) => {
    if (!outgoingVehicleOptions.has(ttin.vehicle.truckinfo)) {
      outgoingVehicleOptions.add(ttin.vehicle.truckinfo);
    }
  });

  
  transactions1.concat(tin).forEach((ttin) => {
    if (!incomingVehicleOptions.has(ttin.vehicleid)) {
      incomingVehicleOptions.add(ttin.vehicleid);
    }
  });

 
  const filteredIncomingTransactions = searchbydate
    ? transactions1.filter((ttin) =>
        selectedIncomingVehicle ? ttin.vehicleid === selectedIncomingVehicle : true
      )
    : tin.filter((ttin) =>
        selectedIncomingVehicle ? ttin.vehicleid === selectedIncomingVehicle : true
      );


  const outgoingVehicleOptionsArray = Array.from(outgoingVehicleOptions);
  const incomingVehicleOptionsArray = Array.from(incomingVehicleOptions);

  // Filtered outgoing transactions based on selected vehicle
  
  const filteredOutgoingTransactions = searchbydate
    ? transactions.filter((ttin) =>
        selectedOutgoingVehicle ? ttin.vehicle.truckinfo === selectedOutgoingVehicle : true
      )
    : tout.filter((ttin) =>
        selectedOutgoingVehicle ? ttin.vehicle.truckinfo === selectedOutgoingVehicle : true
      );
      const openExemptedModal = async (productName, date, deliveredVehicleid) => {
        setSelectedProductName(productName);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const currentDate = new Date().toISOString().split('T')[0];
          const response = await axios.get(
            `/api/ExemptedDetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setExemptedDetails(response.data);
          setExemptedModalIsOpen(true);
        } catch (error) {
          handleError(error);
        }
      };
      
      const closeExemptedModal = () => {
        setExemptedModalIsOpen(false);
      };
      const openAdditionalModal = async (productName, date, deliveredVehicleid) => {
        setSelectedProductName(productName);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const currentDate = new Date().toISOString().split('T')[0];
          const response = await axios.get(
            `/api/additionaldetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setAdditionaldetails(response.data);
          setAdditionalModalIsOpen(true);
        } catch (error) {
          handleError(error);
        }
      };
      
      const closeAdditionalModal = () => {
        setAdditionalModalIsOpen(false);
       
      };
      const openOnlineModal = async (productName, date, deliveredVehicleid) => {
        setSelectedProductName(productName);
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const currentDate = new Date().toISOString().split('T')[0];
          const response = await axios.get(
            `/api/OnlineDetail/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/productname=${productName}/deliveredVehicleid=${deliveredVehicleid}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setOnlineDetails(response.data);
          setOnlineModalIsOpen(true);
        } catch (error) {
          handleError(error);
        }
      };
      
      const closeOnlineModal = () => {
        setOnlineModalIsOpen(false);
      };

      const openTransactionOutModal = async (vehicleId, date) => {
        const token = JSON.parse(localStorage.getItem("data")).token;
       setSelectedVehicleId(vehicleId);
        const trans = await axios.get(
          `/api/TransactionoutgoingDo/vehicle=${vehicleId}/date=${date}/orgcode=${ JSON.parse(localStorage.getItem("data")).orgcode}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        console.log(trans);
    
        setTransactionOutDetails(trans.data.prodDo);
        setToutDate(trans.data.date);
        const pro = await axios.get(
          `/api/product/productstatus=true/orgcode=${
            JSON.parse(localStorage.getItem("data")).orgcode
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        setProduct(pro.data);
    
        setTransactionOutModalIsOpen(true);
      };

      const closeTransactionOutModal = () => {
        setTransactionOutModalIsOpen(false);
      };

      const openTransactionInModal = async (vehicleId,productName, date) => {
        const token = JSON.parse(localStorage.getItem("data")).token;
       setSelectedVehicleId(vehicleId);
       setSelectedVehicleId(productName);
        const trans = await axios.get(
          `/api/Returntransaction/orgcode=${ JSON.parse(localStorage.getItem("data")).orgcode}/date=${date}/vehicleid=${vehicleId}/productname=${productName}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTransactionInDetails(trans.data);
        setTransactionInModalIsOpen(true);
      };

      const closeTransactionInModal = () => {
        setTransactionInModalIsOpen(false);
      };
      
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br />
      <br />
      <div style={{ position: "relative", left: "260px" }}>
        <Search searchName={searchName} />{" "}
      </div>
      <br />
      <div>
        <div>
          <div className="col-12 mx-xl-auto ps-xl-10 ">
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="d-flex">
                  <div className="card-title">Transaction Details
                     </div>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/users/updatetin">
                    <button className="btn btn-primary">
                      Transactionin Update
                    </button>
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/users/updatetout">
                    <button className="btn btn-primary">
                      Transactionout Update
                    </button>
                  </Link>
                </div>{" "}
              </div>
            </div>
          </div>
          <br />
          <div>
            <div className="col-12   ps-xl-10 ">
              <div className="card card-raised">
                <div className="card-body  ">
                  <div className="card-title">Transaction Outgoing Details{""}
                  
                  
                   </div>
                   <br />
                   <br />


        <div>
          <label>Vehicle Id</label>
          <select
            value={selectedOutgoingVehicle}
            onChange={handleOutgoingVehicleFilterChange}
          >
            <option value="">All</option>
            {outgoingVehicleOptionsArray.map((vehicle) => (
      <option key={vehicle} value={vehicle}>
        {vehicle}
              </option>
            ))}
          </select>
        </div>
       


                  <br />
                

                  <table className="table table-bordered ">
                    <thead>
                      <tr
                        style={{
                          fontSize: "17px",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                        
                        <th>Vehicle Id</th>
                    {product.map((prod) => {
                      return <th>{prod.productname}</th>;
                    })}
                       <th>Status</th>

                      </tr>
                    </thead>
                    <tbody>
                      {searchbydate && filteredOutgoingTransactions.length === 0 ? (
                       <tr>
                      <td colSpan={product.length + 4} className="text-center">NO DATA AVAILABLE</td>
                      </tr>
                       ) : (
                        searchbydate &&
                        filteredOutgoingTransactions.map((ttin) => {
                          return (
                            <tr>
                            <th>{ttin.vehicle.truckinfo}</th>
                            {ttin.pdo.map((ps, index) => {
                              return <th key={index}>{ps.qty}</th>;
                            })}
                           <td>
                             <button className="btn btn-primary" onClick={() => openTransactionOutModal(ttin.vehicle.truckinfo, toutDate)}>View</button>
                             </td>
                          </tr>
                          )
              
                            }))
                            }
                      {!searchbydate &&
                        filteredOutgoingTransactions.map((ttin, index) => {
                          return (
                            <tr key={index}>
                            <th>{ttin.vehicle.truckinfo}</th>
                            {ttin.pdo.map((ps, index) => {
                              return <th key={index}>{ps.qty}</th>;
                            })}
                            <td>
                             <button className="btn btn-primary" onClick={() => openTransactionOutModal(ttin.vehicle.truckinfo, toutDate)}>View</button>
                             </td>
                          </tr>
                          )
                        })}
                    </tbody>
                  </table>
                
                
                </div>
              </div>
            </div>
          </div>
              
          <br />
          <div>
            <div className="col-12   ps-xl-10">
              <div className="card card-raised ">
                <div className="card-body ">
                  <div className="card-title">Transaction Incoming Details{""}
                 
                   </div>
                   <br />
                   <br />
                   <div>
          <label> Vehicle Id</label>
          <select
            value={selectedIncomingVehicle}
            onChange={handleIncomingVehicleFilterChange}
          >
            <option value="">All</option>
            {incomingVehicleOptionsArray.map((vehicle) => (
      <option key={vehicle} value={vehicle}>
        {vehicle}
              </option>
            ))}
          </select>
        </div>
                  <br />
                  <div className="table-responsive">
                  <table className="table table-bordered ">
                    <thead>
                      <tr
                        style={{
                          fontSize: "17px",
                          backgroundColor: "blue",
                          color: "white",
                        }}
                      >
                       <th>Vehicle Id</th>
                    <th>Product Name</th>
                    <th>Sales</th>
                    <th>New Connection</th>
                    <th>Additional</th>
                    <th>Onlinepay</th>
                    <th>Exempted</th>
                    <th>Returned Product</th>
                    <th>Transfer</th>
                    <th>Status</th>

                      </tr>
                    </thead>
                    <tbody>

                   
                    {/* {product.map((pro) => {
                          return (
                            <tr>
                            <th></th>

                            <th>{pro.productname}</th>

                          </tr>

                          )

                        })} */}

                      {searchbydate &&  filteredIncomingTransactions.length === 0 ? (
                       <tr>
                      <td colSpan="12" className="text-center">NO DATA AVAILABLE</td>
                      </tr>
                       ) : (
                        searchbydate &&
                        filteredIncomingTransactions.map((ttin) => {
                          return (
                            <tr>
                           <td>{ttin.vehicleid}</td>                           
                              <td>{ttin.productname}</td>
                              <td>{ttin.totalquantity}</td>
                              <th>{ttin.totalneworg}</th>
                              <td>{ttin.totaladditional}
                              <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openAdditionalModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                              </td>
                              <td>{ttin.totalonlinepay}
                              <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openOnlineModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                              </td>
                              <td>{ttin.totalexempted}
                              <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openExemptedModal(ttin.productname, ttin.date,  ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                              </td>
                              <td>{ttin.totalreturnedproduct}</td>
                              <td>{ttin.totaltransfer}</td>
                           
                              <td>
                             <button className="btn btn-primary" onClick={() => openTransactionInModal(ttin.vehicleid, ttin.productname, ttin.date)}>View</button>
                             </td>
                          </tr>
                        );
                      }))}
                      {!searchbydate &&
                        filteredIncomingTransactions.map((ttin) => {
                          return (
                            <tr>
                         
                            <td>{ttin.vehicleid}</td>
                            <td>{ttin.productname}</td>
                            <td>{ttin.totalquantity}</td>
                            <td>{ttin.totalneworg}</td>
                            <td>{ttin.totaladditional}
                            <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openAdditionalModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                            </td>
                      
                            <td>{ttin.totalonlinepay}
                            <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openOnlineModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                            </td>
                            <td>{ttin.totalexempted}
                            <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openExemptedModal(ttin.productname, ttin.date, ttin.vehicleid)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                                </span>
                            </td>
                          
                            <td>{ttin.totalreturnedproduct}</td>
                    
                            <td>{ttin.totaltransfer}</td>
                      
            
                            <td>
                             <button className="btn btn-primary" onClick={() => openTransactionInModal(ttin.vehicleid, ttin.productname, ttin.date)}>View</button>
                             </td>
                        </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Exemptedmodalpopup
        isOpen={exemptedModalIsOpen}
        onRequestClose={closeExemptedModal}
        exemptedDetails={exemptedDetails}
        productName={selectedProductName} // Pass selected product name to the modal
      />
       <AdditionalModalPopup
        isOpen={additionalModalIsOpen}
        onRequestClose={closeAdditionalModal}
        additionalDetails={additionalDetails}
        productName={selectedProductName} 
      />
       <OnlineModalPopup
        isOpen={onlineModalIsOpen} 
        onRequestClose={closeOnlineModal}
        onlineDetails={onlineDetails}
        productName={selectedProductName} 
      />
       <TransactionIncoming
        isOpen={transactionInModalIsOpen} 
       onRequestClose={closeTransactionInModal}
       transactionInDetails={transactionInDetails}
       vehicleId={selectedVehicleId}
       selectedProductName={selectedProductName}
      />
       <TransactionOutgoing
        isOpen={transactionOutModalIsOpen} 
        onRequestClose={closeTransactionOutModal}
        transactionOutDetails={transactionOutDetails}
        toutDate={toutDate}
        vehicleId={selectedVehicleId}
        products={product}
      />
            
        </div>
        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default Transactions;

