import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";

function UpdateProductquantity() {
  const [uproduct, setUproduct] = useState({
    productcode: "",
    orgcode: "",
    loadinquantity: "",
    loadinstatus: "",
    loadoutquantity: "",
    loadoutstatus: "",
    transactioninquantity: "",
    transactioninstatus: "",
    transactionoutquantity: "",
    transactionoutstatus: "",
  });

  const url = "/api/proiductquantity/create";

  function rsubmit(e) {
    e.preventDefault();

    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        uproduct,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        toast.success("product updated successfully");
      })

      .catch((error) => {
        console.log(error);
      });
  }
  function rhandle(e) {
    const newdata = { ...uproduct };
    newdata[e.target.id] = e.target.value;
    setUproduct(newdata);
  }
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Update Products</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">Product Id</label>
                        <input
                          className="form-control"
                          id="productcode"
                          type="text"
                          placeholder="productcode"
                          value={uproduct.productcode}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="orgcode">Organization Id</label>
                        <input
                          className="form-control"
                          id="orgcode"
                          type="text"
                          placeholder="orgcode"
                          value={uproduct.orgcode}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="loadinquantity">Loadin Quantity</label>
                        <input
                          className="form-control"
                          id="loadinquantity"
                          type="text"
                          placeholder="loadinquantity"
                          value={uproduct.loadinquantity}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="transactionid">LoadIn Status</label>
                        <input
                          className="form-control"
                          id="loadinstatus"
                          type="text"
                          placeholder="loadinstatus"
                          value={uproduct.loadinstatus}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="loadoutquantity">Loadout Quantity</label>
                        <input
                          className="form-control"
                          id="loadoutquantity"
                          type="text"
                          placeholder="loadoutquantity"
                          value={uproduct.loadoutquantity}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="loadoutstatus">Loadout Status</label>
                        <input
                          className="form-control"
                          id="loadoutstatus"
                          type="text"
                          placeholder="loadoutstatus"
                          value={uproduct.loadoutstatus}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="transactioninquantity">
                          Transactionin Quantity
                        </label>
                        <input
                          className="form-control"
                          id="transactioninquantity"
                          type="text"
                          placeholder="transactioninquantity"
                          value={uproduct.transactioninquantity}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="transactioninstatus">
                          Transactionin Status
                        </label>
                        <input
                          className="form-control"
                          id="transactioninstatus"
                          type="text"
                          placeholder="transactioninstatus"
                          value={uproduct.transactioninstatus}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="transactionoutquantity">
                          Transactionout Quantity
                        </label>
                        <input
                          className="form-control"
                          id="transactionoutquantity"
                          type="text"
                          placeholder="transactionoutquantity"
                          value={uproduct.transactionoutquantity}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="transactionoutstatus">
                          Transactionout Status
                        </label>
                        <input
                          className="form-control"
                          id="transactionoutstatus"
                          type="text"
                          placeholder="transactionoutstatus"
                          value={uproduct.transactionoutstatus}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>

                    <input
                      type="submit"
                      value="update"
                      className="btn btn-primary"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProductquantity;
