import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";

const AddSettings = ({ isOpen, onRequestClose }) => {


  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [selectedProductId, setSelectedProductId] = useState(null);

  const [uproduct, setUproduct] = useState({
    id: "",
    apikey: "",
    appname:"",
    username:"",
    token:"",
    url: "",
    userid:  JSON.parse(localStorage.getItem("data")).userid,
   
    
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
  const url = "/api/Locationtrack/create";
  const navigate = useNavigate();
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }


const confirmFirstModal = window.confirm("Are you sure you want to save?");

  if (confirmFirstModal) {
    const confirmSecondModal = window.confirm("To save, you need to re-login. Do you want to proceed?");

    if (confirmSecondModal) {
      const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        uproduct,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Added successfully");
        navigate("/");
       JSON.parse(localStorage.removeItem("data"));

       
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.success(error.response.data.message);
          setLoading(false);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.code);
          setLoading(false);
        } else {
          toast.error(error.response.data.message);
          setLoading(false);
        }
      });
    } else {
        // User clicked cancel on the second confirmation
        setLoading(false);
      }
    } else {
      // User clicked cancel on the first confirmation
      setLoading(false);
    }
  }
  function rhandle(e) {
    const newdata = { ...uproduct };
    if (e.target.id.startsWith("pprice")) {
      const nestedKeys = e.target.id.split(".");
      newdata[nestedKeys[0]][nestedKeys[1]] = e.target.value;
    } else {
      // If not related to nested property, update as usual
      newdata[e.target.id] = e.target.value;
    }    setUproduct(newdata);
  }
  
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Create </Modal.Title>
    </Modal.Header>
    <Modal.Body>

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="id">User Id</label>
                        <input
                          className="form-control"
                          id="userid"
                          type="text"
                          placeholder="userid"
                          value={uproduct.userid}
                          //onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="appname">API Name</label>
                        <input
                          className="form-control"
                          id="appname"
                          type="text"
                          placeholder="appname"
                          value={uproduct.appname}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="apikey">API Key</label>
                        <input
                          className="form-control"
                          id="apikey"
                          type="text"
                          placeholder="apikey"
                          value={uproduct.apikey}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="username">UserName</label>
                        <input
                          className="form-control"
                          id="username"
                          type="text"
                          placeholder="username"
                          value={uproduct.username}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="token">Token</label>
                        <input
                          className="form-control"
                          id="token"
                          type="text"
                          placeholder="token"
                          value={uproduct.token}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="url">URL</label>
                        <input
                          className="form-control"
                          id="url"
                          type="text"
                          placeholder="url"
                          value={uproduct.url}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <td>
                {/* Button to open the modal */}
     

              </td>
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                     &nbsp;&nbsp;&nbsp;&nbsp;

          <Button variant="secondary" onClick={onRequestClose}>
            Close
         </Button>
                  </form>
                </div>
              </div>
            </div>


          </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
};

export default AddSettings;
