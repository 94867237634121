import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import "./Spinner.css";
import AdminDashboard from "./admindashboard";
import { Link, useParams, useNavigate } from "react-router-dom";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Emissioncertificate(props) {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [ecertificate, setEcertificate] = useState({
    certificationnumber: "",
    issuedate: "",
    expirydate: "",
    vehicleid: props.params.truckinfo,

    file: null,
    filesize: "",
  });
  const [loading, setLoading] = useState(false);
  const url = "/api/Emisioncertificate/create";
  const navigate = useNavigate();
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        ecertificate,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Emissioncertificate updated successfully");
        navigate(`/users/vehicledocuments/${props.params.truckinfo}`);
      })

      .catch((error) => {
        console.log(error);
      });
  }
  function rhandle(e) {
    const newdata = { ...ecertificate };
    newdata[e.target.id] = e.target.value;
    setEcertificate(newdata);
  }
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const handlePdfFileChange = async (e) => {
    const convertedPdf = await getBase64FromFile(e.target.files[0]);
    const pdfObject = {
      ...ecertificate,

      filesize: convertedPdf.metadata,

      file: convertedPdf.content,
    };

    setEcertificate(pdfObject);
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="col-8  mx-xl-auto px-xl-5 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Fitnescertificate Details</div>
            <br />

            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="certificationnumber">
                          Certificationnumber
                        </label>
                        <input
                          className="form-control"
                          id="certificationnumber"
                          type="text"
                          placeholder="certificationnumber"
                          value={ecertificate.permitnumber}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="issuedate">issuedate</label>
                        <input
                          className="form-control"
                          id="issuedate"
                          type="date"
                          placeholder="issuedate"
                          value={ecertificate.issuedate}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="expirydate">expirydate</label>
                        <input
                          className="form-control"
                          id="expirydate"
                          type="date"
                          placeholder="expirydate"
                          value={ecertificate.expirydate}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="file">file</label>
                        <input
                          className="form-control"
                          id="file"
                          type="file"
                          placeholder="upload document"
                          // value={insurance.file}
                          onChange={(e) => handlePdfFileChange(e)}
                        />
                      </div>
                    </div>
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default withRouter(Emissioncertificate);
