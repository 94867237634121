import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import ManagerSidebar from "./managerSidebar";
import "./Spinner.css";
import { useNavigate } from "react-router-dom";

function UpdateLoadin() {
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const navigate = useNavigate();

  const [transaction, setTransaction] = useState({
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    truckinfo: "",
    date: currentDate,
    status: "full",
    approvalstatus: "",
    invoiceid: "",
    file: null,
    fileSize: "",
    products: [
      {
        productcode: "",
        productname: "",
        quantity: "",
      },
    ],
  });
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);

  const url = "/api/loadin/create";

  const token = JSON.parse(localStorage.getItem("data")).token;
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(true);
    const trans = await axios.get(
      `/api/product/productstatus=true/orgcode=${
        JSON.parse(localStorage.getItem("data")).orgcode
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(trans);

    setProduct(trans.data);
    setTransaction({
      ...transaction,
      products: trans.data.map((item) => ({
        productcode: item.productcode,
        productname: item.productname,
        quantity: 0,
      })),
    });

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);

    const token = JSON.parse(localStorage.getItem("data")).token;
    axios

      .post(url, transaction, { headers: { Authorization: `Bearer ${token}` } })

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("Loadin updated successfully");
        navigate("/users/loaddetails");

      })

      .catch((error) => {
        console.error("Error:", error.response.data); 
        setLoading(false);
        toast.error("Error: " + error.response.data.message);
      });
  }
  function rhandle(e, field, index) {
    let actualValue = e.target.value;

    if (field == "quantity") {
      const newproduct = [...transaction.products];
      newproduct[index] = { ...newproduct[index], quantity: actualValue };

      setTransaction({ ...transaction, products: newproduct });
    } else {
      setTransaction({ ...transaction, [field]: actualValue });
    }
  }
  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...transaction,

      file: convertedPdf.content,
      fileSize: file.size,
      fileContentType:convertedPdf.metadata
    };

    setTransaction(pdfObject);
  };
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br /> <br /> <br /> <br /> <br />
      {loading && <div className="loading"></div>}
      <div
        className="col-8  mx-xl-auto px-xl-5 "
        style={{ position: "relative", left: "150px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="card-title">Load Incoming Updates</div>
            <br />

            <div className="col-xl-12 mx-xl-auto ">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form>
                    <div>
                      <label for="date">Date</label>
                      <input
                        id="date"
                        type="text"
                        value={transaction.date}
                        onChange={(e) => rhandle(e, "date")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="invoice">Vehicle Id</label>
                      <input
                        id="truckinfo"
                        type="text"
                        placeholder="truckinfo"
                        value={transaction.truckinfo}
                        onChange={(e) => rhandle(e, "truckinfo")}
                      />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                      <label for="invoiceid">Invoice</label>
                      <input
                        id="invoiceid"
                        type="text"
                        placeholder="invoiceid"
                        value={transaction.invoiceid}
                        onChange={(e) => rhandle(e, "invoiceid")}
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;

                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="file">Upload Document</label>
                        <input
                          className="form-control  smaller-input "
                          id="file"
                          type="file"
                          placeholder="upload document"
                        
                          onChange={(e) => handlePdfFileChange(e)}
                        />
                      </div>
                    </div>
                    
                    <br />
                    <br />
                    <div>
                      <div>
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>Product Id</th>
                              <th>Product Name</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transaction.products.map((product, index) => {
                              return (
                                <>
                                  <tr key={product.productcode}>
                                    <td>{product.productcode}</td>
                                    <td>{product.productname}</td>
                                    <td>
                                      <input
                                        id="id"
                                        type="number"
                                        placeholder="0"
                                        style={{ border: "none" }}
                                        onChange={(e) =>
                                          rhandle(e, "quantity", index)
                                        }
                                      />
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>{" "}
                    </div>

                    <button
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                      onClick={(e) =>
                        rsubmit(e)
                      }
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <div className="loading"></div>}
    </div>
  );
}

export default UpdateLoadin;
