import React, { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";
import "./Loader.css";
import SubregionCreateModal from "./SubregionCreateModal";

function Subregion() {
  const token = JSON.parse(localStorage.getItem("data")).token;

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [subregions, setSubregions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const getData = async () => {
    setLoading(true);
    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    const token = JSON.parse(localStorage.getItem("data")).token;
   
    const trans = await axios
      .get("/api/subscription-region", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setSubregions(res.data);
        setLoading(false);
      });
  };

  const handleAddRegion = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div
        className="col-9 mx-xl-auto ps-xl-9"
        style={{ position: "relative", left: "100px", top: "110px" }}
      >
        {" "}
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="card-title"> Subscription Region Details
            <button className="btn btn-primary" style={{marginLeft:"60%"}} onClick={handleAddRegion}>
               ADD
            </button>
            </div>
            <br/>
            
            <table
              className="table table-bordered "
              style={{ position: "relative" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Region</th>
                  <th>Currency</th>
                  <th>Symbol</th>
                </tr>
              </thead>
              <tbody>
                {subregions.map((subregions) => {
                  return (
                    <tr
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <th>{subregions.region}</th>
                      <th> {subregions.currency}</th>
                      <th> {subregions.symbol}</th>
                    </tr>
                  );
                })}{" "}
              </tbody>
            </table>
            <div>
            <SubregionCreateModal 
            isOpen={isModalOpen} 
            onRequestClose={handleCloseModal} />
            </div>
          </div>
        </div>
        {loading && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default Subregion;
