import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "@material/mwc-textfield/mwc-textfield.js";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";
import Footer from "./footer";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function Otpvalidation(props) {
  const [registerdata, setAdata] = useState({
    token: "",
  });
  const [accdetails, setAccdetails] = useState({});

  const [subdetails, setSubdetails] = useState({
    username: JSON.parse(localStorage.getItem("RegistrationData")).username,
    orgcode: JSON.parse(localStorage.getItem("RegistrationData")).orgcode,
  });
  const navigate = useNavigate();

  const url = `/api/OTPVerification?token=${registerdata.token}`;

  const [loading, setLoading] = useState(false);
  function rsubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios.post(url).then(
      (res) => {
        console.log(res.data);
        setLoading(false);
        if (`${props.params.id}` == 0) {
          axios
            .post("/api/user/subscription/freetrial", subdetails)
            .then((res) => {
              navigate("/signin");
            });
        } else {
          navigate(`/razorpay/${props.params.id}`);
        }
        toast("OTP validate succefully and registered successfully");
      },
      (error) => {
        setAdata({ error });
      }
    );
  }

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setAdata({ ...registerdata, [field]: actualValue });
  };
  function cynefianwebsite(e) {
    window.open("https://www.cynefian.com", "_blank");
  }
  return (
    <div className="bg-primary">
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-lg-4">
                  <div className="card card-raised shadow-10 mt-5 mt-xl-10 mb-4">
                    <div className="row g-0">
                      <div className="col-lg-12 col-lg-12">
                        <div className="card-body p-5">
                          <div className="text-center">
                            <img
                              className="mb-3"
                              src="assets/img/icons/background.svg"
                              alt="..."
                              style={{ height: "48px" }}
                            />
                            <h1 className="display-5 mb-0">OTP Verification</h1>
                          </div>
                          <form onSubmit={(e) => rsubmit(e)}>
                            <div className="mb-4">
                              <input
                                class="form-control"
                                type="text"
                                placeholder="enter OTP"
                                id="token"
                                value={registerdata.token}
                                onChange={(e) => handleChange(e, "token")}
                              ></input>
                            </div>
                            <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                              <Link
                                className="small fw-500 text-decoration-none"
                                to="/signin"
                              >
                                Return to login
                              </Link>
                              <input
                                type="submit"
                                className="btn btn-primary"
                                value="Verify"
                              />
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="text-center mb-5">
                        <Link
                          className="small fw-500 text-decoration-none link-white"
                          to="/register"
                        >
                          Need an account? Sign up!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>

        <div id="layoutAuthentication_footer">
         <Footer/>
        </div>
      </div>{" "}
      {loading && <div className="loading"></div>}
    </div>
  );
}
export default withRouter(Otpvalidation);
