import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import {FiInfo } from "react-icons/fi";  
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";

const TransactionOutgoing = ({ isOpen, onRequestClose, transactionOutDetails, products,toutDate}) => {
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const url = "/api/TransactionoutgoingDo";

  

  useEffect(() => {
  
  }, []);

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Transactions Outgoing Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div>
    {transactionOutDetails.length > 0 && (
  <div>
    <label style={{ fontSize: '1em', color: "#000000" }}>Vehicle Id: {transactionOutDetails[0].vehicle}</label>
    </div>
    )}
    <label style={{ fontSize: '1em', color: "#000000" }}>Date: {toutDate}</label>
      </div>

      <table className="table table-bordered ">
        <thead>
        <tr>
                <th>Time</th>
                {products.map((prod) => {
                  return <th>{prod.productname}</th>;
                })}
                <th>Approval Status</th>
                
              </tr>
            </thead>
            <tbody>
            {transactionOutDetails.map((ttin, index) => (
            <tr key={index}>
                    <th>{ttin.time}</th>
                    {ttin.pdo.map((ps, index) => {
                      return <th key={index}>{ps.qty}</th>;
                    })}
                    <th>{ttin.status}</th>
                    </tr>
          ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onRequestClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
export default TransactionOutgoing;
