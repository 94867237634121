import React, { useEffect, useState } from "react";
import Dash from "./dash";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../api/baseurl";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}
function AnotherSubscription(props) {
  const [checkedmap, setCheckedmap] = useState(false);
  const [sub, setSub] = useState([]);
  const [subfeatur, setSubfeatur] = useState([]);
  const [subfeaturmodel, setSubfeaturmodel] = useState([]);

  const user = JSON.parse(localStorage.getItem("data"));
  const url = "/api/subscription-model";

  const getData = async () => {
    const sf = await axios.get("/api/subscription-features");

    console.log(sf.data);

    setSubfeatur(sf.data);

    const sfmm = await axios.get("/api/SubmodelandfeatureDo");

    console.log(sfmm.data.features);

    setSubfeaturmodel(sfmm.data);
    const prof1 = await axios.get(url);

    console.log(prof1.data);

    setSub(prof1.data);
  };

  useEffect(() => {
    getData();
  }, []);

  function handletotalamount(e, subscriptionid) {
    navigate(`/razorpay/${subscriptionid}`);
  }

  const navigate = useNavigate();

  console.log(subfeaturmodel, "aaa");
  return (
    <div id="layoutDrawer_content">
      <main>
        <div
          style={{ position: "relative", top: "100px" }}
          className=" col-10 mx-xl-auto ps-xl-9"
        >
          <div className="card card-raised">
            <div className="card-body ">
              <div className="container-xl p-8">
                <div className="text-center mb-5">
                  <h1>Subscribe for the requred plans</h1>
                  <p className="mb-0">No contracts, no surprise fees</p>
                </div>

                <div className="row" style={{ maxWidth: "100%" }}>
                  {subfeaturmodel.map((subscription) => {
                    return (
                      <div className="col-sm-3">
                        <div className="">
                          <div className="card card-raised h-100">
                            <div className="card-body">
                              <h2 className="overline">
                                {subscription.submodel}
                              </h2>
                              <div className="d-flex align-items-center mb-3">
                                <div className="display-5">
                                  {subscription.cost}
                                </div>
                                <div className="text-muted ms-2">
                                  {subscription.region}
                                </div>
                              </div>
                              <p>
                                For most businesses that want to optimize web
                                queries
                              </p>

                              {subscription.features.map((sbf) => {
                                return (
                                  <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-0">
                                      <div className="d-flex align-items-center">
                                        <i className="material-icons icon-xs me-2">
                                          check
                                        </i>
                                        {sbf.features}
                                      </div>
                                    </li>
                                  </ul>
                                );
                              })}
                            </div>
                            <div className="card-footer border-0 bg-transparent pt-0">
                              <button
                                className="btn btn-outline-primary d-grid"
                                onClick={(e) =>
                                  handletotalamount(e, subscription.id)
                                }
                              >
                                Choose Plan
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default withRouter(AnotherSubscription);
