import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Bar, Pie } from "react-chartjs-2";
import ChartJS from "chart.js/auto";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import AdminDashboard from "./admindashboard";
import CanvasJSReact from "@canvasjs/react-charts";
import Search from "./search";
import { IoSearchOutline } from "react-icons/io5";
import ConfirmationModal from "./endtransactionpopup";
import Monthlytarget from "./Monthlytarget";


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
function Productanalytics() {
  const [vehicle, setVehicle] = useState([]);
  const [deliveryagent, setDeliveryaget] = useState([]);
  const navigate = useNavigate();
  const [ClosestockYesterday, setClosestockYesterday] = useState(null);
  const [openstockYesterday, setOpenstockYesterday] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ismonthlyModalOpen, setIsmonthlyModalOpen] = useState(false);


  const date = new Date();
  const date1 = new Date();
  date.setDate(date.getDate());
  date1.setDate(date1.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [product, setProduct] = useState([]);
  const [targetbymonth, setTargetbymonth] = useState([]);
  const [producttargetbymonth, setProducttargetbymonth] = useState([]);

  const [searchbymonth, setSearchBymonth] = useState(true);

  const [totaltransaction, setTotaltransaction] = useState([]);

  const [productnames, setProductname] = useState([]);
  const [datalabels, setDatalabels] = useState({
    labels: [],

    datasets: [
      {
        label: "Target Sale",
        data: [1000, 2000],
        backgroundColor: "",
      },
    ],
  });
  const [revenuedatalabels, setRevenuedatalabels] = useState([]);
  const [searchstate, setSearchstate] = useState(true);
  const [searchbydate, setSearchbydate] = useState([]);
  const [searchproductsale, setSearchproductsale] = useState(true);
  const [productsale1, setProductsale1] = useState({
    labels: [],
    datasets: [
      {
        label: "Product Sale",
        data: [],
        backgroundColor: "",
      },
    ],
  });
  const [productdate, setProductdate] = useState({
    date: "",
  });

  const [productsale, setProductsale] = useState({
    labels: [],
    datasets: [
      {
        label: "Product Sale",
        data: [],
        backgroundColor: "",
      },
    ],
  });
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);



  const checkCloseStockAndUpdate = async () => {
    try {
        const token = JSON.parse(localStorage.getItem("data")).token;

       
        let OpeningStock = null;
        let allOpeningStock = null;

        while (true) {
            const opRes = await axios.get(`/api/Openingstock/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/datetime=${currentDate}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const openStockres = await axios.get(`/api/Openingstock`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            OpeningStock = opRes.data;
            allOpeningStock = openStockres.data;

            if ((OpeningStock.length === 0) && (allOpeningStock.length > 0)) {
                const shouldCompleteTransaction = await new Promise((resolve) => {
                  setIsModalOpen(true);

                    // resolve(window.confirm("End transaction not completed. Proceed to complete the end transaction?"));
                });

                if (shouldCompleteTransaction) {
                    navigate("/users/stock");
                    break; 
                }
            } else {
                break; 
            }
        }
    } catch (error) {
        console.error("An error occurred:", error.message);
    }
};

  
  
  
  

  const url = `/api/product/orgcode=${
    JSON.parse(localStorage.getItem("data")).orgcode
  }`;
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    const products = await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setProduct(res.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
          setLoader(false);
        } else {
          toast.error(error.response.data.message);
          setLoader(false);
        }
      });
    const vehicles = await axios
      .get(
        `/api/vehicle/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoader(false);
        setVehicle(res.data);
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.success(error.response.data.message);
          setLoader(false);
        } else {
          toast.error(error.response.data.message);
          setLoader(false);
        }
      });
    const authority = await axios
      .get(
        `/api/authority/role=ROLE_DELIVERY_AGENTS/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setLoader(false);
        setDeliveryaget(res.data);
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.success(error.response.data.message);
          setLoader(false);
        } else {
          toast.error(error.response.data.message);
          setLoader(false);
        }
      });
    axios
      .get(
        `/api/totaltransaction/date=${currentDate}/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setRevenuedatalabels(res.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.success(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      });
    axios
      .get(
        `/api/totaltransaction/currentmonth=${currentDate}/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setProducttargetbymonth(res.data);
      });
    axios
      .get(
        `/api/totaltransaction/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${currentDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setProductsale({
          ...productsale,
          labels: res.data.map((item) => {
            return item.productname;
          }),
          datasets: [
            {
              label: "Product Sale",
              data: res.data.map((item) => {
                return item.totalsale;
              }),
            },
          ],
        });
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.success(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
 
 

  useEffect(() => {
    getData();
    checkCloseStockAndUpdate();

    const currentfirstDate = new Date("2024-03-01T00:00:00");

    if (currentfirstDate.getDate() === 1) {
        setIsmonthlyModalOpen(true);
    }
  }, []);
  const handleCloseModal = () => {
    setIsmonthlyModalOpen(false);
    getData();
  };

  const searchName = async (text) => {
    setSearchstate(false);

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .get(
        `/api/totaltransaction/date=${text}/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((response) => {
        setSearchbydate(response.data);
      });
  };
  const searchName1 = async (e, text) => {
    setSearchproductsale(false);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .get(
        `/api/totaltransaction/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }/date=${text}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setProductsale1({
          ...productsale1,
          labels: res.data.map((item) => {
            return item.productname;
          }),
          datasets: [
            {
              label: "Product Sale",
              data: res.data.map((item) => {
                return item.totalsale;
              }),
            },
          ],
        });
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.success(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const searchByMonth = async (e, text) => {
    setSearchBymonth(false);
    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .get(
        `/api/totaltransaction/month=${text}/orgid=${
          JSON.parse(localStorage.getItem("data")).orgcode
        }`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setTargetbymonth(res.data);
      })

      .catch((error) => {
        if (error.response.status === 404) {
          toast.success(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  function rhandle(e, field) {
    e.preventDefault();
    let actualValue = e.target.value;

    setProductdate({ productdate, [field]: actualValue });
  }
  const handleCancel = () => {
    setIsModalOpen(false); // Assuming you want to close the modal when cancel is clicked
  };
  
  const handleConfirm = () => {
    navigate("/users/stock"); // Assuming you want to navigate somewhere when confirm is clicked
  };
  

  return (
    <div>
      <div
        className="col-10  mx-xl-auto ps-xl-10 "
        style={{ position: "relative", top: "110px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="col-6  mx-xl-auto ps-xl-4 ">
                <div className="card card-raised">
                  <div className="card-body  ">
                    <div className="card-title">Total Number of Vehicles</div>
                    <h1 style={{ textAlign: "center" }}>{vehicle.length}</h1>
                  </div>
                </div>
              </div>
              <div className="col-6  mx-xl-auto ps-xl-4 ">
                <div className="card card-raised">
                  <div className="card-body  ">
                    <div className="card-title">
                      Total Number of Delivery Agents
                    </div>
                    <h1 style={{ textAlign: "center" }}>
                      {deliveryagent.length}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-10 mx-xl-auto ps-xl-10 "
        style={{ position: "relative", top: "130px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="card-title">Finances</div>
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
              &nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div>
                <Search searchName={searchName} />
              </div>
            </div>
            <div className="row" style={{ maxWidth: "100%" }}>
              {searchstate &&
                revenuedatalabels.map((item) => {
                  return (
                    <div style={{ width: "350px" }}>
                      <Pie
                        data={{
                          labels: [
                            "totalquantity",
                            "totalexempted",
                            "totalneworg",
                            "totaladditional",
                            "totalonline",
                          ],
                          datasets: [
                            {
                              label: item.productname,
                              data: [
                                item.totalquantity,
                                item.totalexempted,
                                item.totalneworg,
                                item.totaladditional,
                                item.totalonlinepay,
                              ],
                            },
                          ],
                        }}
                        height={1000}
                        width={2500}
                      />{" "}
                      <h6>{item.productname}</h6>
                    </div>
                  );
                })}
              {!searchstate &&
                searchbydate.map((item) => {
                  return (
                    <div style={{ width: "350px" }}>
                      <Pie
                        data={{
                          labels: [
                            "totalquantity",
                            "totalexempted",
                            "totalneworg",
                            "totaladditional",
                            "totalonline",
                          ],
                          datasets: [
                            {
                              label: item.productname,
                              data: [
                                item.totalquantity,
                                item.totalexempted,
                                item.totalneworg,
                                item.totaladditional,
                                item.totalonlinepay,
                              ],
                            },
                          ],
                        }}
                        height={1000}
                        width={2500}
                      />{" "}
                      <h6>{item.productname}</h6>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-10  mx-xl-auto ps-xl-10 "
        style={{ position: "relative", top: "200px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="card-title">Today's Product Sale Details</div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
              &nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div>
              

                <div>
                  {" "}
                  <input
                    id="date"
                    type="date"
                    value={new Date().toISOString().slice(0, 10)}
                    onChange={(e) => rhandle(e, "date")}

                  />

                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                    onClick={(e) => searchName1(e, productdate.date)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
                </div>
              </div>
            </div>
            {searchproductsale && (
              <Bar
                data={productsale}
                height={1000}
                width={2500}
                style={{ width: "400px" }}
              />
            )}
            {!searchproductsale && (
              <Bar
                data={productsale1}
                height={1000}
                width={2500}
                style={{ width: "400px" }}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="col-10  mx-xl-auto ps-xl-10 "
        style={{ position: "relative", top: "250px" }}
      >
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="card-title">Monthly Target</div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;{" "}
              &nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div>
                <div>
                  {" "}
                  <input
                    id="date"
                    type="month"
                    value={new Date().toISOString().slice(0, 7)} 

                    onChange={(e) => rhandle(e, "date")}
                  />
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    value="search"
                    className="btn btn-primary"
                    id="btn"
                    onClick={(e) => searchByMonth(e, productdate.date)}
                  >
                    <IoSearchOutline id="IoSearchOutline" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row" style={{ maxWidth: "100%" }}>
              {searchByMonth &&
                  producttargetbymonth &&

                producttargetbymonth.map((item) => {
                  return (
                    <div className="col-sm-6">
                      <CanvasJSChart
                        options={{
                          animationEnabled: true,
                          title: {
                            text: item.productname,
                          },
                          subtitles: [
                            {
                              text:
                                (item.totalofallsale * 100) / item.targetsale +
                                "%" +
                                item.productname,
                              verticalAlign: "center",
                            },
                          ],

                          data: [
                            {
                              type: "doughnut",
                              showInLegend: true,
                              indexLabel: "{name}: {y}",
                              yValueFormatString: "#,###'%'",
                              dataPoints: [
                                {
                                  name: item.productname,
                                  y:
                                    (item.totalofallsale * 100) /
                                    item.targetsale,
                                },
                              ],
                            },
                          ],
                        }}
                        height={1000}
                        width={2500}
                        style={{ width: "400px" }}
                        //                     options={{
                        //   responsive: true,
                        //   maintainAspectRatio: false,
                        //   tooltips: {
                        //     callbacks: {
                        //       label: function (tooltipItem, data) {
                        //         const dataset = data.datasets[tooltipItem.datasetIndex];
                        //         const total = dataset.data.reduce(
                        //           (previousValue, currentValue) => previousValue + currentValue
                        //         );
                        //         const currentValue = dataset.data[tooltipItem.index];
                        //         const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
                        //         return `${data.labels[tooltipItem.index]}: ${percentage}`;
                        //       },
                        //     },
                        //   },
                        // }}
                      />
                    </div>
                  );
                })}
              {!searchByMonth &&
                  targetbymonth &&

                targetbymonth.map((item) => {
                  return (
                    <div className="col-sm-6">
                      <CanvasJSChart
                        options={{
                          animationEnabled: true,
                          title: {
                            text: item.productname,
                          },
                          subtitles: [
                            {
                              text:
                                (item.totalofallsale * 100) / item.targetsale +
                                "%" +
                                item.productname,
                              verticalAlign: "center",
                            },
                          ],

                          data: [
                            {
                              type: "doughnut",
                              showInLegend: true,
                              indexLabel: "{name}: {y}",
                              yValueFormatString: "#,###'%'",
                              dataPoints: [
                                {
                                  name: item.productname,
                                  y:
                                    (item.totalofallsale * 100) /
                                    item.targetsale,
                                },
                              ],
                            },
                          ],
                        }}
                        height={1000}
                        width={2500}
                        style={{ width: "400px" }}
                        //                     options={{
                        //   responsive: true,
                        //   maintainAspectRatio: false,
                        //   tooltips: {
                        //     callbacks: {
                        //       label: function (tooltipItem, data) {
                        //         const dataset = data.datasets[tooltipItem.datasetIndex];
                        //         const total = dataset.data.reduce(
                        //           (previousValue, currentValue) => previousValue + currentValue
                        //         );
                        //         const currentValue = dataset.data[tooltipItem.index];
                        //         const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
                        //         return `${data.labels[tooltipItem.index]}: ${percentage}`;
                        //       },
                        //     },
                        //   },
                        // }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
  <ConfirmationModal
    message="End transaction not completed. Proceed to complete the end transaction?"
    onCancel={handleCancel}
    onConfirm={handleConfirm}
  />
)}
      <Monthlytarget isOpen={ismonthlyModalOpen} onRequestClose={handleCloseModal} />

    </div>
  );
}
export default Productanalytics;
