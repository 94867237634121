import React, { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
//import { token } from "../api/baseurl";
import "./Loader.css";
import "./Spinner.css";
function Denomination() {
  const token = JSON.parse(localStorage.getItem("data")).token;

  <div className="card card-raised mb-5"></div>;
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading1, setLoading1] = useState(null);

  const [denom, setDenom] = useState([]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [denomination, setDenomination] = useState({
    denominations: "",
    status: "",
  });

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setDenomination({ ...denomination, [field]: actualValue });
  };
  function onClickhandler(e, id) {
    setLoading1(id);
    const checked = e.target.checked;

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .get(`/api/denominations/denominationUpdate/id=${id}/value=${checked}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLoading1(null);
        toast.success("denomination  status updated");
      });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post("/api/denominations/create", denomination, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        //    navigate("/users/");
        toast.success("New Denomination Added");
        setLoading(false);
        window.location.reload();
        getData();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error("you don't have a permission");
        }
        //   navigate("/users/Deviceusermapping");

        setLoading(false);
      });
  };

  const getData = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const uid = JSON.parse(localStorage.getItem("data")).userid;

    const { data } = await axios
      .get(`/api/denominations`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setDenom(res.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
          setLoading(false);
        } else {
          toast.error(error.response.data.message);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div
            className="col-9 mx-xl-auto ps-xl-9"
            style={{ position: "relative", left: "100px" }}
          >
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="card-title">Denomination</div>

                <br></br>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label for="region"> Denomination</label>
                      <input
                        className="form-control"
                        id="denominations"
                        onChange={(e) => handleChange(e, "denominations")}
                      />
                    </div>
                  </div>

                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        className="col-9 mx-xl-auto ps-xl-9"
        style={{ position: "relative", left: "100px", top: "60px" }}
      >
        {" "}
        <div className="card card-raised ">
          <div className="card-body ">
            <div className="card-title"> Denomination Details</div>
            <table
              className="table table-bordered "
              style={{ position: "relative" }}
            >
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "#6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Denominations</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {denom.map((denom) => {
                  return (
                    <tr
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <th>{denom.denominations}</th>
                      <td>
                        <div className="form-check form-switch">
                          {loading1 == denom.id && (
                            <div
                              className="loader"
                              style={{
                                position: "relative",
                                left: "75px",
                                top: "10px",
                              }}
                            ></div>
                          )}{" "}
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={denom.status}
                            onClick={(e) => onClickhandler(e, denom.id)}
                            value={denom.id}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}{" "}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Denomination;
