import React, { useEffect, useState, useReducer } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import OpenGoogleMap from "./OpenGoogleMap";
import Dagentandvehiclemapping from "./Dagentandvehiclemapping";
import VehicleeditsModalPopup from "./Vehicleedit";
import AddVehiclesmodalpopup from "./Addvehicle";
 
 
 
import "./Loader.css";
function Vehicledetails() {
  const [vehicle, setVehicle] = useState([]);
  const url = "/api/vehicle";
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [api, setapi] = useState({});
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [isVehicleEditModalOpen, setIsVehicleEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
 
  const onClickhandler = async (e, vid, vtruckinfo, vlicenceNo) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(vid);
    const vehicles = await axios({
      url: "/api/vehicle",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${vid}`,
        truckinfo: `${vtruckinfo}`,
        licenceNo: `${vlicenceNo}`,
        orgid: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
      },
    }).then((res) => {
      toast.success("deleted successfully");
      getData();
      setLoading(null);
    });
  };
 
  function onClickviewvehicle(e, vehicletruckinfo, latitude, longitude) {
    return <OpenGoogleMap latitude={22.3511148} longitude={78.6677428} />;
  }
  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
 
    try {
      setLoader(true);
 
      // Fetch location data
      const locationData = await axios.get(`/api/Locationtrack/userid=${JSON.parse(localStorage.getItem("data")).userid}`, {
                                           
        headers: { Authorization: `Bearer ${token}` },
      });
     
      setapi(locationData.data);
      console.log(locationData.data);
 
      console.log(locationData.data);
      console.log(locationData.data.url);
 
      // Fetch vehicle data based on orgid
      const vehicles = await axios.get(
        `/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
 
      setLoader(false);
      setVehicle(vehicles.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.success(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
 
      setLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, [reducerValue]);
 
  const openAddMemberModal = (vid, truckinfo) => {
    setSelectedVehicle({ vid, truckinfo });
    setIsAddMemberModalOpen(true);
  };
 
  const closeAddMemberModal = () => {
    setSelectedVehicle(null);
    setIsAddMemberModalOpen(false);
  };
 
  function onClickviewvehicle(e, vtruckinfo) {
    navigate(`/users/vehicledocuments/${vtruckinfo}`);
  }
  function onClickviewlocation(e,truckinfo,deviceid) {
    navigate(`/users/OpenGoogleMap/${truckinfo}/${deviceid }`);
  }
    const Vehicleedit = (id) => {
 
    setIsVehicleEditModalOpen(true);
    setSelectedVehicleId(id);
 
 
  }
  const closeVehicleEditModal = () => {
    setSelectedVehicleId(null);
 
    setIsVehicleEditModalOpen(false);
    getData();
 
  };
  const handleaddvehicle = () => {
    setIsModalOpen(true);
  };
 
  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  }
 
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "750px", top: "75px" }}
        ></div>
      )}
      <div className="col-12  mx-xl-auto ps-xl-10 ">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="card-title">Vehicle Details</div>
              {""}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 
              <Link>
              <button
                 className="btn btn-primary"
                onClick={() => window.open(api.url, '_blank')}
                   >
               View all
              </button>          
    </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button className="btn btn-primary float-end" onClick={handleaddvehicle}>
 
                ADD</button>
            </div>
            <br />
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: " #6200ea",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <th>Vehicle Number</th>
                  <th>License</th>
                  <th>Device</th>
                  <th>Add Members</th>
                  <th>Manage Vehicle</th>
                  <th>View Vehicle</th>
                  <th>Edit</th>
                  <th>Delete Vehicle</th>
                </tr>
              </thead>
              <tbody>
              {vehicle.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center">
                No Data Available
              </td>
            </tr>
          ) : (
                vehicle.map((vehicle) => {
                  return (
                    <tr key={vehicle.id}>
                      <td>{vehicle.truckinfo}</td>
                      <td>{vehicle.licenceNo}</td>
                      <td>{vehicle.deviceid}</td>
 
                      <td>
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              openAddMemberModal(vehicle.id, vehicle.truckinfo)
                            }
                          >
                            Add Agents
                          </button>
                        </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={(e) =>
                            onClickviewvehicle(e, vehicle.truckinfo)
                          }
                        >
                          Manage Vehicle
                        </button>
                      </td>
                      <td>
                      {vehicle.deviceid ? (
                 <button
                 className="btn btn-primary"
               onClick={(e) => onClickviewlocation(e, vehicle.truckinfo, vehicle.deviceid)}
                >
                View Vehicle
              </button>
                ) : (
               <p  style={{ color: "red" }}>Device not added</p>
                )}
                      </td>
                      <td>
                        <button
                          onClick={() => Vehicleedit( vehicle.id)}
                          style={{
                            border: "none",
                            background: "white",
                            size: "150px",
                          }}
                        >
                          <BiEditAlt />
                        </button>
                      </td>
                      <td>
                        <button
                          style={{
                            border: "none",
                            background: "white",
                            color: "grey",
                            position: "relative",
                            left: "20px",
                            fontSize: "30px",
                          }}
                          onClick={(e) => {
                            onClickhandler(
                              e,
                              vehicle.id,
                              vehicle.truckinfo,
                              vehicle.licenceNo
                            );
                          }}
                        >
                          <MdDeleteForever />
                        </button>{" "}
                        {loading == vehicle.id && (
                          <div
                            className="loader"
                            style={{
                              position: "relative",
                              left: "40px",
                              top: "8px",
                            }}
                          ></div>
                        )}{" "}
                      </td>
                    </tr>
                  );
                }))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isAddMemberModalOpen && selectedVehicle && (
        <Dagentandvehiclemapping
          isOpen={isAddMemberModalOpen}
          onRequestClose={closeAddMemberModal}
          params={{ vid: selectedVehicle.vid, truckinfo: selectedVehicle.truckinfo }}
        />
      )}
       <VehicleeditsModalPopup
        isOpen={isVehicleEditModalOpen}
        onRequestClose={closeVehicleEditModal}
        params={{ id: selectedVehicleId }} // Pass id as a parameter
        />
             <AddVehiclesmodalpopup isOpen={isModalOpen} onRequestClose={handleCloseModal} />
    </div>
  );
}
 
export default Vehicledetails;