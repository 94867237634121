
import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  // Import Modal and Button from react-bootstrap

import axios from "../api/baseurl";

const PriceHistoryModal = ({ onClose, initialPriceHistory, productId }) => {
  const [localPriceHistory, setLocalPriceHistory] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const token = JSON.parse(localStorage.getItem("data")).token;
          const response = await axios.get(`/api/ProductPrice/productid=${productId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setLocalPriceHistory(response.data);
         
        } catch (error) {
          console.error("Error fetching price history:", error);
        }
      };
  
      fetchData();
  }, [productId]);
  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Price History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(localPriceHistory) && localPriceHistory.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.date}</td>
                <td>{entry.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


export default PriceHistoryModal;
