import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";
import {FiInfo } from "react-icons/fi";  
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import tr from "date-fns/esm/locale/tr/index.js";
const TransactionIncoming = ({ isOpen, onRequestClose,transactionInDetails }) => {

  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [additionalModalOpen, setAdditionalModalOpen] = useState(false);
  const [onlineModalOpen, setOnlineModalOpen] = useState(false);
  const [exemptedModalOpen, setExemptedModalOpen] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  const [additionalDetails, setAdditionalDetails] = useState([]);
  const [onlineDetails, setOnlineDetails] = useState([]);
  const [exemptedDetails, setExemptedDetails] = useState([]);
  
  const openExemptedModal = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/ExemptedDetails/transactionid=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setExemptedDetails(response.data);
      setExemptedModalOpen(true);
    } catch (error) {
     
    }
  };
  
  const closeExemptedModal = () => {
    setExemptedModalOpen(false);
  };
  const openAdditionalModal = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/additionaldetails/transactionid=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAdditionalDetails(response.data);
      setAdditionalModalOpen(true);
    } catch (error) {
     
    }
  };
  
  const closeAdditionalModal = () => {
    setAdditionalModalOpen(false);
   
  };
  const openOnlineModal = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/OnlineDetail/transactionid=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOnlineDetails(response.data);
      setOnlineModalOpen(true);
    } catch (error) {
    
    }
  };
  
  const closeOnlineModal = () => {
    setOnlineModalOpen(false);
  };
  useEffect(() => {
  
  }, []);

  return (
    <>
    <Modal show={isOpen} onHide={onRequestClose} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Transactions Incoming Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
        {transactionInDetails.length > 0 && (
  <div>
    <label style={{ fontSize: '1em', color: "#000000" }}>Vehicle Id: {transactionInDetails[0].vehicle}</label>
    <br />
    <label style={{ fontSize: '1em', color: "#000000" }}>Product Name: {transactionInDetails[0].productname}</label>
    <br />
    <label style={{ fontSize: '1em', color: "#000000" }}>Date: {transactionInDetails[0].date}</label>
    <br /><br /> {/* Add some space between each transaction detail */}
  </div>
)}
      </div>
        <table className="table table-bordered">
          <thead>
            <tr>  
                      <th>Time</th>      
                      <th>Sales</th>                
                        <th>New Connection</th>
                        <th>Additional</th>
                        <th>Onlinepay</th>
                        <th>Exempted</th>
                        <th>Returned Product</th>
                        <th>Transfer</th>
                        <th>Approval Status</th>
                </tr>
              </thead>
              <tbody>
              {transactionInDetails.map((ttin, index) => (
              <tr key={index}>
                     
                              <th>{ttin.time}</th>
                              <th>{ttin.quantity}</th>
                              <th>{ttin.neworg}</th>
                              <th>{ttin.additional}
                              <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() =>openAdditionalModal(ttin.id)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                               </span>
                                </th>
                              <th>{ttin.onlinepay}
                              <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() =>openOnlineModal(ttin.id)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                            </span>
                              </th>
                              <th>{ttin.exempted}
                              <span style={{ marginLeft: '40px' }}><FiInfo 
                              onClick={() => openExemptedModal(ttin.id)}
                               style={{
                                 position: "relative"
                                
                               }}/>
                         </span>
                         </th>
                              <th>{ttin.returnedproduct}</th>
                              <th>{ttin.transfer}</th>
                              <th>{ttin.approvalstatus}</th>
                          </tr>
            ))}
                </tbody>
              </table>
              </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>
      {/* Additional Modal */}
      <Modal show={additionalModalOpen} onHide={closeAdditionalModal} centered size="lg">
        {/* Content for Online Modal */}
        <Modal.Header closeButton>
          <Modal.Title>Adittional Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
        {additionalDetails.length > 0 && (
  <div>
    <label style={{ fontSize: '1em', color: "#000000" }}>Product Name: {additionalDetails[0].productName}</label>
    <br />
    <label style={{ fontSize: '1em', color: "#000000" }}>Date: {additionalDetails[0].date}</label>
    <br />
  </div>
)}
      </div>
  
        <table className="table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Customer Number</th>
              <th>Quantity</th>
              <th>Phone Number</th>

            </tr>
          </thead>
          <tbody>
            {additionalDetails.map((entry, index) => (
              <tr key={index}>
                <td>{entry.customerName}</td>
                <td>{entry.customerNumber}</td>
                <td>{entry.quantity}</td>
                <td>{entry.phoneNumber}</td> 
              </tr>
            ))}
          </tbody>
        </table>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={closeAdditionalModal}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>
      {/* Online Modal */}
      <Modal show={onlineModalOpen} onHide={closeOnlineModal} centered size="lg">
        {/* Content for Online Modal */}
        <Modal.Header closeButton>
          <Modal.Title>Online Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
        {onlineDetails.length > 0 && (
  <div>
    <label style={{ fontSize: '1em', color: "#000000" }}>Product Name: {onlineDetails[0].productname}</label>
    <br />
    <label style={{ fontSize: '1em', color: "#000000" }}>Date: {onlineDetails[0].date}</label>
    <br />
  </div>
)}
      </div>
        <table className="table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Customer Number</th>
              <th>Order Number</th>
              <th>Quantity</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {onlineDetails.map((entry, index) => (
              <tr key={index}>
                <td>{entry.customername}</td>
                <td>{entry.customernumber}</td>
                <td>{entry.ordernumber}</td>
                <td>{entry.quantity}</td>
                <td>{entry.phonenumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={closeOnlineModal}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>
      {/* Exempted Modal */}
      <Modal show={exemptedModalOpen} onHide={closeExemptedModal} centered size="lg">
        {/* Content for Exempted Modal */}
        <Modal.Header closeButton>
          <Modal.Title>Exempted Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div>
        {exemptedDetails.length > 0 && (
  <div>
    <label style={{ fontSize: '1em', color: "#000000" }}>Product Name: {exemptedDetails[0].productname}</label>
    <br />
    <label style={{ fontSize: '1em', color: "#000000" }}>Date: {exemptedDetails[0].date}</label>
    <br />
  </div>
)}
      </div>
        <table className="table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Category Name</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {exemptedDetails.map((entry, index) => (
              <tr key={index}>
                <td>{entry.customername}</td>
                <td>{entry.categoryname}</td>
                <td>{entry.deliveredqty}</td>
                <td>{entry.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={closeExemptedModal}>
          Close
        </Button>
      </Modal.Footer>
      </Modal>
      </>
        );
        
      }
export default TransactionIncoming;
