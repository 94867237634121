import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/baseurl";
import AdminDashboard from "./admindashboard";
import { Link, useNavigate } from "react-router-dom";
import "./Spinner.css";
import { Modal,Button } from "react-bootstrap";
const AddVehiclesmodalpopup= ({ isOpen, onRequestClose }) => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  let day = date.getDate();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [addvehicle, setAddvehicle] = useState({
    truckinfo: "",
    licenceNo: "",
    deviceid: "",
    orgid: JSON.parse(localStorage.getItem("data")).orgcode,
  });
  const [loading, setLoading] = useState(false);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [api, setapi] = useState([]);
  const [api1, setapi1] = useState([]);

  const [associatedDevices, setAssociatedDevices] = useState([]);



  const url = "/api/vehicle/create";
  const deviceApiUrl = process.env.REACT_APP_CONVOIZ_URL;


  const navigate = useNavigate();

    const fetchData = async () => {
     
        const token = JSON.parse(localStorage.getItem("data")).token;
  
        // Fetch location track data
        const response1 = await axios.get(`/api/Locationtrack/userid=${JSON.parse(localStorage.getItem("data")).userid}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setapi(response1.data);
  
        // Fetch vehicles data
        const vehicleresponse = await axios.get(`/api/vehicle/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setapi1(vehicleresponse.data);
        
        // Fetch device data
        const deviceResponse = await axios.get(`${deviceApiUrl}/api/devicevehicle`, {
          headers: {
            'AppName': `${response1.data.appname}`,
            'APIKey': `${response1.data.apikey}`,
          },
        });
      
      
      setDeviceOptions(deviceResponse.data);

      const availableDevices = deviceResponse.data.filter(device => {
        return !vehicleresponse.data.some(vehicle => vehicle?.deviceid == device?.deviceid);
      });

      console.log(availableDevices);
      setAssociatedDevices(vehicleresponse.data);

      setDeviceOptions(availableDevices);
    
  };

  useEffect(() => {

fetchData();
  }, []);
  
  
  
  
  useEffect(() => {


  }, [deviceOptions, associatedDevices]);

  function rsubmit(e) {
    e.preventDefault();
    if (!addvehicle.truckinfo || !addvehicle.licenceNo) {
      toast.error("Please fill out all required fields");
      return;
    }
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios

      .post(
        url,
        addvehicle,

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        toast.success("vehicle added successfully");
        fetchData();

        navigate("/users/vehicledetail");
        onRequestClose();

      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("An error occurred while adding the vehicle");
  
      })
      .finally(() => {
        // Clear the input fields
        setAddvehicle({
          truckinfo: "",
          licenceNo: "",
          deviceid: ""
        });
        fetchData();


      });
  }
  function rhandle(e) {
    const { id, value } = e.target;
    setAddvehicle(prevAddVehicle => ({
      ...prevAddVehicle,
      [id]: id === "deviceid" ? value : value.trim(),
    }));
  }
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add vehicle</Modal.Title>
      </Modal.Header>
      <Modal.Body>

             


            



            <br />


            <div className="col-xl-10 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div className="card-body p-4 ">
                  <form onSubmit={(e) => rsubmit(e)}>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="truckinfo">Truckinfo</label>
                        <input
                          className="form-control"
                          id="truckinfo"
                          type="text"
                          placeholder="truckinfo"
                          value={addvehicle.truckinfo}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label for="licenceNo">License</label>
                        <input
                          className="form-control"
                          id="licenceNo"
                          type="text"
                          placeholder="licenceNo"
                          value={addvehicle.licenceNo}
                          onChange={(e) => rhandle(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-floating-sm mb-2">
                        <label htmlFor="deviceid">Device Id</label>
                        <select
                          className="form-select"
                          id="deviceid"
                          value={addvehicle.deviceid}
                          onChange={(e) => rhandle(e)}
                        >
                          <option value="">Select Device</option>
                          {deviceOptions.map((device) => (
                            <option key={device.deviceid} value={device.deviceid}>
                              {device.deviceid}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                    />

&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                  </form>

                </div>
              </div>
            </div>
            </Modal.Body>
      <Modal.Footer>
       
      </Modal.Footer>
    </Modal>
  );
};

export default AddVehiclesmodalpopup;
