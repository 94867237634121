import React, { useEffect, useState } from "react";
import Dash from "./dash";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import "./Spinner.css";
import SubModule from "./Subscriptionmodule";

function Supersubscriptiondetails() {
  const [checkedmap, setCheckedmap] = useState(false);
  const [sub, setSub] = useState([]);
  const [subfeatur, setSubfeatur] = useState([]);
  const [subfeaturmodel, setSubfeaturmodel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = JSON.parse(localStorage.getItem("data"));
  const url = "/api/subscription-model";

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    setLoading(true);
    const sf = await axios.get("/api/subscription-features");

    console.log(sf.data);

    setSubfeatur(sf.data);

    const sfmm = await axios.get("/api/SubmodelandfeatureDo", {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log(sfmm.data.features);

    setSubfeaturmodel(sfmm.data);
    const prof1 = await axios.get(url);

    console.log(prof1.data);

    setSub(prof1.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
   
  }, []);
  const handleAddProduct = () => {
    setIsModalOpen(true);
    


  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div id="layoutDrawer_content">
      {/* {loading && <div className="loading"></div>} */}
      <main>
        <div className=" col-8 mx-xl-auto ps-xl-6">
          <div className="card card-raised">
            <div className="card-body ">
              <div className="container-xl p-6">
                <div className="text-center mb-5">
                  <h3>Subscription Details</h3>
                </div>
                <button className="btn btn-primary" style={{marginLeft:"70%"}} onClick={handleAddProduct}>
               ADD
            </button>
                <div className="row" style={{ maxWidth: "100%" }}>
                  {subfeaturmodel.map((subscription) => {
                    return (
                      <div className="col-sm-3">
                        <div className="">
                          <div className="card card-raised h-100">
                            <div className="card-body">
                              <h2 className="overline">
                                {subscription.submodel}
                              </h2>
                              <div className="d-flex align-items-center mb-3">
                                <div className="display-5">
                                  {subscription.cost}
                                </div>
                                <div className="text-muted ms-2">
                                  {subscription.region == "INDIA" && "INR"}
                                  {subscription.region == "USA" && "USD"}
                                  &ensp;&nbsp;{subscription.region}
                                </div>
                              </div>
                              <p>
                                For most businesses that want to optimize web
                                queries
                              </p>

                              {subscription.features .map((sbf) => {
                                return (
                                  <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-6">
                                      <div className="d-flex align-items-center">
                                        <i className="material-icons icon-xs me-4">
                                          check
                                        </i>
                                        {sbf.features}
                                      </div>
                                    </li>
                                  </ul>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <SubModule isOpen={isModalOpen} onRequestClose={handleCloseModal} />

    </div>
  );
}

export default Supersubscriptiondetails;
