import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import { useEffect } from "react";

const CreaterecordModal = ({ isOpen, onRequestClose }) => {
  
 
  const [newProduct, setNewProduct] = useState({
    sqcId: "",
    productName: "",
    productCode: "",
    SerialNumber: "",
    sealIntact: "",
    date:"" ,   
    orgcode: "",
    grossWeight: "",
    tareWeight: "",
    remarks: "",
    productReturned: "",
    ervNumber: "",
    orgcode: JSON.parse(localStorage.getItem("data")).orgcode,

    ervDate: "",
    // actualWeight: "",
    // difference: "",
    // approvalStatus:"",




    
  });

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isOpen) {
          const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
          const token = JSON.parse(localStorage.getItem("data")).token;
          
          // Fetch SQC data
          const date = new Date();
          date.setDate(date.getDate() );
          let day = date.getDate();
          let month = (date.getMonth() + 1).toString().padStart(2, "0");
          let year = date.getFullYear();
          const currentDate = `${year}-${month}-${day}`;
          
    
          const urlsqc = `/api/sqcform/orgcode=${orgCode}/date=${currentDate}`;
    
          const sqcResponse = await axios.get(urlsqc, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          console.log("Response from SQC API:", sqcResponse.data);
          const sortedData = sqcResponse.data.sort((a, b) => b.id - a.id);
        const { id, ...restData } = sortedData[0]; // Access the first element of the sorted array
        console.log("SQC ID from API:", id); // Log the SQC ID
        setNewProduct(prevState => ({
          ...prevState,
          sqcId: id,
          ...restData,
          date: currentDate, // Setting the date after fetching it
        }));
  
          // Fetch product data
          const productResponse = await axios.get(
            `/api/product/productstatus=true/orgcode=${orgCode}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          console.log("Response from Product API:", productResponse.data);
          setProducts(productResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        handleError(error);
      }
    };
    
  
    fetchData();
  }, [isOpen]);

  const url = "/api/sqcleveldetails/create";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    try {
      const response = await axios.post(url, [newProduct], {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success(" created successfully");
      onRequestClose(); 
      setNewProduct({
    sqcId: "",
    productName: "",
    productCode: "",
    serialNumber: "",
    sealIntact: "",
    date: "",   
    orgcode: "",
    grossWeight: "",
    tareWeight: "",
    remarks: "",
    productReturned: "",
    ervNumber: "",

    ervDate: "",
    actualWeight: "",
    difference: "",
    approvalStatus:"",
        
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    // Handle error
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setNewProduct(prevState => ({
      ...prevState,
      [id]: value
    }));
  };
  const handleSealIntactChange = (value) => {
    setNewProduct((prevState) => ({
      ...prevState,
      sealIntact: value,
    }));
  };
  const handleReturnedProductChange = (value) => {
    setNewProduct((prevState) => ({
      ...prevState,
      productReturned: value,
    }));
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Record</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4">
              <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="sqcId">SQC Id</label>
                    <input
                      className="form-control"
                      id="sqcId"
                      type="text"
                      placeholder="sqcId"
                      value={newProduct.sqcId}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>
                
                <div className="row mb-2">
  <div className="form-floating-sm">
    <label htmlFor="productName">Product Name</label>
    <select
      className="form-select"
      id="productName"
      value={newProduct.productName}
      onChange={handleChange}
    >
      <option value="">Select Product Name</option>
      {products.map((product, index) => (
        <option key={index} value={product.productname}>
          {product.productname}
        </option>
      ))}
    </select>
  </div>
</div>
                
<div className="row mb-2">
  <div className="form-floating-sm">
    <label htmlFor="productCode">Product Code</label>
    <select
      className="form-select"
      id="productCode"
      value={newProduct.productCode}
      onChange={handleChange}
    >
      <option value="">Select Product Code</option>
      {products.map((product, index) => (
        <option key={index} value={product.productcode}>
          {product.productcode}
        </option>
      ))}
    </select>
  </div>
</div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="serialNumber">SerialNumber</label>
                    <input
                      className="form-control"
                      id="serialNumber"
                      type="text"
                      placeholder="serialNumber"
                      value={newProduct.serialNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="sealIntact">sealIntact</label>
                    <div className="d-flex">
      <Button
        className={`btn ${newProduct.sealIntact === "Yes" ? "btn-success" : "btn-outline-success"} text-dark`}
        onClick={() => handleSealIntactChange("Yes")}
      >
        Yes
      </Button>
      <Button
        className={`btn ${newProduct.sealIntact === "No" ? "btn-danger" : "btn-outline-danger"} ms-2 text-dark`}
        onClick={() => handleSealIntactChange("No")}
      >
        No
      </Button>
    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="date">date</label>
                    <input
                      className="form-control"
                      id="date"
                      type="date"
                      placeholder="Product Code"
                      value={newProduct.date}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="grossWeight">Gross weight</label>
                    <input
                      className="form-control"
                      id="grossWeight"
                      type="text"
                      placeholder="grossWeight"
                      value={newProduct.grossWeight}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="tareWeight">Tare Weight</label>
                    <input
                      className="form-control"
                      id="tareWeight"
                      type="text"
                      placeholder="tareWeight"
                      value={newProduct.tareWeight}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="remarks">remarks</label>
                    <input
                      className="form-control"
                      id="remarks"
                      type="text"
                      placeholder="remarks"
                      value={newProduct.remarks}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="productReturned">Returned product</label>
                    <div className="d-flex">
      <Button
        className={`btn ${newProduct.productReturned === "Yes" ? "btn-success" : "btn-outline-success"}`}
        onClick={() => handleReturnedProductChange("Yes")}
      >
        Yes
      </Button>
      <Button
        className={`btn ${newProduct.productReturned === "No" ? "btn-danger" : "btn-outline-danger"} ms-2`}
        onClick={() => handleReturnedProductChange("No")}
      >
        No
      </Button>
    </div>
                  </div>
                </div>
                {newProduct.productReturned === "Yes" && (
  <div>
    <div className="row mb-2">
      <div className="form-floating-sm">
        <label htmlFor="ervNumber">ervNumber</label>
        <input
          className="form-control"
          id="ervNumber"
          type="text"
          placeholder="ervNumber"
          value={newProduct.ervNumber}
          onChange={handleChange}
        />
      </div>
    </div>
    <div className="row mb-2">
      <div className="form-floating-sm">
        <label htmlFor="ervDate">ervDate</label>
        <input
          className="form-control"
          id="ervDate"
          type="date"
          placeholder="ervDate"
          value={newProduct.ervDate}
          onChange={handleChange}
        />
      </div>
    </div>
  </div>
)}
                {/* <div className="row mb-2">
                  <div className="form-floating-sm">
                    <label htmlFor="actualWeight">actualWeight</label>
                    <input
                      className="form-control"
                      id="actualWeight"
                      type="text"
                      placeholder="actualWeight"
                      value={newProduct.actualWeight}
                      onChange={handleChange}
                    />
                  </div>
                </div> */}
                
                
               
                <div className="row mt-4">
                  <div className="col">
                    <Button variant="primary" type="submit" disabled={loading}>Save</Button>
                    <Button variant="secondary" onClick={onRequestClose} className="ms-2">Close</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreaterecordModal;
