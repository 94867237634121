import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';  
import axios from "../api/baseurl";

const AdditionalModalPopup = ({ isOpen, onRequestClose,additionalDetails }) => {
    const [localPriceHistory, setLocalPriceHistory] = useState([]);
  
    useEffect(() => {
        const fetchData = async () => {
          try {
            const token = JSON.parse(localStorage.getItem("data")).token;
            const currentDate = new Date().toISOString().split('T')[0];
            const productNameResponse = await axios.get(
              `/api/ReturntransactionDo/orgid=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            
            if (productNameResponse.data && productNameResponse.data.length > 0) {
              const productName = productNameResponse.data[0].productname;
              const response1 = await axios.get(
                `/api/additionaldetails/orgcode=${JSON.parse(localStorage.getItem("data")).orgcode}/date=${currentDate}/productname=${productName}`,
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
              setLocalPriceHistory(response1.data);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
      }, []);

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Additional Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
    {additionalDetails.length > 0 && (
  <div>
     <label style={{ fontSize: '1em', color: "#000000" }}>Date: {additionalDetails[0].date}</label>
     <br/>
    <label style={{ fontSize: '1em', color: "#000000" }}>Vehicle Id: {additionalDetails[0].deliveredVehicleid}</label>
    <br/>
    <label style={{ fontSize: '1em', color: "#000000" }}>Product Name: {additionalDetails[0].productName}</label>
     </div>
    )}
   
      </div>
        <table className="table">
          <thead>
            <tr>
              <th> Customer Name</th>
              <th>Customer Number</th>
              <th>Quantity</th>
              <th>Phone Number</th>     
            </tr>
          </thead>
          <tbody>
            {additionalDetails.map((entry, index) => (
              <tr key={index}>
                <td>{entry.customerName}</td>
                <td>{entry.customerNumber}</td>
                <td>{entry.quantity}</td>
                <td>{entry.phoneNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AdditionalModalPopup;