import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import ManagerDashboard from "./managerdashboard";
import ManagerSidebar from "./managerSidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Loader.css";
import { BiEditAlt } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import "./Spinner.css";
function Exempteddelivery() {
  const [Exempteddelivery, setExempteddelivery] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const date = new Date();

  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const navigate = useNavigate();

  const url = `/api/ExemptedDetails/orgcode=${
    JSON.parse(localStorage.getItem("data")).orgcode
  }/date=${currentDate}`;
  console.log("API URL:", url);


  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    await axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setExempteddelivery(res.data);
        setLoader(false);
        console.log(res.data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
          setLoader(false);
        } else {
          toast.error(error.response.data.message);
          setLoader(false);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  function exemptededit(e, id) {
    navigate(`/users/exemptededit/${id}`);
  }
  function rsubmit(e, id, astatus) {
    e.preventDefault();
    setLoading(true);
    // if(!sub.status)

    //{

    //setSub(true)

    // }

    const token = JSON.parse(localStorage.getItem("data")).token;

    axios
      .post(
        "/api/ExemptedDetails/update",
        {
          id: id,
          status: astatus,
        },

        { headers: { Authorization: `Bearer ${token}` } }
      )

      .then((response) => {
        console.log(response.data);
        setLoading(false);
        getData();
      })

      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div className="attendance-page" style={{marginTop:'100px'}}>
    <div className="dashboard-container">
        <AdminDashboard />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {loader && (
        <div
          className="loader"
          style={{ position: "absolute", left: "750px", top: "75px" }}
        ></div>
      )}
            <div className="content-container">

      <div className="col-12  mx-xl-auto ps-xl-30">
        <div className="card card-raised">
          <div className="card-body  ">
            <div className="d-flex">
              <div className="card-title" > Exempted Delivery Details</div>
              {""}
            </div>

            <br />
            <Link to="/users/AddExempteddelivery">
                  <button className="btn btn-primary float-end">ADD</button>
                </Link>
            <table className="table table-bordered ">
              <thead>
                <tr
                  style={{
                    fontSize: "17px",
                    backgroundColor: "blue",
                    color: "white",
                  }}
                >
                  <th>Id</th>
                  <th>Customerid</th>
                  <th>Customername</th>
                 
                   <th>Quantity</th>
                 
                  <th>Productname</th>

                  <th>Productcode</th>
                  <th>Categoryname</th>
                  {/* <th>categoryid</th> */}
                  <th>Total</th>

                  {/* <th>Edit</th> */}
                  <th>Verify</th>

                </tr>
              </thead>
              <tbody>
                {Exempteddelivery.map((exempted) => {
                  return (
                    <tr key={exempted.id}>
                      <td>{exempted.id}</td>
                      <td>{exempted.customerid}</td>
                      <td>{exempted.customername}</td>
                      {/* <td>{exempted.date}</td> */}

                      <td>{exempted.deliveredqty}</td>
                      
                      {/* <td>{exempted.orgcode}</td> */}
                      <td>{exempted.productname}</td>

                      <td>{exempted.productcode}</td>
                      <td>{exempted.categoryname }</td>
                      {/* <td>{exempted.categoryid}</td> */}
                      <td>{exempted.total}</td>







                      {/* <td>
                        <button
                          onClick={(e) => exemptededit(e, exempted.id)}
                          style={{
                            border: "none",
                            background: "white",
                            size: "150px",
                          }}
                        >
                          <BiEditAlt />
                        </button>
                      </td> */}

                      {exempted.status == false ? (
                        <td id="icon" value={exempted.status}>
                          &nbsp; Verify&nbsp;
                          <BsCheckCircle
                            className="icon"
                            id="approval.approvalstatus"
                            style={{ backgroundColor: "green" }}
                            value="true"
                            onClick={(e) =>
                              rsubmit(
                                e,
                                exempted.id,

                                true
                              )
                            }
                          />
                          &ensp;
                          <BsXCircle
                            className="icon"
                            value="Notverified"
                            style={{ backgroundColor: "red" }}
                            id="exempted.status"
                            onClick={(e) =>
                              rsubmit(
                                e,
                                exempted.id,

                                false,
                                exempted.orgcode
                              )
                            }
                          />
                        </td>
                      ) : (
                        <td id="icon" value={exempted.status}>
                          {" "}
                          <div className="d-flex">
                            {" "}
                            <AiOutlineCheck style={{ color: "green" }} />
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}{" "}
              </tbody>
            </table>
          </div>
        </div>{" "}
      </div>
      </div>
    </div>
  );
}

export default Exempteddelivery;
