import React, { useState, useEffect } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";

const MonthlyTarget = ({ isOpen, onRequestClose }) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(false);
  const [productTargets, setProductTargets] = useState({}); 
  const url = "/api/productmonthlytarget/create";

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    const currentDate = new Date().toISOString().split('T')[0];
    setLoader(true);

    try {
      const url = `/api/product/orgcode=${orgcode}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProduct(response.data);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 404) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An error occurred while fetching data");
    }
    setLoader(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    rsubmit();
  };

  const rsubmit = () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    const targetsArray = Object.keys(productTargets).map((productId) => ({
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
      date: new Date().toISOString().split('T')[0],
      productname: product.find(p => p.productcode === productId).productname,
      productcode: productId,
      target: productTargets[productId]
    }));

    axios.post(
      url,
      targetsArray,
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((response) => {
        console.log(response);
        setLoading(false);
        toast.success("Monthly targets updated successfully");
        onRequestClose();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const rhandle = (e, field, productId) => {
    const { value } = e.target;
    setProductTargets((prevTargets) => ({
      ...prevTargets,
      [productId]: value
    }));
  };

  
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Monthly Target</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Code</th>
                <th>Target</th>
              </tr>
            </thead>
            <tbody>
              {product.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    No Data Available
                  </td>
                </tr>
              ) : (
                product.map((product, index) => (
                  <tr key={product.id}>
                    <td>{product.productname}</td>
                    <td>{product.productcode}</td>
                    <td>
                      <input
                        id={`target-${product.productcode}`} // Unique identifier using productid
                        type="number"
                        placeholder="target"
                        style={{ border: "none" }}
                        value={product.target}
                        onChange={(e) =>
                          rhandle(e, "target", product.productcode) // Pass productid as index
                        }
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
         
          <Button variant="primary" type="submit">
            Save
          </Button>
          &nbsp;
          <Button variant="secondary" onClick={onRequestClose}>
            Close
          </Button>
        </form>
        {loading && <div className="loading"></div>}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default MonthlyTarget;
