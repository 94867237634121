import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
 

function SQCeditModalPopup({ isOpen, onRequestClose, productId }) {
    const [uproduct1, setUproduct1] = useState({
      id: "",
      invoiceNumber: "",
      truckNumber: "",
      date: new Date().toISOString().split("T")[0], // Set current date
      orgcode: JSON.parse(localStorage.getItem("data")).orgcode,
    });
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      if (productId) {
        getData();
      }
    }, [productId]);
  
    const getData = async () => {
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/sqcform/${productId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUproduct1(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
  
    const rsubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        await axios.post(
          "/api/sqcform/update",
          uproduct1,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setLoading(false);
        toast.success("SQC updated successfully");
        onRequestClose();
      } catch (error) {
        console.log(error);
      }
    };
  
    const rhandle = (event, field) => {
      const value = event.target.value;
      setUproduct1((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    };
 
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Update SQC Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4">
            <form onSubmit={(e) => rsubmit(e)}>
              <div className="row">
                <div className="form-floating-sm mb-2">
                  <label htmlFor="productname">SQC Number</label>
                  <input
                    className="form-control"
                    id="id"
                    type="text"
                    placeholder="id"
                    value={uproduct1.id}
                    readOnly
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="invoiceNumber">Invoice Number</label>
                  <input
                    className="form-control"
                    id="invoiceNumber"
                    type="text"
                    placeholder="invoiceNumber"
                    value={uproduct1.invoiceNumber}
                    onChange={(e) => rhandle(e, "invoiceNumber")}
                  />
                  </div>
              </div>
                  <div className="row">
                  <div className="form-floating-sm mb-2">
                  <label htmlFor="truckNumber">Truck Number</label>
                  <input
                    className="form-control"
                    id="truckNumber"
                    type="text"
                    placeholder="truckNumber"
                    value={uproduct1.truckNumber}
                    onChange={(e) => rhandle(e, "truckNumber")}
                  />
                  </div>
              </div>
                 
                  <br />
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                
            </form>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer></Modal.Footer>
  </Modal>
  );
}
 
export default SQCeditModalPopup;